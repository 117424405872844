import getRooms from "@/api/rooms/getRooms";
import saveRoom from "@/api/rooms/saveRoom";
import deleteRoom from "@/api/rooms/deleteRoom";

const state = { rooms: [] };

const actions = {
    getRoomsAction({ commit }) {
        return new Promise((resolve, reject) => {
            getRooms.call(this).then(response => {
                if (response.status == 'OK') {
                    commit('setRoomsMutation', { rooms: response.data })
                    resolve()
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    saveRoomAction({ commit }, { room }) {
        return new Promise((resolve, reject) => {
            saveRoom.call(this, { room }).then(response => {
                if (response.status == 'OK') {
                    commit('updateRoomsMutation', { rooms: response.data })
                    resolve()
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    deleteRoomAction({ commit }, { room }) {
        return new Promise((resolve, reject) => {
            deleteRoom.call(this, { room }).then(response => {
                if (response.status == 'OK') {
                    commit('deleteRoomsMutation', { room: response.data })
                    resolve()
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    }
}

const mutations = {
    setRoomsMutation(state, { rooms }) {
        state.rooms = rooms
    },
    updateRoomsMutation(state, { rooms }) {
        rooms.forEach(room => {
            const index = state.rooms.findIndex(r => r.id == room.id)
            if (index !== -1 && typeof index !== 'undefined') {
                state.rooms.splice(index, 1, room)
            } else {
                state.rooms = [...state.rooms, room]
            }
        })
    },
    deleteRoomsMutation(state, { room }) {
        const index = state.rooms.findIndex(r => r.id == room.id)
        if (typeof index !== 'undefined') {
            state.rooms.splice(index, 1)
        }
    },
}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}