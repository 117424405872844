import Vue from 'vue'

export default function deleteOut({ book_outs }) {
    const proxy = process.env.VUE_APP_PROXY
    const api_uri = process.env.VUE_APP_API_URI

    let url = proxy + api_uri + "/bookouts/delete"
    return Vue.http
        .post(url, { book_outs }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response).catch((e) => { throw e })
}