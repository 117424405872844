<template>
  <div class="home__navigate">
    <span class="home__navigate__info all-lessons"
      >All lessons: {{ meta.total }}</span
    >
    <span class="home__navigate__info done-lessons"
      >Done: {{ doneLessons }}</span
    >
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      page: 1,
    };
  },
  computed: {
    ...mapState({
      meta: (state) => state.home.meta,
      lessons: (state) => state.home.homeLessons,
    }),
    doneLessons: function () {
      const teacher = JSON.parse(localStorage.getItem("user"));

      let count = this.lessons.reduce((count, lesson) => {
        if (lesson.main_teacher.id == teacher.id && lesson.date_end) {
          count++;
          return count;
        }
        const otherTeacher = Object.values(lesson.other_teachers).find(
          (t) => t.id == teacher.id,
        );
        if (otherTeacher && otherTeacher.date_end) {
          count++;
          return count;
        }
        const assitant = Object.values(lesson.assistants).find(
          (t) => t.id == teacher.id,
        );
        if (assitant && assitant.visited_at) {
          count++;
          return count;
        }

        return count;
      }, 0);
      return count;
    },
  },
  methods: {
    // TODO: DELETE
    printHandler: function () {
      var el = document.querySelector(".home__print").innerHTML;
      var a = window.open("", "");
      a.document.write("<html>");
      a.document.write("<body>");
      a.document.write(el);
      a.document.write("</body></html>");
      a.document.close();
      // a.print();
      // a.close();
    },
  },
};
</script>

<style src="@/assets/css/home-style.css">
</style>