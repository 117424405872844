import getTasks from "@/api/tasks/getTasks";
import getSingleTask from "@/api/tasks/getSingleTask";
import saveTask from "@/api/tasks/saveTask";
// import deleteCategory from "@/api/surprises/deleteCategory";

const state = { tasks: [], myTasks: [], singleTask: null };

const actions = {
    getTasks({ commit }, filters) {
        return new Promise((resolve, reject) => {
            getTasks.call(this, filters).then(response => {
                if (response.status == 'OK') {
                    commit('setTasksMutation', { tasks: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    getSingleTask({ commit }, id) {
        return new Promise((resolve, reject) => {
            getSingleTask.call(this, id).then(response => {
                if (response.status == 'OK') {
                    commit('setSingleTasksMutation', { task: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    getMyTasks({ commit }, filters) {
        return new Promise((resolve, reject) => {
            getTasks.call(this, filters).then(response => {
                if (response.status == 'OK') {
                    commit('setMyTasksMutation', { tasks: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    saveTaskAction({ commit }, { data }) {
        return new Promise((resolve, reject) => {
            saveTask.call(this, { data }).then(response => {
                if (response.status == 'OK') {
                    commit('updateTasksMutation', {})
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    // deleteCategoryAction({ commit }, { category }) {
    //     return new Promise((resolve, reject) => {
    //         deleteCategory.call(this, { category }).then(response => {
    //             if (response.status == 'OK') {
    //                 commit('deleteCategoryMutation', { category: response.data })
    //                 resolve()
    //             }
    //             else { reject(new Error(response.message)) }
    //         }).catch(e => { reject(new Error(e)) })
    //     })
    // },

    // getSurprisesByStudentAction({ commit }, { student_id }) {
    //     return new Promise((resolve, reject) => {
    //         getSurprises.call(this, { student_id }).then(response => {
    //             if (response.status == 'OK') {
    //                 commit('setSurprisesMutation', { surprises: response.data })
    //                 resolve()
    //             }
    //             else { reject(new Error(response.message)) }
    //         }).catch(e => { reject(new Error(e)) })
    //     })
    // },
    // saveSurpriseAction({ commit }, { surprise }) {
    //     return new Promise((resolve, reject) => {
    //         saveSurprises.call(this, { surprise }).then(response => {
    //             if (response.status == 'OK') {
    //                 commit('updateSurprisesMutation', { surprises: response.data })
    //                 resolve()
    //             }
    //             else { reject(new Error(response.message)) }
    //         }).catch(e => { reject(new Error(e)) })
    //     })
    // },
    // deleteSurpriseAction({ commit }, { surprise }) {
    //     return new Promise((resolve, reject) => {
    //         deleteSurprise.call(this, { surprise }).then(response => {
    //             if (response.status == 'OK') {
    //                 commit('deleteSurpriseMutation', { surprise: response.data })
    //                 resolve()
    //             }
    //             else { reject(new Error(response.message)) }
    //         }).catch(e => { reject(new Error(e)) })
    //     })
    // },
    clearTasksAction({ commit }) {
        commit('clearTasksMutation')
    },
    clearSingleTaskAction({commit}){
        commit('clearSinglerTaskMutation')
    }
}

const mutations = {
    setTasksMutation(state, { tasks }) {
        state.tasks = tasks
    },
    setMyTasksMutation(state, { tasks }) {
        state.myTasks = tasks
    },
    updateTasksMutation() {
        // ничего не делаем, так как родительский компонент 
        // запросит весь список заново
    },
    setSingleTasksMutation(state, { task }) {
        state.singleTask = task
    },
    clearSinglerTaskMutation(state){
        state.singleTask = null
    },
    // updateCategoryMutation(state, { categories }) {
    //     categories.forEach(category => {
    //         const index = state.categories.findIndex(r => r.id == category.id)
    //         if (index !== -1 && typeof index !== 'undefined') {
    //             state.categories.splice(index, 1, category)
    //         } else {
    //             state.categories = [...state.categories, category]
    //         }
    //     })
    // },
    // deleteCategoryMutation(state, { category }) {
    //     const index = state.categories.findIndex(r => r.id == category.id)
    //     if (typeof index !== 'undefined') {
    //         state.categories.splice(index, 1)
    //     }
    // },
    // setSurprisesMutation(state, { surprises }) {
    //     state.surprises = surprises
    // },
    // updateSurprisesMutation(state, { surprises }) {
    //     surprises.forEach(surprise => {
    //         const index = state.surprises.findIndex(r => r.id == surprise.id)
    //         if (index !== -1 && typeof index !== 'undefined') {
    //             state.surprises.splice(index, 1, surprise)
    //         } else {
    //             state.surprises = [...state.surprises, surprise]
    //         }
    //     })
    // },
    // deleteSurpriseMutation(state, { surprise }) {
    //     const index = state.surprises.findIndex(r => r.id == surprise.id)
    //     if (typeof index !== 'undefined') {
    //         state.surprises.splice(index, 1)
    //     }
    // },
    clearTasksMutation(state) {
        state.tasks = []
        state.myTasks = []
    },


}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}