<template>
  <nav class="left-menu">
    <router-link :to="{ name: 'Teacher', params: { teacher_id: user.id } }">
      <vs-avatar
        size="large"
        :src="
          user.options ? `${proxy}/static/files/${user.options.avatar}` : null
        "
      />
    </router-link>

    <ul>
      <li>
        <router-link to="/teachers" v-if="$can('manage', 'teacher')">
          <vs-icon icon="school"></vs-icon>Teachers
        </router-link>
      </li>
      <li>
        <router-link to="/groups">
          <vs-icon icon="group"></vs-icon>Groups
        </router-link>
      </li>
      <li>
        <router-link to="/students">
          <vs-icon icon="person"></vs-icon>Students
        </router-link>
      </li>
      <li>
        <router-link to="/books" v-if="$can('manage', 'books')">
          <vs-icon icon="import_contacts"></vs-icon> Books
        </router-link>
      </li>
      <li>
        <router-link to="/timetable">
          <vs-icon icon="event_available"></vs-icon>Timetable
        </router-link>
      </li>
      <li>
        <router-link to="/orders" v-if="$can('manage', 'orders')">
          <vs-icon icon="insert_chart_outlined"></vs-icon>Orders
        </router-link>
      </li>
      <li>
        <router-link to="/payments" v-if="$can('manage', 'payments')">
          <vs-icon icon="attach_money"></vs-icon>Payments
        </router-link>
      </li>
      <li>
        <router-link to="/tasks" v-if="$can('manage', 'all_tasks_view')">
          <vs-icon icon="task_alt"></vs-icon>Tasks
        </router-link>
      </li>
      <li>
        <router-link to="/calendar" v-if="$can('manage', 'calendar')">
          <vs-icon icon="calendar_month"></vs-icon>Calendar
        </router-link>
      </li>
      <li>
        <router-link to="/settings" v-if="$can('manage', 'settings')">
          <vs-icon icon="settings"></vs-icon>Settings
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      user: localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : {},
      proxy: process.env.VUE_APP_PROXY,
    }
  },
}
</script>

<style lang="scss">
nav.left-menu {
  width: 84px;
  height: 100%;
  border-right: 1px solid #ccc;
  display: block;
  position: sticky;
  top: 0;
  left: 0;
  padding: 10px 0;
  text-align: center;
  &.left-menu {
    .vs-icon {
      display: block;
    }
  }
  li {
    display: block;
    text-align: center;
    padding-left: 4px;
    a {
      color: #2c3e50cc;
      display: block;
      padding: 15px 5px;
      border-radius: 10px 0 0 10px;
      transition: 0.3s all;
      &.router-link-exact-active {
        color: #000;
        background-color: #ccceff;
      }
      &:hover {
        background-color: #eee;
      }
    }
  }
}
</style>
