import Vue from 'vue'

export default function uploadFile({ data }) {
    const proxy = process.env.VUE_APP_PROXY
    const api_uri = process.env.VUE_APP_API_URI

    let url = proxy + api_uri + "/files/upload"
    return Vue.http
        .post(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => response.json()).catch((e) => { throw e })
}