<template>
  <div>
    <div class="unicorn" @click="animate">
      <img src="../../../assets/sloth2.png" alt="" srcset="" />
    </div>
    <div class="confetti" v-if="isActive"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
    }
  },
  methods: {
    animate: function() {
      this.isActive = true
      setTimeout(() => {
        const paths = document.querySelectorAll('.rainbow svg#one path')
        console.log(paths)
        paths.forEach(p => {
          p.classList.add('animate')
        })
      }, 10)

      setTimeout(() => {
        const paths = document.querySelectorAll('svg path')
        paths.forEach(p => {
          p.classList.remove('animate')
        })
        this.isActive = false
      }, 5000)
    },
  },
}
</script>

<style lang="scss" scoped>
.unicorn {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 80px;
  height: 110px;
  img {
    width: 100%;
  }
}

.confetti {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-repeat: repeat-x;
  background-position: top -10px center;
  background-image: url('../../../assets/confetti.svg');
  background-size: 100%;
}
</style>
