<template>
  <div class="schoolyear-select">
    <vs-dropdown vs-custom-content vs-trigger-click>
      <a class="a-icon" href.prevent>
        <vs-icon icon="donut_large" size="small" class="title-icon"></vs-icon>
        {{
          currentYear.name ? `School Year: ${currentYear.name}` : "Select year"
        }}
        <vs-icon class="" icon="expand_more"></vs-icon>
      </a>
      <vs-dropdown-menu class="header-schoolyears-menu">
        <vs-dropdown-item
          v-for="(item, index) in years"
          :key="index"
          @click="selectYear(item.id)"
          :class="{ selected: item.id == currentYear.id }"
        >
          {{ item.name }}
        </vs-dropdown-item>
      </vs-dropdown-menu>
    </vs-dropdown>

    <!-- <vs-select v-model="selectedYear">
      <vs-select-item
        :key="index"
        :value="item.id"
        :text="item.name"
        v-for="(item, index) in years"
      />
    </vs-select> -->
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      years: (state) => state.years.years,
      currentYear: (state) => state.years.currentSchoolYear,
    }),
  },
  methods: {
    selectYear: function (schoolyearId) {
      this.$store.dispatch("years/setCurrentYearAction", {
        schoolyearId,
      });
    },
  },
};
</script>

<style lang="scss">
.header-schoolyears-menu {
  .selected {
    background-color: #eee;
  }
}
.a-icon {
  .vs-icon {
    &.title-icon {
      opacity: 0.3;
      font-size: 1.1rem;
      width: 1.1rem;
      height: 1.1rem;
      margin-right: 3px;
    }
  }
}
</style>