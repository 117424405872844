import Vue from 'vue'

export default function stayStudentInGroup({ id, group_id }) {
  const proxy = process.env.VUE_APP_PROXY
  const api_uri = process.env.VUE_APP_API_URI

  let url =
    proxy + api_uri + '/students/' + id + '/group/' + group_id + '/trial/stay'
  return Vue.http
    .put(
      url,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(response => response.json())
    .catch(() => {
      throw 'Ошибка сохранения'
    })
}
