<template>
  <div>
    <div class="file-item" :class="{ small: size == 'small' }">
      <div class="file-item__preview" @click="showImageHandler">
        <img
          :src="thumbSrc"
          v-if="imgExtensionsList.indexOf(ext.toLowerCase()) != -1"
        />
        <vs-icon icon="attach_file" v-else></vs-icon>
      </div>
      <div class="file-item__info" @click="showImageHandler">
        <div class="file-item__info__name" :title="file_name">
          <div class="filename">{{ file_name }}</div>
          <div class="ext">.{{ ext }}</div>
        </div>
        <div class="file-item__info__credentials">
          <span class="file__info__credentials-owner"
            >- {{ file.owner.nickname }}</span
          >
          <span class="file-item__info__credentials-date">
            {{ $moment(file.created * 1000).format('DD.MM.YYYY HH:mm') }}
          </span>
        </div>
      </div>
      <div class="file-item__manage">
        <vs-dropdown vs-trigger-click>
          <vs-button
            class="btn-drop"
            type="flat"
            icon="more_horiz"
            color="dark"
          ></vs-button>
          <vs-dropdown-menu class="file-item__manage__dropdown">
            <vs-dropdown-item
              v-if="
                isEditable && imgExtensionsList.indexOf(ext.toLowerCase()) != -1
              "
            >
              <div class="file__edit" @click="editHandler" title="Crop image">
                <vs-icon icon="edit" color="dark" size="14px"></vs-icon>
                Edit
              </div>
            </vs-dropdown-item>

            <vs-dropdown-item>
              <div
                class="file__download"
                @click="downloadHandler"
                title="Download file"
              >
                <vs-icon icon="download" color="dark" size="14px"></vs-icon>
                Download
              </div>
            </vs-dropdown-item>
            <vs-dropdown-item
              v-if="
                !isEditable &&
                  imgExtensionsList.indexOf(ext.toLowerCase()) != -1
              "
            >
              <div
                class="file__view"
                @click="showImageHandler"
                title="View file"
              >
                <vs-icon icon="visibility" color="dark" size="14px"></vs-icon>
                View
              </div>
            </vs-dropdown-item>
            <vs-dropdown-item v-if="isEditable">
              <div
                class="file__delete"
                @click="deleteHandler"
                title="Delete file"
              >
                <vs-icon icon="delete" color="dark" size="14px"></vs-icon>
                Delete
              </div>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>
    </div>

    <div class="file-edit-popup" v-if="popupActive">
      <vs-popup
        class="file-edit-popup"
        title="Group"
        :active.sync="popupActive"
      >
        <div class="crop-section">
          <section class="cropper">
            <vue-cropper
              ref="cropper"
              :src="editFileString"
              alt="Source Image"
              @cropend="cropImage"
              v-if="popupActive"
              :zoomOnWheel="false"
            >
            </vue-cropper>
          </section>

          <section class="preview-area">
            <p>Cropped Image</p>
            <div class="cropped-image">
              <img v-if="cropImg" :src="cropImg" alt="Cropped Image" />
              <div v-else class="crop-placeholder"></div>
            </div>
            <vs-button icon="crop" @click="cropHandler">Save</vs-button>
          </section>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import imgExtensionsList from '@/components/common/dicts/imageExtensions.js'

export default {
  props: {
    file: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      ...this.file,
      proxy: process.env.VUE_APP_PROXY,
      popupActive: false,
      cropImg: '',
      editFileString: '',
      imgExtensionsList,
    }
  },
  computed: {
    ext: function() {
      const { name } = this.file
      return name.split('.').pop()
    },
    file_name: function() {
      const { name } = this.file
      const nameItems = name.split('.')
      nameItems.pop()

      return nameItems.join()
    },
    thumbSrc: function() {
      return this.proxy + this.file.thumb_path
    },
    fileSrc: function() {
      return this.proxy + this.file.file_path
    },
  },
  components: { VueCropper },
  methods: {
    editHandler: function() {
      this.popupActive = true
      this.editFileString = URL.createObjectURL(this.file.source)

      setTimeout(() => {
        this.cropImage()
        URL.revokeObjectURL(this.source)
      }, 100)
    },
    deleteHandler: function() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirm`,
        text: 'Are you sure to delete file?',
        accept: () => {
          this.$store
            .dispatch('files/deleteFileAction', this.id)
            .then(() => {
              this.$emit('deleted', this.id)
            })
            .catch(() => {
              this.$vs.notify({
                title: `Error in delete file`,
                text: '',
                color: 'danger',
                position: 'top-right',
              })
            })
        },
      })
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
    },
    cropHandler: async function() {
      let res = await fetch(this.cropImg)
      const blob = await res.blob()
      const newFile = new File([blob], `${this.file_name}.png`, {
        type: 'image/png',
      })
      this.source = newFile
      this.cropData = JSON.stringify(this.$refs.cropper.getData())

      this.$emit('cropped', this.id, this.source)
      this.popupActive = false

      //   this.changeHandler()
      // get image data for post processing, e.g. upload or setting image src
      //   this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
    },
    downloadHandler: async function() {
      var link = document.createElement('a')
      // If you don't know the name or want to use
      // the webserver default set name = ''
      const blob = await fetch(this.proxy + this.file.file_path).then(res =>
        res.blob()
      )
      const url = URL.createObjectURL(blob)
      link.setAttribute('download', this.file.name)
      link.setAttribute('hidden', true)
      link.setAttribute('id', 'download')
      link.href = url
      document.body.appendChild(link)
      document.getElementById('download').click()
      // link.click()
      link.remove()
      URL.revokeObjectURL(blob)
    },
    showImageHandler: function() {
      if (this.imgExtensionsList.includes(this.ext.toLowerCase()))
        this.$emit('showImage', this.file.id)
    },
  },
}
</script>

<style lang="scss">
.file {
  margin-right: 0.5rem;
  min-width: 15rem;
  &-item {
    display: flex;
    flex-flow: row;
    padding: 0.5rem;
    margin: 0 0 0.5rem 0;
    background-color: #eee;
    border-radius: 10px;
    overflow: hidden;
    // gap: 10px;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;
    border: 1px solid #ddd;
    &:hover {
      box-shadow: 0 0 5px 1px #ccc;
    }
    &.small {
      padding: 0.1rem;
      .file-item__preview {
        max-height: 20px;
        max-width: 30px;
        i {
          padding-top: 0rem;
        }
      }
    }
    img.hidden {
      display: none;
    }
    &__preview {
      padding: 0 7px;
      width: 50px;
      height: 50px;
      display: flex;
      justify-items: center;
      img {
        width: 100%;
        height: 100%;
      }
      i {
        padding-top: 0.7rem;
      }
    }
    &__info {
      flex: 1;
      min-width: 0;
      max-width: 10rem;
      padding: 0 7px;
      &__name {
        display: flex;
        .filename {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .ext {
          flex-shrink: 0;
        }
      }
    }
    &__manage {
      text-align: right;
      display: flex;
      flex-flow: row nowrap;
    }
    &__info__credentials {
      color: #777;
      font-size: 0.8em;
      .file__info__credentials-date {
        margin-left: 5px;
      }
    }
  }
}
.crop-section {
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  .cropper {
    // flex: 0.2;
    max-width: 50%;
    & > div {
      height: 100%;
    }
    img {
      height: 100%;
    }
  }
  .preview-area {
    width: 45%;
    margin-left: 2rem;
    .cropped-image {
      margin-bottom: 1rem;
    }
    img {
      width: 100%;
      // max-height: 20rem;
    }
  }
}
.file-item__manage__dropdown {
  .vs-dropdown--item-link {
    & > div {
      display: flex;
      align-items: center;
      gap: 0.2rem;
    }
  }
}
.con-vs-popup.file-edit-popup {
  z-index: 20001 !important;
  .vs-popup {
    height: 100%;
    width: 70%;
    .vs-popup--content {
      position: relative;
      height: calc(100vh - 100px);
      section.cropper {
        height: 100%;
      }
    }
  }
}
</style>
