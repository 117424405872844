<template>
  <div class="lesson-wrapper" :class="{ cancelled: isCancelled }">
    <div class="lesson__header">
      <div class="lesson__time" :class="{ crossed: isCancelled }">
        {{ $moment(lesson.lesson_time * 1000).format('HH:mm') }}
      </div>
      <div class="lesson__group" v-if="lesson.lesson_type == 'group'">
        <group
          :color="lesson.group.color"
          :id="lesson.group.id"
          :type="lesson.lesson_type"
          >{{ lesson.group.name }}</group
        >
      </div>
      <div class="lesson__teacher-type">
        <vs-chip>
          <vs-avatar icon="person_pin" />
          <span v-if="teacherType == 'main'"> Main teacher </span>
          <span v-else-if="teacherType == 'other'"> Sub teacher </span>
          <span v-else-if="teacherType == 'assistant'"> Assistant </span>
        </vs-chip>
      </div>
      <div class="lesson__teachers">
        <div class="lesson__teachers__main" v-if="mainTeacher">
          <span>Main:</span>
          <div class="teachers">
            <teacher
              :teacher="mainTeacher"
              :inWork="mainTeacher.date_start && !mainTeacher.date_end"
              size="18px"
            >
              {{ mainTeacher.nickname }}
            </teacher>
          </div>
        </div>
        <div class="lesson__teachers__other" v-if="otherTeachers.length">
          <span>Sub:</span>
          <div class="teachers">
            <teacher
              :teacher="t"
              :key="index"
              v-for="(t, index) in otherTeachers"
              :inWork="t.date_start && !t.date_end"
              size="18px"
            >
              {{ t.nickname }}
            </teacher>
          </div>
        </div>
        <div class="lesson__teachers__assist" v-if="assistantsTeachers.length">
          <span>Assist:</span>
          <div class="teachers">
            <teacher
              :teacher="t"
              :key="index"
              v-for="(t, index) in assistantsTeachers"
              :inWork="t.date_start && !t.date_end"
              size="18px"
            >
              {{ t.nickname }}
            </teacher>
          </div>
        </div>
      </div>
      <div class="lesson__status">
        <div class="lesson__status__work" v-if="inWork">
          <vs-chip color="primary"> In work </vs-chip>
        </div>
        <div class="lesson__status__work" v-else-if="isDone">
          <vs-chip color="success"> Done</vs-chip>
        </div>
        <div class="lesson__status__work" v-else-if="isCancelled">
          <vs-chip color="danger">Cancelled</vs-chip>
        </div>
      </div>
      <div class="lesson__name">
        <router-link
          :to="{
            name: 'Lessons',
            params: { lesson_id: lesson.id },
          }"
          class="lesson-link"
        >
          {{ lesson.name }}
        </router-link>
        <lesson-online v-if="lesson.is_online" />
      </div>

      <div class="lesson__room">
        <room :icon="lesson.room.icon">{{ lesson.room.name }}</room>
      </div>
      <lesson-manage
        :lesson="lesson"
        size="small"
        :selfLessonTeacher="selfLessonTeacher"
      />
      <div class="lesson__delimiter"></div>
      <div class="lesson__group__current-books">
        <group-current-books-view
          :current_books="lesson.group ? lesson.group.current_books : null"
        />
      </div>
    </div>
    <div class="lesson__tasks">
      <div
        class="lesson_task"
        v-for="task in lesson.tasks"
        :key="task.id"
        @click="$emit('viewTask', task)"
      >
        <div class="lesson_task__tooltip">
          <h4>{{ task.name }}</h4>
          <div v-html="task.desc"></div>
        </div>
        <task-list-item-compact :task="task" size="tiny" />
      </div>
    </div>
    <div class="lesson__table">
      <home-lesson-table
        :lesson="lesson"
        :students="lesson.students"
        :comments="lesson.comments"
        :notes="lesson.notes"
        :prev_comments="lesson.prev_comments"
        :prev_lesson="lesson.prev_lesson"
        :isLessonInWork="inWork"
      />
    </div>
  </div>
</template>

<script>
import group from '../common/elements/group.vue'
import Room from '../common/elements/room.vue'
import Teacher from '../common/elements/teacher.vue'
import LessonOnline from '../common/elements/lessonOnline.vue'
import LessonManage from '../lessons/components/lessonManage.vue'
import TaskListItemCompact from '../tasks/taskListItemCompact.vue'
import HomeLessonTable from './homeLessonTable.vue'
import groupCurrentBooksView from '../books/groups/currentBooks/groupCurrentBooksView.vue'

export default {
  components: {
    group,
    Room,
    TaskListItemCompact,
    HomeLessonTable,
    LessonManage,
    groupCurrentBooksView,
    LessonOnline,
    Teacher,
  },
  props: {
    lesson: {
      type: Object,
    },
    teacher_id: {
      type: Number,
    },
  },
  computed: {
    user: function() {
      return JSON.parse(window.localStorage.getItem('user'))
    },
    selfLessonTeacher: function() {
      const { main_teacher, other_teachers, assistants } = this.lesson
      let { id } = this.user

      if (typeof main_teacher == 'undefined') return {}
      if (main_teacher.id == id) {
        return { ...main_teacher, type: 'main' }
      }
      if (other_teachers && id in other_teachers) {
        return { ...other_teachers[id], type: 'other' }
      }
      if (assistants && id in assistants) {
        assistants[id].type = 'assistant'
        return { ...assistants[id], type: 'assistant' }
      }
      return {}
    },
    teacherType: function() {
      const { main_teacher, other_teachers, assistants } = this.lesson
      let id = this.teacher_id || this.user.id

      if (main_teacher.id == id) {
        return 'main'
      }
      if (other_teachers && id in other_teachers) {
        return 'other'
      }
      if (assistants && id in assistants) {
        return 'assistant'
      }
      return ''
    },
    mainTeacher: function() {
      let { id } = this.user
      const { main_teacher } = this.lesson
      if (main_teacher.id != id) {
        return { ...main_teacher }
      }
      return undefined
    },
    otherTeachers: function() {
      let { id } = this.user
      const { other_teachers } = this.lesson
      return Object.values(other_teachers).filter(t => t.id != id)
    },
    assistantsTeachers: function() {
      let { id } = this.user
      const { assistants } = this.lesson
      return Object.values(assistants).filter(t => t.id != id)
    },
    inWork: function() {
      if (
        this.selfLessonTeacher.id &&
        this.selfLessonTeacher.type != 'assistant' &&
        this.selfLessonTeacher.date_start &&
        !this.selfLessonTeacher.date_end
      )
        return true

      return false
    },
    isDone: function() {
      if (
        this.selfLessonTeacher.id &&
        this.selfLessonTeacher.type != 'assistant' &&
        this.selfLessonTeacher.date_start &&
        this.selfLessonTeacher.date_end
      )
        return true

      // for assistent visiting
      if (
        this.selfLessonTeacher.id &&
        this.selfLessonTeacher.type == 'assistant' &&
        this.selfLessonTeacher.visited_at
      )
        return true

      return false
    },

    isCancelled: function() {
      if (this.lesson.cancelled) return true

      return false
    },
  },
}
</script>

<style lang="scss">
.home__lesson {
  margin: 1rem 0;
  border-radius: 10px;
  padding: 1rem 1.5rem;
  box-shadow: 0 0 10px 1px #eee;
  &.near {
    box-shadow: 0 0 10px 1px rgba(var(--vs-primary), 0.4);
  }
  .lesson {
    &-wrapper {
      &.cancelled {
        opacity: 0.5;
      }
    }
    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    }
    &__time {
      font-size: 1.2rem;
      font-weight: bold;
      margin-right: 0.75rem;
    }
    &__status {
      margin: 0 2rem 0 0.5rem;
      .con-vs-chip {
        font-weight: bold;
      }
    }
    &__name {
      display: flex;
      align-items: center;
    }
    &__group,
    &__name,
    &__room {
      margin-right: 1.5rem;
    }
    &__group {
      font-weight: bold;
      font-size: 1rem;
    }
    &__tasks {
      display: flex;
      flex-flow: row wrap;
      .task-item {
        min-width: 300px;
        max-width: 400px;
        margin-right: 0.5rem;
        &-info__name {
          overflow: hidden;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    &__delimiter {
      flex: 1;
    }
    &__teachers {
      display: flex;
      align-items: baseline;
      font-size: 0.7rem;
      margin: 0 0.5rem;
      & > div {
        display: flex;
        align-items: baseline;
        margin: 0 0.2rem;
        .teachers {
          display: flex;
          background-color: rgba(var(--vs-dark), 0.2);
          padding: 0 0.5rem 0 0;
          border-radius: 1rem;
          margin-left: 0.2rem;
        }
      }
    }
  }
  .lesson_task {
    position: relative;
    &__tooltip {
      visibility: hidden;
      position: absolute;
      bottom: 100%;
      left: 50%;
      min-width: 20rem;
      z-index: 100;
      padding: 1rem;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0px 0px 2px 1px #ccc;
      font-size: 0.9rem;
    }
    &:hover {
      .lesson_task__tooltip {
        visibility: visible;
      }
    }
  }
}
</style>
