<template>
  <div class="search">
    <autocomplete
      aria-label="Search..."
      placeholder="Search..."
      :search="search"
      autoSelect
      :get-result-value="getResultValue"
      @submit="handleSubmit"
    >
      <template #result="{ result, props }">
        <li v-bind="props" class="autocomplete-result result">
          <div class="title">
            <span
              v-if="result.type == 'Group'"
              :class="{ old: result.groupOld }"
            >
              <vs-avatar
                icon="people"
                :color="result.color"
                size="small"
              ></vs-avatar>
              {{ result.groupName }}
            </span>
            <span v-if="result.type == 'Student'">
              <vs-avatar icon="person" size="small"></vs-avatar>
              {{ result.name }}
            </span>
            <span v-if="result.type == 'Teacher'">
              <vs-avatar icon="school" size="small"></vs-avatar>
              {{ result.name }}
            </span>
          </div>
        </li>
      </template>
    </autocomplete>
  </div>
</template>

<script>
import Autocomplete from "@trevoreyre/autocomplete-vue";
import searchQuery from "@/api/search/getSearch";

export default {
  data() {
    return {
      yearId: window.localStorage.getItem("schoolyearId"),
    };
  },
  components: {
    Autocomplete,
  },
  methods: {
    search: function (input) {
      return new Promise((resolve) => {
        if (input.length < 3) {
          return resolve([]);
        }
        searchQuery
          .call(this, { query: input })
          .then((response) => resolve(response.data));
      });
    },
    handleSubmit: function (result) {
      const params = {};
      params[`${result.type.toLowerCase()}_id`] = result.id;
      this.$router.push({ name: result.type, params });
      // if (this.$router.history.current.name == result.type)
      //   window.location.reload();
    },
    getResultValue: function (result) {
      if (result.type == "Group" && result.schoolyear) {
        if (this.yearId && result.schoolyear.id != parseInt(this.yearId, 10)) {
          result.groupName = `${result.name} (${result.schoolyear.name})`;
          result.groupOld = true;
        } else {
          result.groupName = result.name;
        }
      }
      return result.name;
    },
  },
};
</script>

<style lang='scss'>
.search .autocomplete-input {
  padding: 0 0 0 36px;
  background-size: 16px;
}
.autocomplete-result-list {
  .old {
    color: var(--font-negative-color);
  }
}
</style>