import getResults from "@/api/testResults/getResults";
import getSingleResult from "@/api/testResults/getSingleResult";
import addTestToLesson from "@/api/testResults/addTestToLesson";
import setTestResult from "@/api/testResults/setTestResult";
import addTestResult from "@/api/testResults/addTestResult";
import deleteResultsFromLesson from "@/api/testResults/deleteResultsFromLesson";
import deleteResult from "@/api/testResults/deleteResult";
import setTestToLesson from "@/api/books/setTestToLesson";
import getIncomeTestsResults from "@/api/testResults/getIncomeTestsResults";
import setIncomeTestResult from "@/api/testResults/setIncomeTestResult";
import getSingleIncomeResult from "@/api/testResults/getSingleIncomeResult";
import deleteIncomeResult from "@/api/testResults/deleteIncomeResult";
import sendIncomeResult from "@/api/testResults/sendIncomeResult";

const state = { results: [], singleResult: {}, incomeTestsResults: [], singleIncomeResult: {} };

const actions = {
    getResultsByLessonAction({ commit }, { lesson_id, test_id }) {
        const data = { lesson_id, test_id }
        return new Promise((resolve, reject) => {
            getResults.call(this, data).then(response => {
                if (response.status == 'OK') {
                    commit('setResultsMutation', { results: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    getResultsByGroupAction({ commit }, { group_id }) {
        const data = { group_id }
        return new Promise((resolve, reject) => {
            getResults.call(this, data).then(response => {
                if (response.status == 'OK') {
                    commit('setResultsMutation', { results: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    getResultsByStudentAction({ commit }, { student_id }) {
        const data = { student_id }
        return new Promise((resolve, reject) => {
            getResults.call(this, data).then(response => {
                if (response.status == 'OK') {
                    commit('setResultsMutation', { results: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    getSingleResultAction({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            getSingleResult.call(this, id).then(response => {
                if (response.status == 'OK') {
                    commit('setSingleResultMutation', { result: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    addTestToLessonAction({ commit }, { test_id, lesson_id }) {
        return new Promise((resolve, reject) => {
            addTestToLesson.call(this, { test_id, lesson_id }).then(response => {
                if (response.status == 'OK') {
                    // commit('updateBooksMutation', { books: response.data })
                    commit('setSingleResultMutation', { result: response.data[0] })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    setTestResultAction({ commit }, { data }) {
        data = {
            test_id: data.id,
            result_id: data.link_id,
            value: parseFloat(data.test.replace(',', '.')),
        };
        return new Promise((resolve, reject) => {
            setTestResult.call(this, { data }).then(response => {
                if (response.status == 'OK') {
                    commit('updateResultsMutation', { results: [response.data] })
                    commit('setSingleResultMutation', { result: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    addSingleResultAction({ commit }, { data }) {
        return new Promise((resolve, reject) => {
            addTestResult.call(this, { data }).then(response => {
                if (response.status == 'OK') {
                    // commit('updateResultsMutation', { results: response.data })
                    commit('setSingleResultMutation', { result: response.data[0] })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    setSingleResultAction({ commit }, { data }) {
        return new Promise((resolve, reject) => {
            setTestResult.call(this, { data }).then(response => {
                if (response.status == 'OK') {
                    commit('updateResultsMutation', { results: [response.data] })
                    commit('setSingleResultMutation', { result: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    deleteResultsFromLessonAction({ commit }, { lesson_id }) {
        return new Promise((resolve, reject) => {
            deleteResultsFromLesson.call(this, { lesson_id }).then(response => {
                if (response.status == 204) {
                    commit('setSingleResultMutation', { result: {} })
                    resolve(response.status)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    deleteResultsAction({ commit }, { result_id }) {
        return new Promise((resolve, reject) => {
            deleteResult.call(this, { result_id }).then(response => {
                if (response.status == 204) {
                    commit('delSingleResultMutation', result_id)
                    resolve(response.status)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    setTestToLessonAction({ commit }, { lesson_id, group_id, test_id }) {
        return new Promise((resolve, reject) => {
            setTestToLesson.call(this, { lesson_id, group_id, test_id }).then(response => {
                if (response.status == 'OK') {
                    commit('updateResultsMutation', { results: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    // -- income tests
    getIncomeTestsResultsByLessonAction({commit}, {lesson_id}) {
        return new Promise((resolve, reject) => {
            getIncomeTestsResults.call(this, { lesson_id }).then(response => {
                if (response.status == 'OK') {
                    commit('setIncomeTestsResultsMutation', { results: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    getIncomeTestsResultsByStudentAction({commit}, {student_id}) {
        return new Promise((resolve, reject) => {
            getIncomeTestsResults.call(this, { student_id }).then(response => {
                if (response.status == 'OK') {
                    commit('setIncomeTestsResultsMutation', { results: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    getSingleIncomeResultAction({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            getSingleIncomeResult.call(this, id).then(response => {
                if (response.status == 'OK') {
                    commit('setSingleIncomeResultMutation', { result: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    addIncomeTestResultAction({ commit }, { data }) {
        return new Promise((resolve, reject) => {
            setIncomeTestResult.call(this, { data }).then(response => {
                if (response.status == 'OK') {
                    commit('setIncomeTestsResultsMutation', { results: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    setIncomeTestResultAction({ commit }, { data }) {
        return new Promise((resolve, reject) => {
            setIncomeTestResult.call(this, { data }).then(response => {
                if (response.status == 'OK') {
                    commit('updateIncomeTestsResultsMutation', { results: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    deleteIncomeResultsAction({ commit }, result_id) {
        return new Promise((resolve, reject) => {
            deleteIncomeResult.call(this, result_id).then(response => {
                if (response.status == 204) {
                    commit('delSingleIncomeResultMutation', result_id)
                    resolve(response.status)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    sendIncomeResultAction({ commit }, {result_id, sended}) {
        return new Promise((resolve, reject) => {
            sendIncomeResult.call(this, {result_id, sended}).then(response => {
                if (response.status == 'OK') {
                    commit('updateIncomeTestsResultsMutation', {results: [response.data]})
                    resolve(response.status)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    clearIncomeResultsAction({commit}){
        commit('clearIncomeResultsMutation')
    }
}

const mutations = {
    setResultsMutation(state, { results }) {
        state.results = results
    },
    setSingleResultMutation(state, { result }) {
        state.singleResult = result
    },
    updateResultsMutation(state, { results }) {
        results.forEach(result => {
            const index = state.results.findIndex(r => r.id == result.id)
            if (index !== -1 && typeof index !== 'undefined') {
                state.results.splice(index, 1, result)
            } else {
                state.results = [...state.results, result]
            }
        })
    },
    delSingleResultMutation(state, {result_id}) {
        const index = state.results.findIndex(r => r.id == result_id)
        if (typeof index !== 'undefined') {
            state.results.splice(index, 1)
        }
    },
    // -- income tests
    setIncomeTestsResultsMutation(state, { results }) {
        state.incomeTestsResults = results
    },
    setSingleIncomeResultMutation(state, { result }) {
        state.singleIncomeResult = result
    },
    updateIncomeTestsResultsMutation(state, { results }) {
        results.forEach(result => {
            const index = state.incomeTestsResults.findIndex(r => r.id == result.id)
            if (index !== -1 && typeof index !== 'undefined') {
                state.incomeTestsResults.splice(index, 1, result)
            } else {
                state.incomeTestsResults = [...state.incomeTestsResults, result]
            }
            if (state.singleIncomeResult.id == result.id) {
                state.singleIncomeResult = result
            }
        })
    },
    delSingleIncomeResultMutation(state, {result_id}) {
        const index = state.incomeTestsResults.findIndex(r => r.id == result_id)
        if (typeof index !== 'undefined') {
            state.incomeTestsResults.splice(index, 1)
        }
        if (state.singleIncomeResult.id == result_id) {
            state.singleIncomeResult = {}
        }
    },
    clearIncomeResultsMutation(state){
        state.incomeTestsResults = []
    }
    // deleteBooksMutation(state, { book }) {
    //     const index = state.books.findIndex(r => r.id == book.id)
    //     if (typeof index !== 'undefined') {
    //         state.books.splice(index, 1)
    //     }
    // },
    // // -- GROUP TYPES
    // setGroupTypesMutation(state, { types }) {
    //     state.groupTypes = types
    // },
    // updateGroupTypesMutation(state, { types }) {
    //     types.forEach(type => {
    //         const index = state.groupTypes.findIndex(r => r.id == type.id)
    //         if (index !== -1 && typeof index !== 'undefined') {
    //             state.groupTypes.splice(index, 1, type)
    //         } else {
    //             state.groupTypes = [...state.groupTypes, type]
    //         }
    //     })
    // },
    // deleteGroupTypeNutation(state, { type }) {
    //     const index = state.groupTypes.findIndex(r => r.id == type.id)
    //     if (typeof index !== 'undefined') {
    //         state.groupTypes.splice(index, 1)
    //     }
    // },
}


export default {
    namespaced: true,
    state,
    actions,
    mutations,
}