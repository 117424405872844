var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.commentData.id,staticClass:"comment__item",class:{
    'student-comment': _vm.commentData.link.type == 'LessonsStudents',
  }},[_c('div',{staticClass:"comment__item-data",class:{
      deleted: _vm.commentData.deleted,
    }},[_c('div',{staticClass:"comment__options",class:{ comment__options__deleted: _vm.commentData.deleted }},[(_vm.commentData.teacher)?_c('div',{staticClass:"comment__autor"},[_c('vs-avatar',{attrs:{"src":_vm.commentData.teacher.options && _vm.commentData.teacher.options.avatar
              ? (_vm.proxy + "/static/files/" + (_vm.commentData.teacher.options.avatar))
              : undefined}}),_vm._v(" "+_vm._s(_vm.commentData.teacher.nickname)+" ")],1):_vm._e(),_c('div',{staticClass:"comment__dates"},[_c('div',{staticClass:"comment__date"},[_vm._v(" "+_vm._s(_vm.$moment.unix(_vm.commentData.created).format('DD MMM YYYY HH:mm'))+" ")]),(_vm.commentData.updated)?_c('div',{staticClass:"comment__date updated",attrs:{"title":("edited at " + (_vm.$moment
              .unix(_vm.commentData.updated)
              .format('DD MMM YYYY HH:mm')))}},[_vm._v(" Edited ")]):_vm._e()]),(_vm.isEditable)?_c('div',{staticClass:"manage"},[_c('vs-button',{staticClass:"small",attrs:{"type":"flat","color":"dark","icon":"edit"},on:{"click":function($event){_vm.isEdit = true}}},[_vm._v("Edit")]),_c('vs-button',{staticClass:"small",attrs:{"type":"flat","color":"danger","icon":"delete"},on:{"click":_vm.deleteCommentHandle}},[_vm._v("Delete")])],1):_vm._e()]),(!_vm.isEdit)?_c('div',{staticClass:"comment__text"},[_c('div',{staticClass:"comment__name"},[_c('router-link',{attrs:{"to":_vm.linkData}},[_vm._v(_vm._s(_vm.commentData.link.name))]),(_vm.commentData.link.is_online)?_c('lesson-online'):_vm._e()],1),(!_vm.commentData.deleted)?_c('div',{staticClass:"comment__text__block",staticStyle:{"white-space":"pre-line"},domProps:{"innerHTML":_vm._s(_vm.commentData.comment)}}):_c('div',[_c('vs-icon',{attrs:{"icon":"highlight_off","color":"danger"}}),_vm._v(" deleted at: "+_vm._s(_vm.$moment.unix(_vm.commentData.deleted).format('DD MMMM YYYY HH:mm'))+" ")],1),(_vm.files.length && !_vm.commentData.deleted)?_c('div',{staticClass:"comment__files"},[_c('files-list',{attrs:{"files":_vm.files}})],1):_vm._e()]):(_vm.isEdit)?_c('div',{staticClass:"comment__form"},[_c('CommentForm',{attrs:{"commentItem":_vm.commentData},on:{"closeCommentForm":_vm.closeCommentFormHandle}})],1):_vm._e()]),(_vm.commentData.childs)?_c('div',{staticClass:"comment__childs"},_vm._l((_vm.commentData.childs),function(child){return _c('div',{key:child.id},[_c('CommentItem',{attrs:{"comment":child,"name":"child-comment"}})],1)}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }