import getSurprises from "@/api/surprises/getSurprises";
import saveSurprises from "@/api/surprises/saveSurprises";
import deleteSurprise from "@/api/surprises/deleteSurprise";
import getCategories from "@/api/surprises/getCategories";
import saveCategory from "@/api/surprises/saveCategory";
import deleteCategory from "@/api/surprises/deleteCategory";

const state = { surprises: [], categories: [] };

const actions = {
    // -- categories
    getCategoriesAction({ commit }) {
        return new Promise((resolve, reject) => {
            getCategories.call(this).then(response => {
                if (response.status == 'OK') {
                    commit('setCategoriesMutation', { categories: response.data })
                    resolve()
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    saveCategoryAction({ commit }, { category }) {
        return new Promise((resolve, reject) => {
            saveCategory.call(this, { category }).then(response => {
                if (response.status == 'OK') {
                    commit('updateCategoryMutation', { categories: response.data })
                    resolve()
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    deleteCategoryAction({ commit }, { category }) {
        return new Promise((resolve, reject) => {
            deleteCategory.call(this, { category }).then(response => {
                if (response.status == 'OK') {
                    commit('deleteCategoryMutation', { category: response.data })
                    resolve()
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },

    getSurprisesByStudentAction({ commit }, { student_id }) {
        return new Promise((resolve, reject) => {
            getSurprises.call(this, { student_id }).then(response => {
                if (response.status == 'OK') {
                    commit('setSurprisesMutation', { surprises: response.data })
                    resolve()
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    saveSurpriseAction({ commit }, { surprise }) {
        return new Promise((resolve, reject) => {
            saveSurprises.call(this, { surprise }).then(response => {
                if (response.status == 'OK') {
                    commit('updateSurprisesMutation', { surprises: response.data })
                    resolve()
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    deleteSurpriseAction({ commit }, { surprise }) {
        return new Promise((resolve, reject) => {
            deleteSurprise.call(this, { surprise }).then(response => {
                if (response.status == 'OK') {
                    commit('deleteSurpriseMutation', { surprise: response.data })
                    resolve()
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    clearSurprisesAction({ commit }) {
        commit('clearSurprisesMutation')
    }
}

const mutations = {
    setCategoriesMutation(state, { categories }) {
        state.categories = categories
    },
    updateCategoryMutation(state, { categories }) {
        categories.forEach(category => {
            const index = state.categories.findIndex(r => r.id == category.id)
            if (index !== -1 && typeof index !== 'undefined') {
                state.categories.splice(index, 1, category)
            } else {
                state.categories = [...state.categories, category]
            }
        })
    },
    deleteCategoryMutation(state, { category }) {
        const index = state.categories.findIndex(r => r.id == category.id)
        if (typeof index !== 'undefined') {
            state.categories.splice(index, 1)
        }
    },
    setSurprisesMutation(state, { surprises }) {
        state.surprises = surprises
    },
    clearSurprisesMutation(state) {
        state.surprises = {}
    },
    updateSurprisesMutation(state, { surprises }) {
        surprises.forEach(surprise => {
            const index = state.surprises.findIndex(r => r.id == surprise.id)
            if (index !== -1 && typeof index !== 'undefined') {
                state.surprises.splice(index, 1, surprise)
            } else {
                state.surprises = [...state.surprises, surprise]
            }
        })
    },
    deleteSurpriseMutation(state, { surprise }) {
        const index = state.surprises.findIndex(r => r.id == surprise.id)
        if (typeof index !== 'undefined') {
            state.surprises.splice(index, 1)
        }
    },
}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}