<template>
  <div>
    <div class="unicorn" @click="animate">
      <img src="../../../assets/unicorn.png" alt="" srcset="" />
    </div>
    <div class="rainbow" v-if="isActive">
      <svg
        id="one"
        width="1600"
        height="1400"
        viewBox="0 -100 1000 1000"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="path"
          stroke-linecap="round"
          d="M1000 500C1000 367.392 947.322 240.215 853.553 146.447C759.785 52.6784 632.608 1.00116e-05 500 0C367.392 -1.00116e-05 240.215 52.6784 146.447 146.447C52.6785 240.215 2.00233e-05 367.392 0 500L50 500C50 380.653 97.4106 266.193 181.802 181.802C266.193 97.4106 380.653 50 500 50C619.347 50 733.807 97.4106 818.198 181.802C902.589 266.193 950 380.653 950 500H1000Z"
          fill="none"
          stroke="#F41010"
          stroke-width="52"
        />
        <path
          id="path"
          d="M950 500C950 380.653 902.589 266.193 818.198 181.802C733.807 97.4106 619.347 50 500 50C380.653 50 266.193 97.4106 181.802 181.802C97.4106 266.193 50 380.653 50 500L95 500C95 392.587 137.67 289.574 213.622 213.622C289.574 137.67 392.587 95 500 95C607.413 95 710.426 137.67 786.378 213.622C862.33 289.574 905 392.587 905 500H950Z"
          stroke-linecap="round"
          fill="none"
          stroke="#FA7D09"
          stroke-width="52"
        />
        <path
          id="path"
          d="M906 500C906 392.322 863.225 289.054 787.085 212.915C710.946 136.775 607.678 94 500 94C392.322 94 289.054 136.775 212.915 212.915C136.775 289.054 94 392.322 94 500L134.6 500C134.6 403.09 173.097 310.149 241.623 241.623C310.149 173.097 403.09 134.6 500 134.6C596.91 134.6 689.851 173.097 758.377 241.623C826.903 310.149 865.4 403.09 865.4 500H906Z"
          stroke-linecap="round"
          fill="none"
          stroke="#FAD309"
          stroke-width="52"
        />
        <path
          id="path"
          d="M865 500C865 403.196 826.545 310.357 758.094 241.906C689.643 173.455 596.804 135 500 135C403.196 135 310.357 173.455 241.906 241.906C173.455 310.357 135 403.196 135 500L171.5 500C171.5 412.876 206.11 329.321 267.715 267.715C329.321 206.11 412.876 171.5 500 171.5C587.124 171.5 670.679 206.11 732.285 267.715C793.89 329.321 828.5 412.876 828.5 500H865Z"
          stroke-linecap="round"
          fill="none"
          stroke="#0AE76F"
          stroke-width="52"
        />
        <path
          id="path"
          d="M827 499.5C827 412.642 792.496 329.341 731.077 267.923C669.659 206.504 586.358 172 499.5 172C412.642 172 329.341 206.504 267.923 267.923C206.504 329.341 172 412.642 172 499.5L204.75 499.5C204.75 421.327 235.804 346.357 291.08 291.08C346.357 235.804 421.327 204.75 499.5 204.75C577.673 204.75 652.643 235.804 707.92 291.08C763.196 346.357 794.25 421.327 794.25 499.5H827Z"
          stroke-linecap="round"
          fill="none"
          stroke="#0A6FE7"
          stroke-width="52"
        />
        <path
          id="path"
          d="M795 500C795 421.761 763.92 346.727 708.597 291.404C653.273 236.08 578.239 205 500 205C421.761 205 346.727 236.08 291.404 291.403C236.08 346.727 205 421.761 205 500L234.5 500C234.5 429.585 262.472 362.054 312.263 312.263C362.054 262.472 429.585 234.5 500 234.5C570.415 234.5 637.946 262.472 687.737 312.263C737.528 362.054 765.5 429.585 765.5 500H795Z"
          stroke-linecap="round"
          fill="none"
          stroke="#AD0AE7"
          stroke-width="52"
        />
      </svg>
    </div>
    <div class="confetti" v-if="isActive"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
    }
  },
  methods: {
    animate: function() {
      this.isActive = true
      setTimeout(() => {
        const paths = document.querySelectorAll('.rainbow svg#one path')
        console.log(paths)
        paths.forEach(p => {
          p.classList.add('animate')
        })
      }, 10)

      setTimeout(() => {
        const paths = document.querySelectorAll('svg path')
        paths.forEach(p => {
          p.classList.remove('animate')
        })
        this.isActive = false
      }, 5000)
    },
  },
}
</script>

<style lang="scss" scoped>
.unicorn {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 80px;
  height: 80px;
  img {
    width: 100%;
  }
}
.rainbow {
  margin: 0 auto;
  position: fixed;
  top: 0;
  left: 50px;
  z-index: 1002;
  svg {
    stroke-dasharray: 5000;
    stroke-dashoffset: 5000;
    pointer-events: none;
    opacity: 0.5;
  }
  .animate {
    animation: firstAnimation 5s linear forwards 1;
  }
  @keyframes firstAnimation {
    to {
      stroke-dashoffset: 0;
    }
  }
}
.confetti {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-repeat: repeat-x;
  background-position: top -10px center;
  background-image: url('../../../assets/confetti.svg');
}
</style>
