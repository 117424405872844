import Vue from 'vue'

export default function getTests(query) {
    const proxy = process.env.VUE_APP_PROXY
    const api_uri = process.env.VUE_APP_API_URI

    let url = proxy + api_uri + "/tests"
    if (query) {
        let q = new URLSearchParams(query).toString()
        url += `?${q}`
    }
    return Vue.http
        .get(url, {}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
}