var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"task-item",class:{
    student: _vm.task.students.length,
    group: _vm.task.group.id,
    lesson: _vm.task.lesson.id,
    small: _vm.size == 'small',
    tiny: _vm.size == 'tiny',
  }},[_c('div',{staticClass:"task-item__importance__border",class:_vm.importance.name.toLowerCase()}),_c('vs-row',{staticClass:"task-item-info",attrs:{"vs-w":"12"}},[_c('div',{staticClass:"task-item-info__id",class:_vm.status.class},[_vm._v("#"+_vm._s(_vm.task.id))]),_c('div',{staticClass:"task-item-info__name"},[_vm._v(" "+_vm._s(_vm.task.name)+" ")]),(_vm.size == 'tiny')?_c('task-dates',{attrs:{"task":_vm.task,"size":_vm.size}}):_vm._e(),_c('task-status',{attrs:{"status":_vm.status}})],1),(_vm.size == 'small')?_c('vs-row',{staticClass:"task-item-sub",attrs:{"vs-w":"12"}},[_c('div',{staticClass:"task-item-sub__places"},[(_vm.task.group.id)?_c('div',{staticClass:"task-item-place__group"},[_c('vs-avatar',{attrs:{"icon":"people","color":_vm.task.group.color,"size":"16px","title":_vm.task.group.name}}),_c('span',[_vm._v(_vm._s(_vm.task.group.name))])],1):_vm._e(),(_vm.task.lesson.id)?_c('div',{staticClass:"task-item-place__lesson"},[_c('vs-avatar',{attrs:{"icon":"query_builder","size":"16px","color":"#aaa","title":((_vm.task.lesson.name) + " - " + (_vm.$moment(
              _vm.task.lesson.lesson_time * 1000
            ).format('DD.MM.YYYY HH:mm')))}}),_c('span',[_vm._v(_vm._s(_vm.task.lesson.name)+" - "+_vm._s(_vm.$moment(_vm.task.lesson.lesson_time * 1000).format('DD.MM.YYYY HH:mm')))])],1):_vm._e()])]):_vm._e(),_c('vs-row',{staticClass:"task-item-sub task-students"},_vm._l((_vm.task.students),function(student,index){return _c('div',{key:index,staticClass:"task-item-place__student"},[(student && student.first_name)?_c('div',[_c('vs-avatar',{attrs:{"icon":"person","color":student.color,"size":"16px","title":((student.last_name) + " " + (student.first_name))}}),_c('span',[_vm._v(_vm._s(student.last_name)+" "),(_vm.task.students.length <= 2)?_c('span',[_vm._v(_vm._s(student.first_name))]):_c('span',[_vm._v(_vm._s(student.first_name.slice(0, 1))+".")])])],1):_vm._e()])}),0),(_vm.size != 'tiny')?_c('vs-row',{staticClass:"task-item-sub task-teachers"},[_c('div',{staticClass:"task-item-sub__teachers"},[_c('div',{staticClass:"task-item-sub__importance-item"},[_c('vs-icon',{attrs:{"icon":_vm.importance.icon,"color":_vm.importance.color,"title":_vm.importance.name}})],1),_vm._l((_vm.task.teachers),function(t){return _c('div',{key:t.id,staticClass:"task-item-sub__teacher"},[_c('teacher-chip-view',{attrs:{"teacher":t,"size":"small"}})],1)})],2),_c('task-dates',{attrs:{"task":_vm.task}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }