import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'SmartEnglish',
    component: Home,
    meta: {
      requiresAuth: true,
      breadcrumb: 'Home',
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    meta: {
      requiresAuth: true,
      breadcrumb: {
        label: 'Settings',
        parent: 'Home',
      },
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
  },
  {
    path: '/students',
    name: 'Students',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "students" */ '../views/StudentsList.vue'),
  },
  {
    path: '/students/:student_id',
    name: 'Student',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "students" */ '../views/Student.vue'),
  },
  {
    path: '/teachers',
    name: 'Teachers',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "teachers" */ '../views/TeachersList.vue'),
  },
  {
    path: '/teachers/:teacher_id',
    name: 'Teacher',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "teachers" */ '../views/Teacher.vue'),
  },
  {
    path: '/groups',
    name: 'Groups',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "groups" */ '../views/GroupsList.vue'),
  },
  {
    path: '/groups/:group_id',
    name: 'Group',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "singleGroup" */ '../views/Group.vue'),
  },
  {
    path: '/lessons/:lesson_id',
    name: 'Lessons',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "lessons" */ '../views/Lesson.vue'),
  },
  {
    path: '/timetable',
    name: 'Timetable',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "timetable" */ '../views/newTimetable.vue'),
  },
  {
    path: '/reports',
    name: 'Reports',

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "reports" */ '../views/Orders.vue'),
    // children: [
    //   {
    //     path: 'order',
    //     component: Orders
    //     // component: () =>
    //     //   import(/* webpackChunkName: "reports" */ "../views/reports/Orders.vue"),
    //   },
    // ]
  },
  {
    path: '/orders',
    name: 'Orders',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "orders" */ '../views/Orders.vue'),
  },
  {
    path: '/payments',
    name: 'Payments',

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "reports" */ '../views/Payments.vue'),
    // children: [
    //   {
    //     path: 'order',
    //     component: Orders
    //     // component: () =>
    //     //   import(/* webpackChunkName: "reports" */ "../views/reports/Orders.vue"),
    //   },
    // ]
  },
  {
    path: '/login',
    name: 'Login',

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "auth" */ '../views/Auth.vue'),
  },
  {
    path: '/books',
    name: 'Books',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "books" */ '../views/Books.vue'),
  },
  {
    path: '/books/:book_id',
    name: 'Book',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "books" */ '../views/Book.vue'),
  },
  {
    path: '/results/:result_id',
    name: 'Result',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "result" */ '../views/Result.vue'),
  },
  {
    path: '/results/income/:result_id',
    name: 'IncomeResult',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "result" */ '../views/IncomeResult.vue'),
  },
  {
    path: '/tasks',
    name: 'Tasks',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "tasks" */ '../views/Tasks.vue'),
  },
  {
    path: '/calendar',
    name: 'Calendar',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "tasks" */ '../views/Calendar.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('user') && localStorage.getItem('token')) {
      next()
      return
    }
    next('/login')
  }
  // else {
  //   next()
  // }
  next()
})

export default router
