<template>
  <div class="notifications__block">
    <vs-dropdown vs-custom-content vs-trigger-click>
      <vs-chip color="danger" transparent class="notification__button">
        <vs-avatar icon="notifications" color="#c00" />
        <div v-if="nearEvent" class="notification__button__info">
          <strong>{{ nearEvent.name }}</strong> at
          {{ $moment(nearEvent.date_start * 1000).format('DD MMM HH:mm') }}
          <div class="markdown danger" v-if="notificationsCount != 0">
            + {{ notificationsCount }}
          </div>
        </div>
        <div v-else>-</div>
      </vs-chip>

      <vs-dropdown-menu class="notifications-menu">
        <div
          class="notification__day"
          v-for="(n, index) in groupNotifications"
          :key="index"
        >
          <div class="notification__date">
            <span class="notification__date__day">{{
              n.dateStart.getDate()
            }}</span>
            <span class="notification__date__month">
              {{
                n.dateStart.toLocaleString('default', {
                  month: 'long',
                })
              }}
            </span>
          </div>
          <div class="notification__items">
            <div class="notification__items__bd">
              <div
                class="notification__items__student-bd"
                :set="
                  (items = n.items.filter(n => n.type == 'student_birthday'))
                "
              >
                <vs-dropdown vs-custom-content v-if="items.length">
                  <span> 🎉 {{ items[0].items.length }} </span>
                  <vs-dropdown-menu class="student-bd-dropdown">
                    <div
                      class="student"
                      v-for="student in items[0].items"
                      :key="student.id"
                    >
                      <router-link
                        :to="{
                          name: 'Student',
                          params: { student_id: student.id },
                        }"
                      >
                        {{ student.name }}
                      </router-link>
                    </div>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
              <div class="notification__items__teacher-bd">
                <div
                  class="notification__items__student-bd"
                  :set="
                    (items = n.items.filter(n => n.type == 'teacher_birthday'))
                  "
                >
                  <div class="teacher" v-if="items.length">
                    <teacher
                      v-for="(t, index) in items[0].items"
                      :key="index"
                      :teacher="t"
                      size="15px"
                      >{{ t.name }}</teacher
                    >
                  </div>
                </div>
              </div>
            </div>
            <div
              class="notification__items__events"
              :set="(items = n.items.filter(n => n.type == 'Tasks'))"
            >
              <div
                class="task"
                v-for="t in items"
                :key="t.id"
                :style="{
                  backgroundColor: t.event.all_day ? t.event.color : 'inherit',
                  color: t.event.all_day ? '#fff' : 'inherit',
                }"
              >
                <div class="circle" v-if="!t.event.all_day">
                  <vs-icon
                    icon="circle"
                    size="10px"
                    :color="t.event.color"
                  ></vs-icon>
                </div>
                <div class="time" v-if="!t.event.all_day">
                  {{ $moment(t.date_start * 1000).format('HH:mm') }}
                </div>
                <div class="name">
                  {{ t.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import teacher from './teacher.vue'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapState({
      notifications: state => state.events.notifications,
    }),
    groupNotifications: function() {
      // группируем по дням
      const notices = this.notifications.reduce((prev, cur) => {
        const dateStart = new Date(cur.date_start * 1000)
        const date = dateStart.getDate()

        if (!(date in prev)) {
          prev[date] = {
            dateStart,
            items: [],
          }
        }
        prev[date].items.push(cur)
        return prev
      }, {})
      return Object.values(notices).sort((a, b) => a.dateStart - b.dateStart)
    },
    nearEvent: function() {
      let notifications = this.notifications.toSorted(
        (a, b) => a.date_start - b.date_start
      )
      return notifications.find(n => ['Tasks'].includes(n.type))
    },
    notificationsCount: function() {
      let events = this.notifications.filter(n =>
        ['Tasks', 'teacher_birthday'].includes(n.type)
      )
      return events.length
    },
  },
  components: {
    teacher,
  },
  created: async function() {
    this.$store.dispatch('events/getNotificationsAction')
  },
}
</script>

<style lang="scss">
.notifications__block {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.notifications-menu {
  min-width: 20vw;
  .vs-dropdown--menu {
    padding: 1rem !important;
  }
}
.notification__button {
  color: var(--text-color) !important;
  &__info {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    & > * {
      margin: 0 3px;
    }
  }
}
.notification {
  &__icon {
    &:hover {
      opacity: 1;
    }
    & > span.material-icons {
      font-size: 24px !important;
      opacity: 0.8;
    }
  }
  &__day {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 1rem;
    &:not(:last-child) {
      border-bottom: 1px solid #eee;
      padding-bottom: 1rem;
    }
  }
  &__date {
    display: flex;
    flex-flow: column nowrap;
    justify-items: center;
    text-align: center;
    &__day {
      font-size: 3rem;
    }
  }
  &__items {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    padding: 5px 0;
    &__bd {
      display: flex;
      flex-flow: row nowrap;
      gap: 1rem;
      align-items: center;
      margin-bottom: 3px;
    }
    &__student-bd {
      padding: 3px;
      border-radius: 3px;
      &:hover {
        background-color: #ddd;
      }
    }
    &__events {
      display: flex;
      flex-flow: column nowrap;
      gap: 3px;
      .task {
        padding: 2px 5px;
        border-radius: 3px;
        display: flex;
        flex-flow: row nowrap;
        gap: 10px;
        width: 100%;
        .name {
          font-weight: bold;
        }
        &:hover {
          background-color: #ddd !important;
          color: inherit !important;
        }
      }
    }
  }
}
.student-bd-dropdown {
  z-index: 40002 !important;
  .vs-dropdown--menu {
    padding: 1rem !important;
  }
}
</style>
