import Vue from 'vue'

export default function deleteGroupTypes(id) {
    const proxy = process.env.VUE_APP_PROXY
    const api_uri = process.env.VUE_APP_API_URI

    let url = proxy + api_uri + "/group_types/" + id
    return Vue.http
        .delete(url, {}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .catch(response => response.json())
}