<template>
  <div class="teacher-element" :class="{ 'in-work': inWork }">
    <vs-avatar
      :src="
        teacher.options && teacher.options.avatar
          ? `${proxy}/static/files/${teacher.options.avatar}`
          : undefined
      "
      :size="size"
    ></vs-avatar>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    teacher: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: 'small',
    },
    inWork: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      proxy: process.env.VUE_APP_PROXY,
    }
  },
}
</script>

<style lang="scss">
.teacher-element {
  &.in-work {
    .con-vs-avatar {
      outline: 2px solid rgba(var(--vs-primary));
    }
  }
}
</style>
