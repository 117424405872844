import Vue from 'vue'

export default function saveRoom({ id, startDate, endDate }) {
    const proxy = process.env.VUE_APP_PROXY
    const api_uri = process.env.VUE_APP_API_URI

    let url = proxy + api_uri + "/schoolyears"
    let data = {
        date_start: `${startDate.getDate()}.${startDate.getMonth() + 1}.${startDate.getFullYear()}`,
        date_end: `${endDate.getDate()}.${endDate.getMonth() + 1}.${endDate.getFullYear()}`,
    }
    if (id) data.id = id
    return Vue.http
        .post(url, [data], {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json()).catch(() => { throw "Ошибка сохранения" })
}