import login from "@/api/auth/login";

const state = { status: false, token: localStorage.getItem('token') || '', user: {} };

const getters = {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
}


const actions = {
    loginAction({ commit }, { nickname, password }) {
        console.log(nickname, password)
        return new Promise((resolve, reject) => {
            login.call(this, { nickname, password }).then(response => {
                if (response.status == 'OK') {


                    commit('loginMutation', { data: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                state.status = false
                reject(new Error(e))
            })
        })
    },

}

const mutations = {
    loginMutation(state, { data }) {
        localStorage.setItem('token', data.token)
        localStorage.setItem('user', JSON.stringify(data.user))
        state.token = data.token
        state.user = data.user
        state.status = true

    }

}
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}