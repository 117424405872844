import Vue from 'vue'

export default function getGroupBooks(query) {
    const proxy = process.env.VUE_APP_PROXY
    const api_uri = process.env.VUE_APP_API_URI

    let url = proxy + api_uri + "/books/group/" + query.group_id
    // if (query) {
    //     let q = new URLSearchParams(query).toString()
    //     url += `?${q}`
    // }
    return Vue.http
        .get(url, {}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
}