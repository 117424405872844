<template>
  <div class="table">
    <table class="attendance">
      <thead>
        <tr>
          <th></th>
          <th>
            {{
              prev_lesson.lesson_time
                ? $moment(prev_lesson.lesson_time * 1000).format('DD.MM')
                : '-'
            }}
          </th>
          <th>
            {{ $moment(lesson.lesson_time * 1000).format('DD.MM') }}
          </th>
          <th>Book to give out</th>
          <th>Test</th>
          <th>Prev H/W</th>
          <th>Notes</th>
          <th>H/W</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(student, index) in studentsSorted" :key="student.id">
          <td
            class="student__name"
            :class="{
              'is-trial': student.status == 6,
            }"
          >
            <router-link
              :to="{
                name: 'Student',
                params: { student_id: student.id },
              }"
              class="student-link"
            >
              {{ student.name }}
            </router-link>
            <span
              class="add-surprise"
              title="Add surprise"
              @click="addSurpriseHandler(student)"
              v-if="lesson.date_start"
            >
              <vs-icon icon="star" color="primary"></vs-icon>
            </span>
          </td>
          <td
            class="student__prev-attendance"
            :class="{
              danger: student.prev && student.prev.exist == 'н',
              wrong: student.prev && student.prev.exist == 'б',
              disabled: !student.prev,
              empty: student.prev && student.prev.exist == '0',
              warn: student.prev && student.prev.exist == 'ч',
              'is-trial': student.prev && student.prev.is_trial,
            }"
          >
            <div>{{ student.prev ? student.prev.exist : null }}</div>
            <div
              class="comment"
              v-if="student.prev && student.prev.comment"
              :title="student.prev.comment"
            ></div>
          </td>
          <td
            class="student__attendance"
            :class="{
              danger: student.exist == 'н',
              wrong: student.exist == 'б',
              disabled: !student.exist && !isLessonInWork,
              empty: student.exist == '0',
              warn: student.exist == 'ч',
              'is-trial': student.is_trial,
            }"
          >
            <span v-if="isLessonInWork">
              <edit-attandance
                :lesson="lesson"
                :link_id="student.link_id"
                :value="student.exist"
                type="exist"
                :index="index"
                :student_id="student.id"
              />
            </span>
            <span v-else>
              {{ student.exist }}
            </span>
          </td>
          <td class="student__book-out">
            <div
              class="student__book-out__item"
              v-for="(book, index) in student.books"
              :key="index"
              :class="{ 'to-giveout': !book.delivered_date }"
            >
              <div class="book-out__name">
                {{ book.name }}
              </div>
              <div class="book-out__give-out" v-if="!book.delivered_date">
                <vs-button
                  icon="call_missed_outgoing"
                  color="rgba(0,0,200,0.1)"
                  text-color="#222"
                  size="small"
                  @click="bookGiveOut(book.id)"
                  >Give out</vs-button
                >
              </div>
              <div class="book-out__delivered" v-else>
                <vs-icon icon="done" color="success"></vs-icon>
              </div>
            </div>
          </td>
          <td
            class="student__prev-test"
            :class="{
              disabled: !student.prev_test || !student.prev_test.value,
            }"
          >
            <div v-if="student.prev_test && student.prev_test.value">
              {{ student.prev_test.value }}/{{
                student.prev_test.test.max_value || '-'
              }}
              <div
                class="col-test"
                :class="{
                  high: student.prev_test.persent >= 85,
                  subhigh:
                    student.prev_test.persent >= 75 &&
                    student.prev_test.persent < 85,
                  middle:
                    student.prev_test.persent >= 50 &&
                    student.prev_test.persent < 75,
                  low: student.prev_test.persent < 50,
                }"
              >
                <sub>{{ student.prev_test.persent }}%</sub>
              </div>
            </div>
          </td>
          <td
            v-if="index == 0"
            :rowspan="students.length"
            class="lesson__comments__cell prev-comment"
          >
            <div
              class="lesson__comments"
              v-for="comment in prev_comments"
              :key="comment.id"
            >
              <div class="lesson__comments__header">
                <teacher :teacher="comment.teacher" size="16px">{{
                  comment.teacher.nickname
                }}</teacher>
              </div>
              <div v-html="comment.comment" class="lesson__comment"></div>
              <div class="lesson__comment__files" v-if="comment.files.length">
                <files-list :files="comment.files" size="small" />
              </div>
            </div>
          </td>
          <td v-if="index == 0" :rowspan="students.length" class="lesson__note">
            <div class="lesson__notes" v-for="note in notes" :key="note.id">
              <div class="lesson__notes__header">
                <teacher :teacher="note.teacher" size="16px">{{
                  note.teacher.nickname
                }}</teacher>
                <vs-button
                  size="small"
                  type="flat"
                  title="Edit note"
                  @click="editNoteHandler($event, note)"
                >
                  <vs-icon icon="edit" size="12px"></vs-icon
                ></vs-button>
              </div>

              <div v-html="note.note" class="note"></div>
            </div>
            <div class="lesson__comment__addnew">
              <vs-button
                type="flat"
                size="small"
                @click="addNoteHandler($event)"
              >
                <vs-icon icon="add" size="1.1rem"></vs-icon>Add new</vs-button
              >
            </div>
          </td>
          <td
            v-if="index == 0"
            :rowspan="students.length"
            class="lesson__comments__cell"
          >
            <div
              class="lesson__comments"
              v-for="comment in comments"
              :key="comment.id"
            >
              <div class="lesson__comments__header">
                <teacher :teacher="comment.teacher" size="16px">{{
                  comment.teacher.nickname
                }}</teacher>
                <vs-button
                  size="small"
                  type="flat"
                  title="Edit comment"
                  @click="editCommentHandler($event, comment)"
                >
                  <vs-icon icon="edit" size="12px"></vs-icon
                ></vs-button>
              </div>

              <div v-html="comment.comment" class="lesson__comment"></div>
              <div class="lesson__comment__files" v-if="comment.files.length">
                <files-list :files="comment.files" size="small" />
              </div>
            </div>
            <div class="lesson__comment__addnew">
              <vs-button
                type="flat"
                size="small"
                @click="addCommentHandler($event)"
              >
                <vs-icon icon="add" size="1.1rem"></vs-icon>Add new</vs-button
              >
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="popup-edit" v-if="surprisePopupActive">
      <vs-popup
        class="form-popup surprise__popup"
        classContent="surprise__popup-content"
        title="Add surprise"
        :active.sync="surprisePopupActive"
        v-if="surprisePopupActive"
      >
        <surprise-form
          :students="lesson.students"
          :selectedStudentId="studentToAddSurprise.id"
          :lesson="lesson"
          :teacher_id="lesson.main_teacher.id"
          @closePopup="closePopup"
        />
      </vs-popup>
    </div>
  </div>
</template>

<script>
import teacher from '../common/elements/teacher.vue'
import EditAttandance from './components/editAttandance.vue'
import eventBus from '@/eventBus.js'
import FilesList from '@/components/files/filesList'
import SurpriseForm from '@/components/students/surprisers/surpriseForm.vue'

export default {
  components: { teacher, EditAttandance, SurpriseForm, FilesList },
  props: [
    'lesson',
    'students',
    'comments',
    'notes',
    'prev_comments',
    'prev_lesson',
    'isLessonInWork',
  ],
  data() {
    return {
      selectedCellElement: null,
      sourceValue: null,
      editedCell: {},
      surprisePopupActive: false,
      studentToAddSurprise: null,
    }
  },
  computed: {
    studentsSorted: function() {
      let students = this.students.map(s => {
        let books = []
        for (let key in this.lesson.books_to_out) {
          let st = this.lesson.books_to_out[key].students.find(
            student => s.id == student.student_id
          )
          if (st) {
            books.push({
              id: key,
              name: this.lesson.books_to_out[key].name,
              ...st,
            })
          }
        }
        for (let key in this.lesson.books_out) {
          let st = this.lesson.books_out[key].students.find(
            student => s.id == student.student_id
          )
          if (st) {
            books.push({
              book_id: key,
              name: this.lesson.books_out[key].name,
              ...st,
            })
          }
        }
        return { ...s, books }
      })
      return students.sort((a, b) => {
        return a.name.localeCompare(b.name)
      })
    },
  },
  methods: {
    addCommentHandler: function(event) {
      eventBus.$emit('editComment', event, this.lesson)
    },
    addNoteHandler: function(event) {
      eventBus.$emit('editNote', event, this.lesson)
    },
    editCommentHandler: function(event, comment) {
      eventBus.$emit('editComment', event, this.lesson, comment)
    },
    editNoteHandler: function(event, note) {
      eventBus.$emit('editNote', event, this.lesson, note)
    },
    bookGiveOut: function(book_out_id) {
      eventBus.$emit('bookGiveOut', book_out_id, this.lesson.id)
    },
    addSurpriseHandler: function(student) {
      this.studentToAddSurprise = student
      this.surprisePopupActive = true
    },
    closePopup: function() {
      this.surprisePopupActive = false
      this.studentToAddSurprise = null
    },
  },
}
</script>

<style lang="scss">
.lesson__table {
  table {
    thead {
      th {
        font-size: 0.7rem;
        border: 1px solid #ccc;
      }
    }
    border-collapse: collapse;
    margin-top: 0.5rem;
    td {
      padding: 8px;
      border: 1px solid #ccc;
      &.student__attendance {
        padding: 0;
      }
      &.lesson__comments__cell,
      &.lesson__note {
        font-size: 0.85rem;
        vertical-align: top;
        width: 21%;
        padding: 2px 8px;
        text-align: left;
        &.prev-comment {
          width: 23%;
        }
      }
      &.is-trial {
        box-shadow: inset 0px 0px 0px 2px #5b3cc4;
        position: relative;
        &::after {
          content: 'trial';
          font-size: 0.8rem;
          position: absolute;
          top: 2px;
          right: 4px;
          color: #5b3cc4;
        }
      }
      .lesson__comments,
      .lesson__notes {
        margin-bottom: 1rem;
        .lesson__comment,
        .note {
          padding: 0 0 0 1rem;
          margin-bottom: 0.5rem;
        }
        &__header {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          .vs-button {
            margin-left: 0.5rem;
            padding: 5px;
          }
        }
        .lesson__comment__files {
          padding: 0 0 0 1rem;
        }
      }
      &.lesson__note {
        width: 25%;
      }
      &.danger {
        background-color: var(--table-danger-bcg);
      }
      &.empty {
        background-color: var(--table-empty-bcg);
      }
      &.disabled {
        background-color: var(--table-disabled-bcg);
      }
      &.warn {
        background-color: var(--table-warn-bcg);
      }
      &.wrong {
        background-color: var(--table-wrong-bcg);
      }
    }
    .student {
      &__name {
        width: 250px;
        position: relative;
        &:hover {
          .add-surprise {
            visibility: visible;
          }
        }
      }
      &__attendance,
      &__prev-attendance {
        width: 50px;

        text-align: center;
      }
      &__prev-test {
        width: 90px;
        text-align: center;
        font-size: 0.8rem;
      }
      &__book-out {
        width: 150px !important;
        vertical-align: middle !important;
        .student__book-out__item {
          font-size: 0.7rem;
          &.to-giveout .book-out__name {
            font-weight: bold;
          }
          .vs-button {
            float: none;
            padding: 3px 0.5rem;
          }
          i {
            font-size: 1rem;
          }
        }
        .student__book-out__item:not(:last-child) {
          margin-bottom: 0.4rem;
        }
      }
      &__attendance {
        input {
          width: 100%;
          height: 100%;
        }
      }
    }
    .comment {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #c00;
      cursor: pointer;
      margin: 0 auto;
    }
    .low sub {
      color: rgba(var(--vs-danger));
    }
    .middle sub {
      color: rgba(var(--vs-warning));
    }
    .subhigh sub {
      color: rgba(var(--vs-dark));
    }
    .high sub {
      color: rgba(var(--vs-success));
    }
    .add-surprise {
      visibility: hidden;
      height: 2rem;
      background-color: #eee;
      border-radius: 7px 0 0 7px;
      position: absolute !important;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      box-shadow: -3px 1px 15px 1px #eee;
      padding: 0 5px;
      cursor: pointer;
    }
  }
}
</style>
