<template>
  <div class="comments">
    <div class="comment__form__add-button" v-hotkey="keymap">
      <vs-button
        :title="`Add new Comment to ${this.itemType}`"
        type="flat"
        icon="add_comment"
        color="primary"
        @click="showCommentForm = true"
        >{{ `Add Comment to ${this.itemType} (or press m)` }}</vs-button
      >
    </div>
    <div class="comments-form" v-if="showCommentForm">
      <CommentsForm
        :itemType="itemType"
        :itemId="itemId"
        v-if="showCommentForm"
        @closeCommentForm="closeCommentFormHandle"
      />
    </div>
    <div class="comments-list">
      <CommentsList :comments="comments" />
    </div>

    <div v-if="showMoreButton" class="comments__load-more">
      <vs-button
        type="border"
        color="primary"
        icon="file_download"
        @click="loadMoreHandler"
        >Load more Comments</vs-button
      >
    </div>
  </div>
</template>

<script>
// import { mapState } from "vuex";

import CommentsList from '@/components/comments/commentsList'
import CommentsForm from '@/components/comments/commentsForm'
import getComments from '@/api/comments/getComments'
import eventBus from '@/eventBus.js'

export default {
  name: 'comments',
  // props: [
  //   "student_id",
  //   "group_id",
  //   "teacher_id",
  //   "lesson_id",
  //   "book_id",
  //   "result_id",
  //   "item_type",
  // ],
  props: ['itemId', 'itemType'],
  components: {
    CommentsList,
    CommentsForm,
  },
  data() {
    return {
      showCommentForm: false,
      currentPage: 1,
      comments: [],
      meta: {},
    }
  },
  computed: {
    // ...mapState({
    //   comments: (state) => state.comments.comments,
    //   meta: (state) => state.comments.commentMeta,
    // }),
    showMoreButton: function() {
      return this.meta.total > 1 && this.currentPage < this.meta.next
    },
    keymap() {
      return {
        m: this.showCommentFormHandler,
      }
    },
  },
  created() {
    setTimeout(() => {
      eventBus.$on('updateCommentsList', this.loadComments)
    }, 10)
    this.loadComments()
    // setTimeout(() => {
    // getComments
    //   .call(this, query)
    //   .then((response) => {
    //     if (response.status == "OK") {
    //       this.comments = response.data;
    //     } else {
    //       console.log(response.message);
    //     }
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
    // this.$store.dispatch("comments/getCommentsAction", query).then(data => {
    //   this.commentsList = data;
    // });
    // }, 500);
  },
  beforeDestroy() {
    eventBus.$off('updateCommentsList')
  },
  methods: {
    closeCommentFormHandle: function() {
      this.showCommentForm = false
    },
    loadComments: function() {
      const { itemId, itemType } = this
      if (!itemId) return
      const query = {
        itemId,
        itemType,
        query: {},
      }
      if (this.currentPage > 1) query.query.page = this.currentPage

      getComments.call(this, { ...query }).then(response => {
        if (response.status == 'OK') {
          this.meta = response.meta
          if (this.meta.page > 1)
            this.comments = [...this.comments, ...response.data]
          else this.comments = response.data
        } else {
          console.log(response.message)
        }
      })
    },
    loadMoreHandler: function() {
      this.currentPage++
      this.loadComments()
    },
    showCommentFormHandler: function() {
      this.showCommentForm = true
    },
  },
}
</script>

<style></style>
