import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vuesax from 'vuesax'
import VueResource from "vue-resource"
import Vuelidate from 'vuelidate'
import VueSplit from 'vue-split-panel'
import VCalendar from 'v-calendar';
import VueTimepicker from 'vue2-timepicker'
import VueMoment from 'vue-moment'
import VueBreadcrumbs from 'vue-2-breadcrumbs';
import VueHotkey from 'v-hotkey'
// import moment from 'moment-timezone'
import User from "@/plugins/UserPlugin"
import Round from "@/plugins/RoundPlugin"
import InfiniteLoading from 'vue-infinite-loading';
import { abilitiesPlugin } from '@casl/vue';
import ability from './services/ability';
import Autocomplete from '@trevoreyre/autocomplete-vue'

import 'vuesax/dist/vuesax.css' //Vuesax styles
import 'material-icons/iconfont/material-icons.css';
import 'vue2-timepicker/dist/VueTimepicker.css'
import '@trevoreyre/autocomplete-vue/dist/style.css'

import titleMixin from './mixins/titleMixin'
import 'viewerjs/dist/viewer.css'

Vue.use(Vuesax)
Vue.use(VueResource)
Vue.use(Vuelidate)
Vue.use(VueSplit)
Vue.use(VCalendar)
Vue.use(VueTimepicker)
Vue.use(User)
Vue.use(Round)
Vue.use(InfiniteLoading, { /* options */ });

Vue.use(VueMoment)
Vue.use(abilitiesPlugin, ability);
Vue.use(Autocomplete)
Vue.use(VueBreadcrumbs);
Vue.use(VueHotkey)

Vue.config.productionTip = false;

Vue.mixin(titleMixin)

const token = localStorage.getItem('token')
if (token) {
  Vue.http.interceptors.push((request, next) => {
    request.headers.set('Authorization', token)
    request.headers.set('Accept', 'application/json')
    next()
  })
  Vue.http.interceptors.push(function () {
    return function (response) {
      // modify response
      if (response.status == 400 && response.body.message == 'Token is invalid') {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        router.push({ name: 'Login' })
        window.location.reload()
      }
      if (response.status == 403) {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        router.push({ name: 'Login' })
        window.location.reload()
      }
    };
  })
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
