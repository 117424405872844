<template>
  <div class="lesson-edit-attendance">
    <textarea
      :data-index="`exist-${lesson.id}-${index}`"
      @keydown="nameKeydown($event)"
      @focus="cellFocus"
      @blur="lostFocus($event)"
      class="attendance-input"
      type="text"
      v-model="fieldValue"
      ref="field"
      :disabled="disabled"
    ></textarea>
    <div class="save-tooltip" v-if="viewSaveButtons">
      <div class="save-tooltip__content">
        <vs-button
          data-type="cell-action-button"
          type="flat"
          icon="check"
          title="Save"
          @click="saveHandle"
          :disabled="disabled"
        ></vs-button>
        <vs-button
          data-type="cell-action-button"
          type="flat"
          color="dark"
          icon="close"
          title="Cancel"
          @click="cancelEditHandle"
          :disabled="disabled"
        ></vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/eventBus.js'
export default {
  props: {
    lesson: {
      type: Object,
    },
    type: {
      type: String,
      default: 'exist',
    },
    student_id: {
      type: Number,
    },
    link_id: {
      type: Number,
    },
    value: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      fieldValue: this.value,
      viewSaveButtons: false,
      disabled: false,
    }
  },
  methods: {
    cellFocus: function() {
      this.viewSaveButtons = true
    },
    nameKeydown: function(e) {
      if (this.type == 'exist') {
        if (e.key == 'Backspace') return
        if (!/^[-|1|н|б|ч|0]$/.test(e.key)) {
          e.preventDefault()
        }
      }
      if (e.key == 'Enter') {
        if (this.type == 'exist') {
          this.saveHandle().then(() => {
            e.target.classList.add('saved')

            const nextField = document.querySelector(
              `textarea[data-index="${this.type}-${this.lesson.id}-${this
                .index + 1}"]`
            )
            if (nextField) {
              nextField.focus()
            }
          })
          return
        }
      }

      this.$refs.field.classList.add('changed')
    },
    lostFocus: function() {
      setTimeout(() => {
        this.viewSaveButtons = false
      }, 50)
    },
    changeCell: function() {},
    saveHandle: function() {
      // пока сохраняем только занятие, так как компонент рассчитан только
      // на главную страницу системы
      let { lesson, student_id, link_id, type, fieldValue } = this
      const data = {
        id: lesson.id,
        student_id,
        link_id,
        lesson_id: lesson.id,
        columnType: type,
      }
      data[type] = fieldValue

      if (type == 'exist' && fieldValue == '-') data[type] = 'н'
      this.disabled = true
      return this.$store
        .dispatch('lessons/visitLessonAction', {
          data,
        })
        .then(() => {
          this.$refs.field.classList.remove('changed')
          this.viewSaveButtons = false
          this.fieldValue = this.value != this.fieldValue ? '' : this.value

          this.$vs.notify({
            title: 'Lesson saved',
            text: `Changes in lesson saved`,
            color: 'success',
            position: 'top-right',
          })
          this.disabled = false
          eventBus.$emit('updateLessonStatus')
        })
        .catch(message => {
          this.$vs.notify({
            title: 'Lesson error',
            text: `${message}`,
            color: 'danger',
            position: 'top-right',
            time: 6000,
          })
          this.disabled = false
        })
    },
    cancelEditHandle: function() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirm`,
        text: 'Are You sure to cancel changes?',

        accept: () => {
          this.fieldValue = this.value
          this.viewSaveButtons = false
          this.$refs.field.classList.remove('changed')
        },
      })
    },
  },
  watch: {
    value: function(newV) {
      console.log(newV)
      this.fieldValue = newV
    },
  },
}
</script>

<style lang="scss">
.lesson-edit-attendance {
  position: relative;
  textarea {
    outline: 1px solid transparent;
    border: none;
    background: none;
    width: auto;
    padding: 10px;
    width: 100%;
    height: 100%;
    transition: all 0.3s;
    resize: none;
    &.changed {
      background-color: #efefef;
      outline: 2px solid #c00;
    }
    &:focus {
      outline-color: rgba(var(--vs-primary), 0.5);
    }
  }
  .save-tooltip {
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 1000;
  }
}
</style>
