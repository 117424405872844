<template>
  <div
    class="task-item"
    :class="{
      student: task.students.length,
      group: task.group.id,
      lesson: task.lesson.id,
      small: size == 'small',
      tiny: size == 'tiny',
    }"
  >
    <div
      class="task-item__importance__border"
      :class="importance.name.toLowerCase()"
    ></div>
    <vs-row vs-w="12" class="task-item-info">
      <div class="task-item-info__id" :class="status.class">#{{ task.id }}</div>
      <div class="task-item-info__name">
        {{ task.name }}
      </div>
      <task-dates :task="task" :size="size" v-if="size == 'tiny'"></task-dates>
      <task-status :status="status" />
    </vs-row>
    <vs-row vs-w="12" class="task-item-sub" v-if="size == 'small'">
      <div class="task-item-sub__places">
        <div class="task-item-place__group" v-if="task.group.id">
          <vs-avatar
            icon="people"
            :color="task.group.color"
            size="16px"
            :title="task.group.name"
          ></vs-avatar>
          <span>{{ task.group.name }}</span>
        </div>
        <div class="task-item-place__lesson" v-if="task.lesson.id">
          <vs-avatar
            icon="query_builder"
            size="16px"
            color="#aaa"
            :title="
              `${task.lesson.name} - ${$moment(
                task.lesson.lesson_time * 1000
              ).format('DD.MM.YYYY HH:mm')}`
            "
          ></vs-avatar>
          <span
            >{{ task.lesson.name }} -
            {{
              $moment(task.lesson.lesson_time * 1000).format('DD.MM.YYYY HH:mm')
            }}</span
          >
        </div>
      </div>
    </vs-row>
    <vs-row class="task-item-sub task-students">
      <div
        class="task-item-place__student"
        v-for="(student, index) in task.students"
        :key="index"
      >
        <div v-if="student && student.first_name">
          <vs-avatar
            icon="person"
            :color="student.color"
            size="16px"
            :title="`${student.last_name} ${student.first_name}`"
          ></vs-avatar>
          <span
            >{{ student.last_name }}
            <span v-if="task.students.length <= 2">{{
              student.first_name
            }}</span
            ><span v-else>{{ student.first_name.slice(0, 1) }}.</span></span
          >
        </div>
      </div>
    </vs-row>
    <vs-row class="task-item-sub task-teachers" v-if="size != 'tiny'">
      <div class="task-item-sub__teachers">
        <div class="task-item-sub__importance-item">
          <vs-icon
            :icon="importance.icon"
            :color="importance.color"
            :title="importance.name"
          ></vs-icon>
        </div>
        <div
          class="task-item-sub__teacher"
          v-for="t in task.teachers"
          :key="t.id"
        >
          <teacher-chip-view :teacher="t" size="small" />
        </div>
      </div>
      <task-dates :task="task"></task-dates>
    </vs-row>
  </div>
</template>

<script>
import { TASK_IMPORTANCE, TASK_STATUS } from './dicts'
import taskStatus from './taskStatus.vue'
import teacherChipView from '@/components/teachers/teacherChipView'
import taskDates from './taskDates.vue'

export default {
  components: { taskStatus, teacherChipView, taskDates },
  props: {
    task: Object,
    size: {
      type: String,
      default: 'small',
    },
  },
  data() {
    return {
      proxy: process.env.VUE_APP_PROXY,
    }
  },
  computed: {
    importance: function() {
      return (
        TASK_IMPORTANCE.find(i => i.id == this.task.importance) ||
        TASK_IMPORTANCE[1]
      )
    },
    status: function() {
      return TASK_STATUS.find(s => s.id == this.task.status) || TASK_STATUS[0]
    },
  },
}
</script>

<style lang="scss"></style>
