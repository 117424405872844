<template>
  <div class="group-currrent-books">
    <vs-chip v-if="book" color="#f0e9dd">{{ book }}</vs-chip>
    <vs-chip v-if="reading" color="#e1f0dd">{{ reading }}</vs-chip>
    <vs-chip v-if="verb" color="#dde0f0">{{ verb }}</vs-chip>
    <vs-chip v-if="exam" color="#f0ddde">{{ exam }}</vs-chip>
  </div>
</template>

<script>
export default {
  props: ['current_books'],

  data() {
    return {}
  },
  computed: {
    book: function() {
      return this.current_books?.book?.name
    },
    reading: function() {
      return this.current_books?.reading?.name
    },
    verb: function() {
      return this.current_books?.verb?.name
    },
    exam: function() {
      return this.current_books?.exam?.name
    },
  },
  created() {},
}
</script>

<style lang="scss" scoped>
.group-currrent-books {
  margin-right: 0.2rem;
  display: flex !important;
  flex-flow: row nowrap;
  .con-vs-chip {
    color: var(--font-color) !important;
    font-weight: bold;
    margin-bottom: 0;
    margin-right: 0.5rem;
    font-size: 0.7rem !important;
    display: flex;
    box-shadow: none;
  }
}
</style>
