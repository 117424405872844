import getHomeLessons from "@/api/home/getHomeLessons";
// import saveRoom from "@/api/rooms/saveRoom";
// import deleteRoom from "@/api/rooms/deleteRoom";

const state = { homeLessons: [], meta: {}, pages: 0, currentPage: 1, perPage: 2, nextPage: undefined, prevPage: undefined };

const actions = {
    getHomeLessonsAction({ commit }, data) {
        return new Promise((resolve, reject) => {
            getHomeLessons.call(this, data).then(response => {
                if (response.status == 'OK') {
                    commit('setHomeLessonsMutation', { lessons: response.data, meta: response.meta })
                    resolve()
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    clearAction({commit}){
        commit('clearMutation')
    }
    // saveRoomAction({ commit }, { room }) {
    //     return new Promise((resolve, reject) => {
    //         saveRoom.call(this, { room }).then(response => {
    //             if (response.status == 'OK') {
    //                 commit('updateRoomsMutation', { rooms: response.data })
    //                 resolve()
    //             }
    //             else { reject(new Error(response.message)) }
    //         }).catch(e => { reject(new Error(e)) })
    //     })
    // },
    // deleteRoomAction({ commit }, { room }) {
    //     return new Promise((resolve, reject) => {
    //         deleteRoom.call(this, { room }).then(response => {
    //             if (response.status == 'OK') {
    //                 commit('deleteRoomsMutation', { room: response.data })
    //                 resolve()
    //             }
    //             else { reject(new Error(response.message)) }
    //         }).catch(e => { reject(new Error(e)) })
    //     })
    // }
}

let getNextPage = function(){
    const nextPage = state.currentPage + 1
        if (nextPage <= state.pages) {
            return nextPage
        } 
        return state.pages
}
let getPrevPage = function(){
    const prevPage = state.currentPage - 1
        if (prevPage > 0) {
            return prevPage
        } 
        return 1
}

const mutations = {
    setHomeLessonsMutation(state, { lessons, meta }) {
        state.homeLessons = lessons
        state.meta = meta
        state.pages = Math.ceil(meta.total/state.perPage)
        state.nextPage = getNextPage()
        state.prevPage = getPrevPage()
    },
    clearMutation(state){
        state.homeLessons = []
    }
    // updateRoomsMutation(state, { rooms }) {
    //     rooms.forEach(room => {
    //         const index = state.rooms.findIndex(r => r.id == room.id)
    //         if (index !== -1 && typeof index !== 'undefined') {
    //             state.rooms.splice(index, 1, room)
    //         } else {
    //             state.rooms = [...state.rooms, room]
    //         }
    //     })
    // },
    // deleteRoomsMutation(state, { room }) {
    //     const index = state.rooms.findIndex(r => r.id == room.id)
    //     if (typeof index !== 'undefined') {
    //         state.rooms.splice(index, 1)
    //     }
    // },
}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}