import Vue from 'vue'

export default function reopenLesson({id, teacherId}) {
    const proxy = process.env.VUE_APP_PROXY
    const api_uri = process.env.VUE_APP_API_URI

    let url = proxy + api_uri + "/lessons/" + id + "/reopen"
    
    let data = {}
    if(teacherId){
        data.teacher_id = teacherId
    }

    return Vue.http
        .patch(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json()).catch(() => { throw "Ошибка сохранения" })
}