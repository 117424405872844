<template>
  <div class="comment__item closed-task__item" :key="commentData.id">
    <div @click="viewPopupActive = true">
      <task-list-item-compact :task="commentData" />
    </div>

    <div v-if="viewPopupActive">
      <vs-popup
        class="form-popup teacher-tasks-popup-view"
        classContent="task__popup-content-edit-view"
        :title="`Task #${commentData.id}`"
        :active.sync="viewPopupActive"
        @close="closeTaskPopupHandler"
      >
        <task-view
          :task="commentData"
          @editTask="update"
          @taskSaved="update"
          :isEdit="false"
        />
      </vs-popup>
    </div>
  </div>
</template>

<script>
import taskListItemCompact from "../tasks/taskListItemCompact.vue";
import eventBus from "@/eventBus.js";

export default {
  components: {
    taskListItemCompact,
    taskView: () => import("../tasks/taskView.vue"), // Подключаем компонент динамически
  },
  name: "ClosedTaskCommentView",
  props: ["comment"],
  data() {
    return {
      proxy: process.env.VUE_APP_PROXY,
      viewPopupActive: false,
      commentData: this.comment,
    };
  },
  methods: {
    closeTaskPopupHandler: function () {
      this.viewPopupActive = false;
      // updateComments
    },
    update: function () {
      eventBus.$emit("updateCommentsList");
      eventBus.$emit("updateTasksList");
      this.viewPopupActive = false;
    },
  },
};
</script>

<style lang="scss">
</style>