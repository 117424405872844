<template>
  <div class="home">
    <HomeWrapper />
  </div>
</template>

<script>
// @ is an alias to /src
import HomeWrapper from "@/components/home/HomeWrapper.vue";

export default {
  name: "Home",
  title: "SE- Home",
  components: {
    HomeWrapper,
  },
};
</script>
