import getYears from "@/api/years/getYears";
import getYearMonths from "@/api/years/getYearMonths";
import getHolidays from "@/api/holidays/getHolidays";
import saveYear from "@/api/years/saveYear";
import deleteYear from "@/api/years/deleteYear";
import saveHolidays from "@/api/holidays/saveHolidays";

const state = { years: [], holidays: [], currentSchoolYear: {}, yearMonths: [] };

const actions = {
    getYearsAction({ commit }) {
        return new Promise((resolve, reject) => {
            getYears.call(this).then(response => {
                if (response.status == 'OK') {
                    commit('setYearMutation', { years: response.data })
                    resolve()
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    getHolidaysAction({ commit }, { schoolyear_id }) {
        return new Promise((resolve, reject) => {
            getHolidays.call(this, { schoolyear_id }).then(response => {
                if (response.status == 'OK') {
                    commit('setHolidaysMutation', { holidays: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    saveYearAction({ commit }, { id, startDate, endDate }) {
        return new Promise((resolve, reject) => {
            saveYear.call(this, { id, startDate, endDate }).then(response => {
                if (response.status == 'OK') {
                    commit('updateYearMutation', { years: response.data })
                    resolve()
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    saveHolidaysAction({ commit }, { schoolyear_id, holidays }) {
        return new Promise((resolve, reject) => {
            saveHolidays.call(this, { schoolyear_id, holidays }).then(response => {
                if (response.status == 'OK') {
                    commit('setHolidaysMutation', { holidays: response.data })
                    resolve()
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    deleteYearAction({ commit }, { year }) {
        return new Promise((resolve, reject) => {
            deleteYear.call(this, { year }).then(response => {
                if (response.status == 'OK') {
                    commit('deleteYearMutation', { year: response.data })
                    resolve()
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    getYearMonthsAction({ commit }, id) {
        return new Promise((resolve, reject) => {
            getYearMonths.call(this, id).then(response => {
                if (response.status == 'OK') {
                    commit('setYearMonthsMutation', { months: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    setCurrentYearAction({ commit }, { schoolyearId }) {
        commit('setCurrentYearMutation', { schoolyearId })
    }
}

const mutations = {
    setYearMutation(state, { years }) {
        state.years = years;
        const now_unix = new Date().getTime() / 1000;
        if (state.years) {
            const userYearId = window.localStorage.getItem('schoolyearId')
            if (userYearId && userYearId !== 'undefined') {
                state.currentSchoolYear = state.years.find(y => y.id == userYearId)
                return
            }
            state.currentSchoolYear = state.years.find(
                y =>
                    now_unix >= y.date_start_unix &&
                    now_unix <= y.date_end_unix
            ) || state.years[0];
            window.localStorage.setItem('schoolyearId', state.currentSchoolYear.id)
        }

    },
    updateYearMutation(state, { years }) {
        years.forEach(year => {
            const index = state.years.findIndex(r => r.id == year.id)
            if (index !== -1 && typeof index !== 'undefined') {
                state.years.splice(index, 1, year)
            } else {
                state.years = [...state.years, year]
            }
        })
    },
    deleteYearMutation(state, { year }) {
        const index = state.years.findIndex(r => r.id == year.id)
        if (typeof index !== 'undefined') {
            state.years.splice(index, 1)
        }
    },
    setHolidaysMutation(state, { holidays }) {
        state.holidays = holidays.map(h => {
            return new Date(h.date * 1000)
        })
    },
    setYearMonthsMutation(state, { months }) {
        state.yearMonths = months
    },
    setCurrentYearMutation(state, { schoolyearId }) {
        state.currentSchoolYear = state.years.find(y => y.id == schoolyearId)
        window.localStorage.setItem('schoolyearId', schoolyearId)

    }
}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}