import Vue from 'vue'

export default function doneLesson(id) {
    const proxy = process.env.VUE_APP_PROXY
    const api_uri = process.env.VUE_APP_API_URI

    let url = proxy + api_uri + "/lessons/" + id + "/stop"
    return Vue.http
        .put(url, {}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json()).catch(() => { throw "Ошибка сохранения" })
}