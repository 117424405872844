import Vue from 'vue'

export default function saveStudents({ studentId, groupId, insert, is_trial }) {
  const proxy = process.env.VUE_APP_PROXY
  const api_uri = process.env.VUE_APP_API_URI

  let url =
    proxy + api_uri + '/students/' + studentId + '/group/' + groupId + '/add'
  return Vue.http
    .put(
      url,
      { insert, is_trial },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(response => response.json())
    .catch(() => {
      throw 'Ошибка сохранения'
    })
}
