import getStudents from '@/api/students/getStudents'
import getActiveStudents from '@/api/students/getActiveStudents'
import setStudents from '@/api/students/setStudents'
import setStudentGroup from '@/api/students/setStudentGroup'
import getSingleStudents from '@/api/students/getSingleStudents'
import getStudentBalance from '@/api/students/getStudentBalance'
import deleteStudents from '@/api/students/deleteStudents'
import excludeStudentFromGroup from '@/api/students/excludeStudentFromGroup'
import addStudentToGroup from '@/api/students/addStudentToGroup'
import moveStudentToGroup from '@/api/students/moveStudentToGroup'
import removeStudentFromGroup from '@/api/students/removeStudentFromGroup'
import stayStudentInGroup from '@/api/students/stayStudentInGroup'

const state = {
  students: [],
  singleStudent: [],
  balances: {},
  activeStudentsCount: 0,
  studentsCount: {},
}

const actions = {
  getStudentsAction({ commit }, data) {
    return new Promise((resolve, reject) => {
      getStudents
        .call(this, data)
        .then(response => {
          if (response.status == 'OK') {
            commit('setStudentsMutation', {
              students: response.data,
              meta: response.meta,
            })
            resolve()
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  getActiveStudentsAction({ commit }, schoolyear_id) {
    return new Promise((resolve, reject) => {
      getActiveStudents
        .call(this, { schoolyear_id })
        .then(response => {
          if (response.status == 'OK') {
            commit('setActiveStudentsMutation', { response: response.data })
            resolve()
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  saveStudentsAction({ commit }, { student }) {
    return new Promise((resolve, reject) => {
      setStudents
        .call(this, { students: [student] })
        .then(response => {
          if (response.status == 'OK') {
            commit('updateStudentsMutation', { students: response.data })
            resolve(response.data)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  getSingleStudentAction({ commit }, { id, schoolyear_id }) {
    return new Promise((resolve, reject) => {
      getSingleStudents
        .call(this, id, schoolyear_id)
        .then(response => {
          if (response.status == 'OK') {
            commit('setSingleStudentsMutation', { student: response.data })
            resolve()
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  deleteStudentsAction({ commit }, id) {
    return new Promise((resolve, reject) => {
      deleteStudents
        .call(this, id)
        .then(response => {
          if (response.status == 'OK') {
            commit('deleteStudentsMutation', { student: response.data })
            resolve()
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  saveStudentGroupAction({ commit }, { student, group, groupData }) {
    return new Promise((resolve, reject) => {
      setStudentGroup
        .call(this, { studentId: student.id, groupId: group, data: groupData })
        .then(response => {
          if (response.status == 'OK') {
            commit('updateStudentsMutation', { students: [response.data] })
            resolve(response.data)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  excludeStudentFromGroupAction(
    { commit },
    { studentId, groupId, exit, comment, is_trial }
  ) {
    return new Promise((resolve, reject) => {
      excludeStudentFromGroup
        .call(this, { studentId, groupId, exit, comment, is_trial })
        .then(response => {
          if (response.status == 'OK') {
            commit('updateStudentsMutation', { students: [response.data] })
            resolve(response.data)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  removeStudentFromGroupAction({ commit }, { student_id, group_id, comment }) {
    return new Promise((resolve, reject) => {
      removeStudentFromGroup
        .call(this, { student_id, group_id, comment })
        .then(response => {
          if (response.status == 204) {
            commit('removeStudentFromGroupMutation')
            resolve()
          } else {
            reject()
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  addStudentToGroupAction(
    { commit },
    { studentId, groupId, insert, is_trial }
  ) {
    return new Promise((resolve, reject) => {
      addStudentToGroup
        .call(this, { studentId, groupId, insert, is_trial })
        .then(response => {
          if (response.status == 'OK') {
            commit('updateStudentsMutation', { students: [response.data] })
            resolve(response.data)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  moveStudentToGroupAction({ commit }, { studentId, groupId, data }) {
    return new Promise((resolve, reject) => {
      moveStudentToGroup
        .call(this, { studentId, groupId, data })
        .then(response => {
          if (response) {
            commit('updateStudentsMutation', { students: [response.data] })
            resolve(response.data)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  getStudentBalanceAction({ commit }, { student_id, schoolyear_id }) {
    return new Promise((resolve, reject) => {
      getStudentBalance
        .call(this, { student_id, schoolyear_id })
        .then(response => {
          if (response.status == 'OK') {
            commit('setStudentBalanceMutation', { balances: response.data })
            resolve(response.data)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  stayStudentInGroupAction({ commit }, { id, group_id }) {
    return new Promise((resolve, reject) => {
      stayStudentInGroup
        .call(this, { id, group_id })
        .then(response => {
          if (response) {
            commit('updateStudentsMutation', { students: [response.data] })
            resolve(response.data)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  clearAction({ commit }) {
    commit('clearMutation')
  },
}

const mutations = {
  setStudentsMutation(state, { students, meta }) {
    state.students = students
    state.studentsCount = meta
  },
  updateStudentsMutation(state, { students }) {
    students.forEach(student => {
      student.birthdate = student.birthdate
        ? new Date(student.birthdate * 1000)
        : null
      const index = state.students.findIndex(r => r.id == student.id)
      if (index !== -1 && typeof index !== 'undefined') {
        state.students.splice(index, 1, student)
      } else {
        state.students = [...state.students, student]
      }
      if (student.id == state.singleStudent.id) {
        state.singleStudent = student
      }
    })
  },
  setSingleStudentsMutation(state, { student }) {
    student.birthdate = student.birthdate
      ? new Date(student.birthdate * 1000)
      : null
    state.singleStudent = student
  },
  deleteStudentsMutation(state, { student }) {
    const index = state.students.findIndex(r => r.id == student.id)
    if (typeof index !== 'undefined') {
      state.students.splice(index, 1)
    }
  },
  setStudentBalanceMutation(state, { balances }) {
    state.balances = balances || {}
  },
  clearMutation(state) {
    state.singleStudent = {}
    state.balance = {}
  },
  removeStudentFromGroupMutation() {},
  setActiveStudentsMutation(state, { response }) {
    state.activeStudentsCount = response.count
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
