import Vue from 'vue'

export default function deleteBookIncome({incomeId}) {
    const proxy = process.env.VUE_APP_PROXY
    const api_uri = process.env.VUE_APP_API_URI

    let url = proxy + api_uri + "/books/in/" + incomeId
    return Vue.http
        .delete(url, { }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response).catch((e) => { throw e })
}