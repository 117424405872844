import Vue from "vue";


const eventBus = new Vue()

export default eventBus

// Alternate version. TODO: delete 
// class Event {
//     constructor() {
//         this.vue = new Vue();
//     }

//     fire(event, data = null) {
//         this.vue.$emit(event, data);
//     }

//     listen(event, callback) {
//         this.vue.$on(event, callback);
//     }

//     destroy(event) {
//         this.vue.$off(event)
//     }
// }

// export default Event;