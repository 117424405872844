import Vue from 'vue'

export default function getOrdersGroupsByStudent({ student, groups, schoolyear_id }) {
    const proxy = process.env.VUE_APP_PROXY
    const api_uri = process.env.VUE_APP_API_URI

    let url = proxy + api_uri + "/orders/students/" + student + "/orders_groups"
    if (groups && schoolyear_id) {
        let q = new URLSearchParams({ groups, schoolyear_id }).toString()
        url += `?${q}`
    }

    return Vue.http
        .get(url, {}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
}