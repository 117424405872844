<template>
  <div class="home">
    <div class="home__view">
      <div class="home__lessons__wrapper">
        <div class="scroll-top">
          <scroll-to-top />
        </div>
        <div class="home__lessons__header">
          <div class="status-bar__back-button">
            <vs-button
              icon="navigate_before"
              class
              type="border"
              color="dark"
              :title="`Go to previous day`"
              @click="goToPrevDay"
            ></vs-button>
          </div>
          <div class="status-bar__back-button">
            <vs-button
              icon="navigate_next"
              class
              type="border"
              color="dark"
              :title="`Go to next day `"
              @click="goToNextDay"
            ></vs-button>
          </div>
          <div class="home__current-date">
            {{ lessonDay.format('DD MMMM YYYY') }} ({{
              lessonDay.format('dddd')
            }})
          </div>
          <div class="home__navigate">
            <div
              class="home__teachers-filter"
              v-if="$can('manage', 'view_home_by_another_teacher')"
            >
              <v-select
                label="nickname"
                :options="teachers"
                v-model="selectedTeacherId"
                :reduce="item => item.id"
                autocomplete="on"
                clearable
                placeholder="Select teacher"
                @input="changeTeacherHandler"
              >
                <template #option="{ nickname, options }">
                  <vs-avatar
                    :src="
                      options && options.avatar
                        ? `${proxy}/static/files/${options.avatar}`
                        : undefined
                    "
                    size="small"
                  ></vs-avatar
                  >{{ nickname }}
                </template>
                <template #selected-option="{ nickname, options }">
                  <vs-avatar
                    :src="
                      options && options.avatar
                        ? `${proxy}/static/files/${options.avatar}`
                        : undefined
                    "
                    size="small"
                  ></vs-avatar>
                  {{ nickname }}
                </template>
              </v-select>
            </div>

            <Navigate />
            <vs-button
              type="flat"
              color="dark"
              title="print lessons"
              v-print="printObj"
              class="home__navigate__print"
              @click="printHandler"
              icon="print"
            >
            </vs-button>
          </div>
          <div class="home__goto-next-lesson" v-if="nearLesson.id">
            <vs-button
              icon="navigate_next"
              size="small"
              type="flat"
              @click="scrollToNextLesson"
              >View next lesson</vs-button
            >
          </div>
        </div>
        <div class="home__lessons" v-if="homeLessons.length">
          <div
            class="home__lesson"
            v-for="lesson in homeLessons"
            :key="lesson.id"
            :id="`lesson-${lesson.id}`"
            :class="{ near: nearLesson && nearLesson.id == lesson.id }"
          >
            <home-lesson
              :lesson="lesson"
              :teacher_id="selectedTeacherId || user.id"
              @viewTask="viewTaskPopupHandler"
            />
          </div>
        </div>
        <div class="home__lessons" v-else>
          <vs-alert icon="search">No lessons today</vs-alert>
        </div>
      </div>
    </div>
    <div class="home__print" id="home__print">
      <div class="home__lessons" v-if="printView && homeLessons.length != 0">
        <div
          class="home__lesson"
          v-for="lesson in homeLessons"
          :key="lesson.id"
        >
          <home-lesson :lesson="lesson" :teacher_id="selectedTeacherId" />
          <!-- <div class="print__page-break" v-if="index % 2 != 0"></div> -->
        </div>
      </div>
      <div class="home__lessons" v-else>No lessons today</div>
    </div>
    <div v-if="commentPopupActive">
      <draggable-panel :X="dragPositionX" :Y="dragPositionY">
        <template v-slot:header> Comment by {{ lessonName }} </template>
        <comments-form
          itemType="Lessons"
          :itemId="lessonId"
          :commentItem="editedComment"
          class="small"
          @closeCommentForm="closeHandler"
          @updateCommentsList="commentSavedHandler"
        />
      </draggable-panel>
    </div>
    <div v-if="notesPopupActive">
      <draggable-panel :X="dragPositionX" :Y="dragPositionY">
        <template v-slot:header> Note by {{ lessonName }} </template>
        <note-edit-form
          :note="editedNote"
          @noteSaved="noteSavedHandler"
          class="small"
          @closeForm="closeHandler"
        />
      </draggable-panel>
    </div>
    <div v-if="taskPopupActive">
      <vs-popup
        class="form-popup teacher-tasks-popup-view"
        classContent="task__popup-content-edit-view"
        :title="`Task #${selectedTask.id}`"
        :active.sync="taskPopupActive"
        @close="closeHandler"
      >
        <task-view
          :id="selectedTask.id"
          :isEdit="false"
          @taskSaved="taskUpdateHandler"
        />
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import HomeLesson from './homeLesson.vue'
import Navigate from './navigate.vue'
import NoteEditForm from '@/components/teachers/notes/noteEditForm.vue'
import CommentsForm from '@/components/comments/commentsForm.vue'
import DraggablePanel from '@/components/common/elements/draggablePanel.vue'
import eventBus from '@/eventBus.js'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import print from 'vue-print-nb'
import scrollToTop from '@/components/common/scrollToTop.vue'
import TaskView from '@/components/tasks/taskView.vue'

export default {
  name: 'HomeWrapper',
  props: {
    msg: String,
  },
  data() {
    const now = this.$moment()
    return {
      now,
      lessonDay: this.$moment(),
      commentPopupActive: false,
      notesPopupActive: false,
      taskPopupActive: false,
      selectedTask: null,
      lessonId: null,
      lessonName: '',
      editedComment: {},
      editedNote: {},
      loading: false,
      proxy: process.env.VUE_APP_PROXY,
      selectedTeacherId: undefined,
      printView: false,
      printObj: {
        id: 'home__print',
        popTitle: `Print ${now.format('DD MM YYYY')}`,
        closeCallback(vue) {
          vue.printView = false
        },
        clickMounted() {
          // удаляем старые page-break
          const pageBreaks = document.querySelectorAll(
            '.home__print .print__page-break'
          )
          pageBreaks.forEach(el => {
            el.remove()
          })
          const maxHeight = 850 // 96 DPI
          const lessons = document.querySelectorAll(
            '.home__print .home__lesson'
          )
          // let pageHeight = 0
          // for (var i = 0; i < lessons.length; i++) {
          //   const curElement = lessons[i]
          //   const curHeight = curElement.offsetHeight
          //   if (pageHeight + curHeight >= maxHeight) {
          //     const pageBreak = document.createElement('div')
          //     pageBreak.classList.add('print__page-break')
          //     curElement.parentNode.insertBefore(pageBreak, curElement)
          //     pageHeight = curHeight
          //   } else {
          //     pageHeight += curHeight
          //   }
          // }
          for (var i = 1; i <= lessons.length; i++) {
            const curElement = lessons[i - 1]
            const nextElement = i != lessons.length ? lessons[i] : null
            if (nextElement) {
              const curElementHeight = curElement.offsetHeight - 70
              const nextElementHeight = nextElement.offsetHeight - 70
              console.log(curElementHeight, nextElementHeight)
              console.log(curElementHeight + nextElementHeight, maxHeight)
              if (
                maxHeight <= curElementHeight + nextElementHeight ||
                i % 2 == 0
              ) {
                const pageBreak = document.createElement('div')
                pageBreak.classList.add('print__page-break')
                curElement.parentNode.insertBefore(pageBreak, nextElement)
              }
            }
          }
        },
      },
      dragPositionX: 0,
      dragPositiony: 0,
    }
  },
  computed: {
    ...mapState({
      homeLessons: state => state.home.homeLessons,
      teachers: state => state.teachers.teachers,
    }),
    user: function() {
      return JSON.parse(window.localStorage.getItem('user'))
    },
    nearLesson: function() {
      const now = this.$moment().unix()
      // let nearLesson = null;
      // this.homeLessons.forEach((lesson) => {
      //   if (!nearLesson) nearLesson = lesson;
      //   const thisLessonDiff = Math.abs(lesson.lesson_time - now);
      //   const nearLessonDiff = Math.abs(nearLesson.lesson_time - now);
      //   console.log(thisLessonDiff, nearLessonDiff);
      //   if (thisLessonDiff <= nearLessonDiff) {
      //     nearLesson = lesson;
      //   }
      // });
      // return nearLesson;
      return this.homeLessons.reduce((nearLesson, lesson) => {
        if (lesson.lesson_time > now) {
          if (!nearLesson.id && !lesson.cancelled) return lesson
          if (nearLesson.lesson_time == lesson.lesson_time) return nearLesson
          const thisLessonDiff = Math.abs(lesson.lesson_time - now)
          const nearLessonDiff = Math.abs(nearLesson.lesson_time - now)
          if (thisLessonDiff <= nearLessonDiff && !lesson.cancelled) {
            return lesson
          }
        }
        return nearLesson
      }, {})
    },
  },
  directives: {
    print,
  },
  components: {
    Navigate,
    HomeLesson,
    NoteEditForm,
    CommentsForm,
    vSelect,
    DraggablePanel,
    scrollToTop,
    TaskView,
  },
  methods: {
    loadLessons: async function() {
      const date_from = this.lessonDay.startOf('day').unix()
      const date_to = this.lessonDay.endOf('day').unix()
      const data = {
        date_from,
        date_to,
      }
      if (this.selectedTeacherId) {
        data.teacher_id = this.selectedTeacherId
      }
      // await this.$store.dispatch("home/clearAction");
      await this.$store.dispatch('home/getHomeLessonsAction', data)
    },
    editCommentHandler: function(event, lesson, comment) {
      this.lessonId = lesson.id
      this.editedComment = comment
      this.commentPopupActive = true
      this.dragPositionX = event.x
      this.dragPositionY = event.y
      this.lessonName = lesson.name
    },
    editNoteHandler: function(event, lesson, note) {
      this.notesPopupActive = false
      this.lessonId = lesson.id
      if (note) this.editedNote = note
      else this.editedNote = {}
      this.editedNote.lesson = {
        id: lesson.id,
      }
      this.notesPopupActive = true
      this.dragPositionX = event.x
      this.dragPositionY = event.y
      this.lessonName = lesson.name
    },
    noteSavedHandler: function() {
      eventBus.$emit('updateLessonStatus')
      this.notesPopupActive = false
      this.editedNote = {}
      this.lessonId = null
    },
    commentSavedHandler: function() {
      eventBus.$emit('updateLessonStatus')
      this.commentPopupActive = false
      this.editedComment = {}
      this.lessonId = null
    },
    goToPrevDay: async function() {
      this.closeHandler()
      this.$vs.loading()
      this.lessonDay.add(-1, 'day')
      await this.loadLessons()
      this.$vs.loading.close()
    },
    goToNextDay: async function() {
      this.closeHandler()
      this.$vs.loading()
      this.lessonDay.add(1, 'day')
      await this.loadLessons()
      this.$vs.loading.close()
    },
    scrollToNextLesson: function() {
      if (this.nearLesson?.id) {
        document.querySelector(`#lesson-${this.nearLesson.id}`).scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        })
      }
    },
    changeTeacherHandler: async function() {
      this.$vs.loading()
      await this.loadLessons()
      this.$vs.loading.close()
    },
    bookGiveOutHandler: async function(out_id, lesson_id) {
      await this.$store.dispatch('books/giveOutBookToStudentAction', {
        out_id,
        lesson_id,
      })

      await this.loadLessons()
    },
    printHandler: function() {
      this.printView = true
    },
    closeHandler: function() {
      this.notesPopupActive = false
      this.commentPopupActive = false
      this.taskPopupActive = false
      this.selectedTask = null
    },
    viewTaskPopupHandler: function(task) {
      this.selectedTask = task
      this.taskPopupActive = true
    },
    taskUpdateHandler: async function() {
      this.selectedTask = null
      this.taskPopupActive = false
      await this.loadLessons()
    },
  },
  created: async function() {
    this.$vs.loading({
      scale: 0.6,
    })
    await this.$store.dispatch('teachers/getTeachersAction')
    this.teacher_id = this.user.id
    await this.loadLessons()
    this.$vs.loading.close()
    this.scrollToNextLesson()
    eventBus.$on('updateLessonStatus', this.loadLessons)
    eventBus.$on('editComment', this.editCommentHandler)
    eventBus.$on('editNote', this.editNoteHandler)
    eventBus.$on('bookGiveOut', this.bookGiveOutHandler)
  },
  beforeDestroy() {
    eventBus.$off('updateLessonStatus')
    eventBus.$off('editComment')
    eventBus.$off('editNote')
    eventBus.$off('bookGiveOut')
  },
}
</script>

<style lang="scss">
.home {
  // &__view {
  //   display: flex;
  //   flex-flow: row nowrap;
  // }
  // &__navigate {
  //   width: 3rem;
  // }
  // &__lessons__wrapper {
  //   flex: 1;
  // }
  &__current-date {
    font-size: 1.3rem;
    font-weight: bold;
    margin: 0.5rem 0 0.5rem 0;
    position: sticky;
    top: 55px;
  }
  &__lessons {
    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      & div {
        margin-right: 0.5rem;
      }
    }
    &__loader {
      height: 200px;
      width: 100%;
    }
  }
  &__print {
    // display: none;
    visibility: hidden;
  }
  &__teachers-filter {
    width: 15rem;
  }
  .scroll-top {
    position: fixed;
    right: 25px;
    bottom: 2rem;
    box-shadow: 0 0 10px 1px #ddd;
    border-radius: 7px;
  }
}
</style>
