<template>
  <div class="room-element">
    <vs-icon :icon="icon"></vs-icon>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
    },
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.room-element {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
</style>