<template>
  <div
    class="comment__item"
    :key="commentData.id"
    :class="{
      'student-comment': commentData.link.type == 'LessonsStudents',
    }"
  >
    <div
      class="comment__item-data"
      :class="{
        deleted: commentData.deleted,
      }"
    >
      <div
        class="comment__options"
        :class="{ comment__options__deleted: commentData.deleted }"
      >
        <div class="comment__autor" v-if="commentData.teacher">
          <vs-avatar
            :src="
              commentData.teacher.options && commentData.teacher.options.avatar
                ? `${proxy}/static/files/${commentData.teacher.options.avatar}`
                : undefined
            "
          ></vs-avatar>
          {{ commentData.teacher.nickname }}
        </div>
        <div class="comment__dates">
          <div class="comment__date">
            {{ $moment.unix(commentData.created).format('DD MMM YYYY HH:mm') }}
          </div>
          <div
            class="comment__date updated"
            v-if="commentData.updated"
            :title="
              `edited at ${$moment
                .unix(commentData.updated)
                .format('DD MMM YYYY HH:mm')}`
            "
          >
            Edited
          </div>
        </div>
        <div class="manage" v-if="isEditable">
          <vs-button
            type="flat"
            color="dark"
            icon="edit"
            @click="isEdit = true"
            class="small"
            >Edit</vs-button
          >
          <vs-button
            type="flat"
            color="danger"
            icon="delete"
            class="small"
            @click="deleteCommentHandle"
            >Delete</vs-button
          >
        </div>
      </div>

      <div class="comment__text" v-if="!isEdit">
        <div class="comment__name">
          <router-link :to="linkData">{{ commentData.link.name }}</router-link>
          <lesson-online v-if="commentData.link.is_online" />
        </div>
        <div
          style="white-space: pre-line"
          v-html="commentData.comment"
          class="comment__text__block"
          v-if="!commentData.deleted"
        ></div>
        <div v-else>
          <vs-icon icon="highlight_off" color="danger"></vs-icon>
          deleted at:
          {{ $moment.unix(commentData.deleted).format('DD MMMM YYYY HH:mm') }}
        </div>
        <div class="comment__files" v-if="files.length && !commentData.deleted">
          <files-list :files="files" />
        </div>
      </div>
      <div class="comment__form" v-else-if="isEdit">
        <CommentForm
          :commentItem="commentData"
          @closeCommentForm="closeCommentFormHandle"
        />
      </div>
    </div>

    <div class="comment__childs" v-if="commentData.childs">
      <div v-for="child in commentData.childs" :key="child.id">
        <CommentItem :comment="child" name="child-comment" />
      </div>
    </div>
  </div>
</template>

<script>
import CommentItem from '@/components/comments/commentItem'
import CommentForm from '@/components/comments/commentsForm'
import FilesList from '@/components/files/filesList'
import LessonOnline from '@/components/common/elements/lessonOnline.vue'

export default {
  name: 'CommentItem',
  props: ['comment'],
  data() {
    return {
      proxy: process.env.VUE_APP_PROXY,
      isEdit: false,
      commentData: this.comment,
    }
  },
  components: {
    CommentItem,
    CommentForm,
    FilesList,
    LessonOnline,
    // Viewer,
  },
  computed: {
    isEditable: function() {
      const now = new Date()
      const created = new Date(this.commentData.created * 1000)
      const timedelta = (now - created) / 1000

      if (this.commentData.link.type == 'LessonsStudents') return false
      if (this.commentData.deleted) return false
      if (this.commentData.id.toString().startsWith('sub_')) return false
      if (this.$can('manage', 'comment')) return true
      if (this.commentData.teacher.id != this.$getUser().id) return false
      if (timedelta > 60 * 60 * 24 * 2) return false
      return true
    },
    linkData: function() {
      const linkType = this.comment.link.type
      const linkData = {
        name: linkType,
        params: {},
      }
      let id_field_name = ''

      switch (this.comment.link.type) {
        case 'Groups':
          id_field_name = 'group_id'
          linkData.name = 'Group'
          break
        case 'Lessons':
          id_field_name = 'lesson_id'
          break
        case 'Students':
          id_field_name = 'student_id'
          linkData.name = 'Student'
          break
        case 'LessonsStudents':
          id_field_name = 'student_id'
          linkData.name = 'Student'
          break
        case 'Books':
          id_field_name = 'book_id'
          linkData.name = 'Book'
          break
        case 'Result':
          id_field_name = 'result_id'
          linkData.name = 'Result'
          break
        case 'Task':
          id_field_name = 'task_id'
          linkData.name = 'Task'
          break
      }
      linkData.params[id_field_name] = this.comment.link.id
      return linkData
    },
    files: function() {
      return this.comment.files || []
    },
  },
  methods: {
    closeCommentFormHandle: function(comment) {
      if (comment && comment.length) this.commentData = comment[0]
      this.isEdit = false
    },
    deleteCommentHandle: function() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirm delete comment`,
        text: 'Are you sure to delete comment?',
        accept: function() {
          this.$store
            .dispatch('comments/deleteCommentAction', this.commentData.id)
            .then(data => {
              this.commentData = data
            })
        }.bind(this),
      })
    },
  },
}
</script>

<style lang="scss">
.vs-button {
  &.small {
    i {
      font-size: 14px;
    }
  }
}
.deleted {
  color: #999;
  .comment__text {
    box-shadow: none !important;
    background: #fff !important;
    border: 1px solid #ccc !important;
  }
}
.comment__options__deleted {
  text-decoration: line-through;
}
.comment__files {
  margin-top: 1rem;
}
</style>
