import Vue from 'vue'

export default function sendIncomeResult({ result_id, sended }) {
    const proxy = process.env.VUE_APP_PROXY
    const api_uri = process.env.VUE_APP_API_URI

    let url = proxy + api_uri + "/tests_income/results/"+result_id+"/send"

    return Vue.http
        .put(url, {sended}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
}