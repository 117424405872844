<template>
  <div class="task-dates" :class="{ tiny: size == 'tiny' }">
    <vs-chip v-if="task.date_start && size != 'tiny'">
      Start:
      {{ $moment(task.date_start * 1000).format('YYYY-MM-DD') }}
    </vs-chip>
    <vs-chip
      class="task__finish__date"
      v-if="task.date_finish && size != 'tiny'"
    >
      Finish:
      {{ $moment(task.date_finish * 1000).format('YYYY-MM-DD') }}
      <vs-icon
        v-if="task.status != 2"
        icon="arrow_right_alt"
        class="inline-datepicker"
      ></vs-icon>
      <span
        v-if="task.status != 2"
        class="task__days-to-finish"
        :class="{ danger: daysToFinish <= 1, warning: daysToFinish <= 2 }"
        >{{ daysToFinish }} days</span
      >
    </vs-chip>
    <div
      v-if="task.date_finish && size == 'tiny'"
      class="task-dates__due__tiny"
      :class="{ danger: daysToFinish <= 1, warning: daysToFinish <= 2 }"
    >
      {{ daysToFinish }}d
    </div>
  </div>
</template>

<script>
export default {
  props: ['task', 'size'],
  data() {
    return {
      now: new Date(),
    }
  },
  computed: {
    daysToFinish: function() {
      if (this.task.date_finish) {
        const date_finish = new Date(this.task.date_finish * 1000)
        return Math.ceil(
          (date_finish - this.now.getTime()) / (1000 * 60 * 60 * 24)
        )
      }
      return 0
    },
  },
}
</script>

<style lang="scss">
.task-dates {
  display: flex;
  &:not(.tiny) {
    margin-top: 0.7rem;
  }
}
.task__finish__date {
  font-weight: bold;
}
.task__days-to-finish,
.task-dates__due__tiny {
  display: inline-block;
  font-size: 0.9rem;
  vertical-align: top;
  &.warning {
    color: rgb(var(--vs-warning));
  }
  &.danger {
    color: rgb(var(--vs-danger));
  }
}
.task-dates__due__tiny {
  font-size: 0.75rem;
  font-weight: bold;
  padding: 3px;
  background-color: #fefefe;
  border-radius: 3px;
}
</style>
