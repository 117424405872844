import getGroups from "@/api/groups/getGroups";
import getSingleGroup from "@/api/groups/getSingleGroup";
import setGroups from "@/api/groups/setGroups";
import deleteGroups from "@/api/groups/deleteGroups";
import getGroupTypes from "@/api/groupTypes/getGroupTypes";
import setGroupTypes from "@/api/groupTypes/setGroupTypes";
import deleteGroupTypes from "@/api/groupTypes/deleteGroupTypes";
import updateLessons from "@/api/groups/updateLessons";
import getGroupBookOuts from "@/api/groups/getGroupBookOuts";


const state = { groups: [], singleGroup: {}, groupTypes: [], groupBookOuts: {} };

const getters = {
    group: (state) => {
        return state.singleGroup
    }
}

const actions = {
    // -- GROUPS
    getGroupsAction({ commit }, { data }) {
        return new Promise((resolve, reject) => {
            getGroups.call(this, data).then(response => {
                if (response.status == 'OK') {
                    commit('setGroupsMutation', { groups: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    getSingleGroupAction({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            getSingleGroup.call(this, id).then(response => {
                if (response.status == 'OK') {
                    commit('setSingleGroupMutation', { group: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    saveGroupsAction({ commit }, { groups }) {
        return new Promise((resolve, reject) => {
            setGroups.call(this, { groups: [groups] }).then(response => {
                if (response.status == 'OK') {
                    commit('updateGroupsMutation', { groups: response.data })
                    commit('setSingleGroupMutation', { group: response.data[0] })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    deleteGroupsAction({ commit }, id) {
        return new Promise((resolve, reject) => {
            deleteGroups.call(this, id).then(response => {
                if (response.status == 'OK') {
                    commit('deleteGroupsMutation', { group: response.data })
                    resolve()
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    updateLessonsAction({ commit }, group_id) {
        return new Promise((resolve, reject) => {
            updateLessons.call(this, group_id).then(response => {
                if (response.status == 'OK') {
                    commit('updateGroupsMutation', { groups: [response.data] })
                    commit('setSingleGroupMutation', { group: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    // -- GROUP TYPES
    getGroupTypesAction({ commit }) {
        return new Promise((resolve, reject) => {
            getGroupTypes.call(this).then(response => {
                if (response.status == 'OK') {
                    commit('setGroupTypesMutation', { types: response.data })
                    resolve()
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    setGroupTypesAction({ commit }, { groupType }) {
        return new Promise((resolve, reject) => {
            setGroupTypes.call(this, { groupTypes: groupType }).then(response => {
                if (response.status == 'OK') {
                    commit('updateGroupTypesMutation', { types: response.data })
                    resolve()
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    deleteGroupTypeAction({ commit }, { groupType }) {
        return new Promise((resolve, reject) => {
            deleteGroupTypes.call(this, groupType.id).then(response => {
                if (response.status == 'OK') {
                    commit('deleteGroupTypeNutation', { type: response.data })
                    resolve()
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    getGroupBookOutsAction({ commit }, { group_id }) {
        return new Promise((resolve, reject) => {
            getGroupBookOuts.call(this, { group_id }).then(response => {
                if (response.status == 'OK') {
                    commit('setGroupBookOutsMutation', { outs: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    clearAction({ commit }) {
        commit('clearMutation')
    }
}

const mutations = {
    // -- GROUPS
    setGroupsMutation(state, { groups }) {
        state.groups = groups
    },
    setSingleGroupMutation(state, { group }) {
        state.singleGroup = group
    },
    updateGroupsMutation(state, { groups }) {
        groups.forEach(group => {
            const index = state.groups.findIndex(r => r.id == group.id)
            if (index !== -1 && typeof index !== 'undefined') {
                state.groups.splice(index, 1, group)
            } else {
                state.groups = [...state.groups, group]
            }
        })
    },
    deleteGroupsMutation(state, { group }) {
        const index = state.groups.findIndex(r => r.id == group.id)
        if (typeof index !== 'undefined') {
            state.groups.splice(index, 1)
        }
    },
    // -- GROUP TYPES
    setGroupTypesMutation(state, { types }) {
        state.groupTypes = types
    },
    updateGroupTypesMutation(state, { types }) {
        types.forEach(type => {
            const index = state.groupTypes.findIndex(r => r.id == type.id)
            if (index !== -1 && typeof index !== 'undefined') {
                state.groupTypes.splice(index, 1, type)
            } else {
                state.groupTypes = [...state.groupTypes, type]
            }
        })
        state.groupTypes = state.groupTypes.sort((a,b) => a.weight - b.weight)
    },
    deleteGroupTypeNutation(state, { type }) {
        const index = state.groupTypes.findIndex(r => r.id == type.id)
        if (typeof index !== 'undefined') {
            state.groupTypes.splice(index, 1)
        }
    },
    setGroupBookOutsMutation(state, { outs }) {
        state.groupBookOuts = outs
    },
    clearMutation(state) {
        state.singleGroup = {}
        state.groupBookOuts = {}
    }
}


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}