<template>
  <div
    class="task-teachers__teacher__item"
    :class="{ worked: teacher.worked_at }"
    :title="
      teacher.worked_at
        ? `in work from ${$moment(teacher.worked_at * 1000).format(
            'DD.MM.YYYY HH.mm',
          )}`
        : ''
    "
  >
    <vs-chip transparent v-if="teacher.worked_at">
      <vs-avatar
        :src="
          teacher.options && teacher.options.avatar
            ? `${proxy}/static/files/${teacher.options.avatar}`
            : undefined
        "
      ></vs-avatar>
      <span v-if="size != 'small'">{{ teacher.name }}</span>
      <span
        v-if="!isClosedTask && size != 'middle' && teacher.worked_at"
        class="task-teachers__teacher__in-work"
      >
        In work
      </span>
    </vs-chip>
    <div v-else>
      <vs-avatar
        size="small"
        :src="
          teacher.options && teacher.options.avatar
            ? `${proxy}/static/files/${teacher.options.avatar}`
            : undefined
        "
      ></vs-avatar>
      <span v-if="size != 'small'">{{ teacher.name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    teacher: {
      type: Object,
    },
    size: {
      type: String,
      default: "large",
    },
    isClosedTask: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      proxy: process.env.VUE_APP_PROXY,
    };
  },
};
</script>

<style>
</style>