<template>
  <vs-button
    icon="arrow_upward"
    type="flat"
    color="primary"
    title="Move to top"
    @click="scrollTop"
    v-show="visible"
  ></vs-button>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    scrollTop: function() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    scrollListener: function() {
      this.visible = window.scrollY > 150
    },
  },
  mounted: function() {
    window.addEventListener('scroll', this.scrollListener)
  },
  beforeDestroy: function() {
    window.removeEventListener('scroll', this.scrollListener)
  },
}
</script>

<style lang="scss">
.scroll-top {
  transition: all 0.3s;
  background-color: #fff;
}
</style>
