<template>
  <div class="group-element">
    <div v-if="type == 'group'">
      <vs-avatar icon="people" :color="color"></vs-avatar>
      <router-link
        :to="{ name: 'Group', params: { group_id: id } }"
        class="group-link"
        target="_blank"
      >
        <span :class="{ crossed: !is_active }"><slot></slot></span>
      </router-link>
    </div>
    <div v-else-if="type == 'tutor'">
      <vs-avatar icon="explore"></vs-avatar>
      Tutoring
    </div>
    <div v-else-if="type == 'testing'">
      <vs-avatar icon="colorize"></vs-avatar>
      Testing
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
    },
    color: {
      type: String,
      required: true,
    },
    is_active: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'group',
    },
  },
}
</script>

<style></style>
