import getSources from "@/api/payments/getSources";
import saveSource from "@/api/payments/saveSource";
import saveTransfer from "@/api/payments/saveTransfer";
import deleteSource from "@/api/payments/deleteSource";
import getPayments from "@/api/payments/getPayments";
import savePayment from "@/api/payments/savePayment";
import deletePayment from "@/api/payments/deletePayment";

const state = { sources: [], orderPayments: [] };

const actions = {
    getSourcesAction({ commit }) {
        return new Promise((resolve, reject) => {
            getSources.call(this).then(response => {
                if (response.status == 'OK') {
                    commit('setSourceMutation', { sources: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    saveSourceAction({ commit }, { source }) {
        return new Promise((resolve, reject) => {
            saveSource.call(this, { source }).then(response => {
                if (response.status == 'OK') {
                    commit('updateSourcesMutation', { sources: response.data })
                    resolve()
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    deleteSourceAction({ commit }, { source }) {
        return new Promise((resolve, reject) => {
            deleteSource.call(this, { source }).then(response => {
                if (response.status == 'OK') {
                    commit('deleteSourcesMutation', { source: response.data })
                    resolve()
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    getOrderPaymentsAction({ commit }, query) {
        return new Promise((resolve, reject) => {
            getPayments.call(this, query).then(response => {
                if (response.status == 'OK') {
                    commit('setOrderPaymantsMutation', { payments: response.data })
                    resolve()
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    setOrderPaymentAction({ commit }, { payment }) {
        const payments = [payment]
        return new Promise((resolve, reject) => {
            savePayment.call(this, payments).then(response => {
                if (response.status == 'OK') {
                    commit('updateOrderPaymentMutation', { payments: response.data.saved })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    setOrderPaymentMultipleAction({ commit }, payments) {
        return new Promise((resolve, reject) => {
            savePayment.call(this, payments).then(response => {
                if (response.status == 'OK') {
                    commit('updateOrderPaymentMutation', { payments: response.data.saved })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    deletePaymentAction({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            deletePayment.call(this, { id }).then(response => {
                if (response.status == 204) {
                    commit('deletePaymentMutation', { id })
                    resolve()
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    setTransferAction({ commit }, { data }) {
        return new Promise((resolve, reject) => {
            saveTransfer.call(this, { data }).then(response => {
                if (response.status == 'OK') {
                    commit('updateTransferMutation')
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
}

const mutations = {
    setSourceMutation(state, { sources }) {
        state.sources = sources
    },
    updateSourcesMutation(state, { sources }) {
        sources.forEach(source => {
            const index = state.sources.findIndex(r => r.id == source.id)
            if (index !== -1 && typeof index !== 'undefined') {
                state.sources.splice(index, 1, source)
            } else {
                state.sources = [...state.sources, source]
            }
        })
    },
    updateOrderPaymentMutation(state, { payments }) {
        payments.forEach(payment => {
            const index = state.orderPayments.findIndex(r => r.id == payment.id)
            if (index !== -1 && typeof index !== 'undefined') {
                state.orderPayments.splice(index, 1, payment)
            } else {
                state.orderPayments = [payment, ...state.orderPayments]
            }
        })
    },
    deleteSourcesMutation(state, { source }) {
        const index = state.sources.findIndex(r => r.id == source.id)
        if (typeof index !== 'undefined') {
            state.sources.splice(index, 1)
        }
    },
    setOrderPaymantsMutation(state, { payments }) {
        state.orderPayments = payments
    },
    deletePaymentMutation(state, { id }) {
        const index = state.orderPayments.findIndex(p => p.id == id)
        if (typeof index !== 'undefined') state.orderPayments.splice(index, 1)
    },
    updateTransferMutation() { }
}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}