export default {
    install(Vue) {
        Vue.prototype.$getUser = function () {
            const user = localStorage.getItem('user')
            if (user) return JSON.parse(user)

        }
        Vue.prototype.$setRoles = function () {

            const user = localStorage.getItem('user')
            if (user) return JSON.parse(user)

        }
    }
}