var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"task-teachers__teacher__item",class:{ worked: _vm.teacher.worked_at },attrs:{"title":_vm.teacher.worked_at
      ? ("in work from " + (_vm.$moment(_vm.teacher.worked_at * 1000).format(
          'DD.MM.YYYY HH.mm'
        )))
      : ''}},[(_vm.teacher.worked_at)?_c('vs-chip',{attrs:{"transparent":""}},[_c('vs-avatar',{attrs:{"src":_vm.teacher.options && _vm.teacher.options.avatar
          ? (_vm.proxy + "/static/files/" + (_vm.teacher.options.avatar))
          : undefined}}),(_vm.size != 'small')?_c('span',[_vm._v(_vm._s(_vm.teacher.name))]):_vm._e(),(!_vm.isClosedTask && _vm.size != 'middle' && _vm.teacher.worked_at)?_c('span',{staticClass:"task-teachers__teacher__in-work"},[_vm._v(" In work ")]):_vm._e()],1):_c('div',[_c('vs-avatar',{attrs:{"size":"small","src":_vm.teacher.options && _vm.teacher.options.avatar
          ? (_vm.proxy + "/static/files/" + (_vm.teacher.options.avatar))
          : undefined}}),(_vm.size != 'small')?_c('span',[_vm._v(_vm._s(_vm.teacher.name))]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }