import { AbilityBuilder, Ability } from '@casl/ability'

const { can, cannot, rules } = new AbilityBuilder(Ability);

let user = localStorage.getItem('user')
if (user) {
    user = JSON.parse(user)
    if (user.options.roles) {
        const roles = user.options.roles
        if (roles.includes('admin')) {
            can('manage', "all")
        }
        if (roles.includes('operator')) {
            // can('manage', "teacher")
            // can('manage', "settings")
            // can('manage', "student")
            // can('manage', "student_missings")
            // can('manage', "group")
            // can('manage', "price")
            // can('manage', "reports")
            // can('manage', "orders")
            // can('manage', "payments")
            // can('manage', "results")
            // can('manage', "books")
            // can('manage', "tests")
            // can('manage', "surprise")
            // can('manage', "remove-surprise")
            // can('manage', "books_outs")
            // can('manage', 'view_home_by_another_teacher')
            can('manage', "all_tasks_view")
            can('manage', "add_task")
            can('manage', "task_reopen")
            can('manage', "task_edit")
            can('manage', "current_books")
        }
    }

}
can('read', 'all');
cannot('read', 'teacher')

export default new Ability(rules);