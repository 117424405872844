import Vue from 'vue'

export default function saveOrdersGroupsStudent({ order_id, data }) {
    const proxy = process.env.VUE_APP_PROXY
    const api_uri = process.env.VUE_APP_API_URI
    const {value, lessons_plan, comment} = data
    let url = proxy + api_uri + "/orders/orders_groups/" + order_id
    return Vue.http
        .put(url, {value, lessons_plan, comment}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response).catch(response => response.json())
}