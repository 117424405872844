import getLessons from "@/api/lessons/getLessons";
import getLessonsByGroups from "@/api/lessons/getLessonsByGroups";
import getSingleLesson from "@/api/lessons/getSingleLesson";
import setLessons from "@/api/lessons/setLessons";
import getPrevLesson from "@/api/lessons/getPrevLesson";
import cancelLesson from "@/api/lessons/cancelLesson";
import startLesson from "@/api/lessons/startLesson";
import doneLesson from "@/api/lessons/doneLesson";
import deleteLesson from "@/api/lessons/deleteLesson";
import assistLesson from "@/api/lessons/assistLesson";
import visitLesson from "@/api/lessons/visitLesson";
import reopenLesson from "@/api/lessons/reopenLesson";
import getTutorLessons from "@/api/lessons/getTutorLessons";

const state = { lessons: [], lesson: {}, prevLesson: {}, lessonsGroups: {}, tutorLessonsByStudent: [] };

const getters = {
    singleLesson: (state) => {
        return state.lesson
    }
}

const actions = {
    getLessonsAction({ commit }, data) {
        return new Promise((resolve, reject) => {
            getLessons.call(this, data).then(response => {
                if (response.status == 'OK') {
                    commit('setLessonsMutation', { lessons: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    getLessonsByGroupsAction({ commit }, data) {
        return new Promise((resolve, reject) => {
            getLessonsByGroups.call(this, data).then(response => {
                if (response.status == 'OK') {
                    commit('setLessonsMutation', { lessons: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    setLessonsAction({ commit }, { data }) {
        return new Promise((resolve, reject) => {
            setLessons.call(this, [data]).then(response => {
                if (response.status == 'OK') {
                    commit('updateLessonsMutation', { lessons: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    getSingleLessonAction({ commit }, data) {
        return new Promise((resolve, reject) => {
            getSingleLesson.call(this, data.id).then(response => {
                if (response.status == 'OK') {
                    commit('setSingleLessonsMutation', { lesson: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    getPrevLessonAction({ commit }, data) {
        if (!data.id) {
            commit('setPrevLessonsMutation', { lesson: {} })
            return
        }
        return new Promise((resolve, reject) => {
            getPrevLesson.call(this, data.id).then(response => {
                if (response.status == 'OK') {
                    commit('setPrevLessonsMutation', { lesson: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    cancelLessonAction({ commit }, { id, cancel_description }) {
        return new Promise((resolve, reject) => {
            cancelLesson.call(this, { id, cancel_description }).then(response => {
                if (response.status == 'OK') {
                    commit('setSingleLessonsMutation', { lesson: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    startLessonAction({ commit }, id) {
        return new Promise((resolve, reject) => {
            startLesson.call(this, id).then(response => {
                if (response.status == 'OK') {
                    commit('setSingleLessonsMutation', { lesson: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    doneLessonAction({ commit }, id) {
        return new Promise((resolve, reject) => {
            doneLesson.call(this, id).then(response => {
                if (response.status == 'OK') {
                    commit('setSingleLessonsMutation', { lesson: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    assistLessonAction({ commit }, id) {
        return new Promise((resolve, reject) => {
            assistLesson.call(this, id).then(response => {
                if (response.status == 'OK') {
                    commit('setSingleLessonsMutation', { lesson: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    visitLessonAction({ commit }, { data }) {
        return new Promise((resolve, reject) => {
            visitLesson.call(this, { data }).then(response => {
                if (response.status == 'OK') {
                    commit('setSingleLessonsMutation', { lesson: response.data })
                    commit('updateLessonsMutation', { lessons: [response.data] })
                    commit('updateTutorLessonsMutation', { lessons: [response.data] })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    clearPrevLessonAction({ commit }) {
        commit('clearPrevLessonMutation')
    },
    reopenLessonAction({ commit }, {id, teacherId}) {
        return new Promise((resolve, reject) => {
            reopenLesson.call(this, {id, teacherId}).then(response => {
                if (response.status == 'OK') {
                    commit('setSingleLessonsMutation', { lesson: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    saveTutorLessonAction({ commit }, { data }) {
        return new Promise((resolve, reject) => {
            setLessons.call(this, [data]).then(response => {
                if (response.status == 'OK') {
                    // commit('setLessons', { lesson: response.data })
                    commit('setTutorLessonMutation', { lessons: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    // tutor
    getTutorLessonsByStudentAction({ commit }, { student_id, schoolyear_id }) {
        return new Promise((resolve, reject) => {
            getTutorLessons.call(this, { student_id, schoolyear_id }).then(response => {
                if (response.status == 'OK') {
                    commit('setTutorLessonsMutation', { lessons: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    clearGroupLessonsAction({ commit }) {
        commit('clearGroupLessonsMutation')
    },
    deteleLessonAction({ commit }, id) {
        return new Promise((resolve, reject) => {
            deleteLesson.call(this, id).then(response => {
                if (response.status == 'OK') {
                    commit('deleteLessonMutation')
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
}

const mutations = {
    setLessonsMutation(state, { lessons }) {
        state.lessons = lessons;
        // state.lessonsGroups = lessons.groups;
    },
    updateLessonsMutation(state, { lessons }) {
        lessons.forEach(lesson => {
            const index = state.lessons.findIndex(r => r.id == lesson.id)
            if (index !== -1 && typeof index !== 'undefined') {
                state.lessons.splice(index, 1, lesson)
            } else {
                state.lessons = [...state.lessons, lesson]
            }
            if (lesson.id == state.lesson.id) state.lesson = lesson
        })
    },
    setSingleLessonsMutation(state, { lesson }) {
        state.lesson = lesson
    },
    setPrevLessonsMutation(state, { lesson }) {
        state.prevLesson = lesson
    },
    clearPrevLessonMutation(state) {
        state.prevLesson = {}
    },
    setTutorLessonMutation(state, { lessons }) {
        // преобразовываем для расписания
        lessons.forEach(lesson => {
            const index = state.lessons.findIndex(r => r.id == lesson.id)
            const {
                id,
                students,
                date_end,
                evening,
                cancelled,
                duration,
                name,
                lesson_time,
                room,
                teacher,
            } = lesson

            let formatedLesson = {
                id: id,
                title: name,
                start: new Date(lesson_time * 1000),
                end: new Date(lesson_time * 1000 + duration * 60 * 1000),
                backgroundColor: null,
                roomName: room.name,
                extendedProps: {
                    teacher: teacher.nickname,
                    room: room.room_icon,
                    students: students,
                    done: !!date_end,
                    evening: evening,
                    cancelled: cancelled,
                    duration: duration,
                    lastchar: "",
                },
            }
            if (index !== -1 && typeof index !== 'undefined') {
                state.lessons.splice(index, 1, formatedLesson)
            } else {
                state.lessons = [...state.lessons, formatedLesson]
            }
            if (id == state.lesson.id) state.lesson = formatedLesson
        })
    },
    setTutorLessonsMutation(state, { lessons }) {
        state.tutorLessonsByStudent = lessons
    },
    updateTutorLessonsMutation(state, { lessons }) {
        lessons.forEach(lesson => {
            if (lesson.lesson_type == 'tutor') {
                const index = state.tutorLessonsByStudent.findIndex(r => r.id == lesson.id)
                if (index !== -1 && typeof index !== 'undefined') {
                    state.tutorLessonsByStudent.splice(index, 1, lesson)
                } else {
                    state.tutorLessonsByStudent = [...state.tutorLessonsByStudent, lesson]
                }
                if (lesson.id == state.lesson.id) state.lesson = lesson
            }

        })
    },
    clearGroupLessonsMutation(state) {
        state.lessons = []
    },
    deleteLessonMutation(){
        window.location.href = '/timetable'
    }
}


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}