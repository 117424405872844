<template>
  <div class="task-status status-block">
    <span class="status" :class="status.class">
      {{ status.name }}
    </span>
  </div>
</template>

<script>
export default {
  props: ["status"],
};
</script>

<style lang="scss">
.task-status {
  & > span {
    &.task-open {
      background-color: #ddd;
    }
    &.task-close {
      background-color: var(--font-discount-15);
      color: #eee;
    }
    &.task-review {
      background-color: rgba(var(--vs-primary), 1);
      color: #eee;
    }
  }
}
</style>