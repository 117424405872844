import Vue from 'vue'

export default function getFiles(filters) {
    const proxy = process.env.VUE_APP_PROXY
    const api_uri = process.env.VUE_APP_API_URI

    let url = proxy + api_uri + "/files"
    if (filters) {
        let q = new URLSearchParams(filters).toString()
        url += `?${q}`
    }
    return Vue.http
        .get(url, {}, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => response.json()).catch((e) => { throw e })
}