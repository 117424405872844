<template>
  <div class="lesson-manage">
    <div class="lesson-manage-buttons" v-if="canGetInWork">
      <vs-button
        icon="person"
        class="status-bar__start-button"
        type="border"
        color="success"
        @click="startLessonHandle"
        :size="size"
        >Get in work</vs-button
      >
    </div>

    <div
      class="lesson-manage-buttons"
      v-if="
        selfLessonTeacher &&
        selfLessonTeacher.type == 'assistant' &&
        !selfLessonTeacher.visited_at
      "
    >
      <vs-button
        icon="person"
        class="status-bar__start-button"
        type="border"
        color="primary"
        @click="assistLessonHandle"
        :size="size"
        >Assist</vs-button
      >
    </div>
    <div class="lesson-manage-buttons">
      <vs-button
        icon="done"
        class="status-bar__done-button"
        type="border"
        color="success"
        @click="doneLessonHandle"
        v-if="canDone"
        :size="size"
        >Done</vs-button
      >
    </div>
    <div
      class="lesson-manage-buttons"
      v-if="
        lesson.main_teacher &&
        lesson.main_teacher.date_end &&
        $can('manage', 'lesson')
      "
    >
      <vs-button
        icon="settings_backup_restore"
        class="status-bar__reopen-button"
        type="border"
        @click="reopenLessonHandle"
        :size="size"
        >ReOpen</vs-button
      >
    </div>
    <div class="lesson-manage-buttons">
      <vs-button
        icon="close"
        class="status-bar__cancel-button"
        type="flat"
        color="danger"
        @click="cancelLessonHandle"
        v-if="$can('manage', 'cancel-lesson') && !lesson.cancelled"
        :size="size"
        >Cancel</vs-button
      >
    </div>
    <vs-prompt
      color="danger"
      @accept="acceptStartLesson"
      :active.sync="startUndefinedTeacherPromptActive"
      :is-valid="!!reasonToGetInWork"
    >
      <div>
        <p>
          You are not defined in this lesson. Do you really want to Get in work?
        </p>
        <br />
        <p>Please, write a comment with reason:</p>
        <vs-textarea
          v-model="reasonToGetInWork"
          counter="200"
          class="reason-comment"
          autofocus
        />
      </div>
    </vs-prompt>
    <vs-prompt
      color="danger"
      @accept="acceptCancel"
      :active.sync="cancelPromptActive"
      :is-valid="!!cancelDescription"
    >
      <div>
        <p>Do you really want to cancel the lesson?</p>
        <br />
        <p>Please, write a comment with reason:</p>
        <vs-textarea
          v-model="cancelDescription"
          counter="250"
          class="reason-comment"
          autofocus
        />
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import setComments from "@/api/comments/setComments";
import eventBus from "@/eventBus.js";

export default {
  props: {
    lesson: {
      type: Object,
    },
    selfLessonTeacher: {
      type: Object,
    },
    size: {
      type: String,
      default: "default",
    },
  },
  data() {
    return {
      startUndefinedTeacherPromptActive: false,
      reasonToGetInWork: "",
      cancelPromptActive: false,
      cancelDescription: "",
    };
  },
  computed: {
    user: function () {
      return JSON.parse(window.localStorage.getItem("user"));
    },
    canGetInWork: function () {
      if (this.lesson.cancelled) return false;
      // -------
      if (
        this.selfLessonTeacher.id &&
        this.selfLessonTeacher.type == "assistant"
      )
        return false;
      if (this.selfLessonTeacher.id && !this.selfLessonTeacher.date_start)
        return true;

      if (this.lesson.main_teacher && this.lesson.main_teacher.date_start)
        return false;
      if (this.selfLessonTeacher.id && this.selfLessonTeacher.date_start)
        return false;
      return true;
    },
    canDone: function () {
      if (this.lesson.cancelled) return false;
      if (this.lesson.date_end) return false;
      if (
        this.user.options.roles.includes("admin") &&
        this.lesson.date_start &&
        !this.lesson.date_end
      )
        return true;
      if (
        this.selfLessonTeacher.id &&
        this.selfLessonTeacher.type == "assistant"
      )
        return false;
      if (!this.selfLessonTeacher.id) return false;
      if (this.selfLessonTeacher.id && this.selfLessonTeacher.date_end)
        return true;
      if (this.selfLessonTeacher.id && !this.selfLessonTeacher.date_start)
        return false;
      return true;
    },
  },
  methods: {
    startLessonHandle: function () {
      if (!this.selfLessonTeacher.id) {
        this.startUndefinedTeacherPromptActive = true;
        return;
      }
      this.startLesson();
    },
    startLesson: function () {
      this.$store
        .dispatch("lessons/startLessonAction", this.lesson.id)
        .then(() => {
          eventBus.$emit("updateLessonStatus");
          this.$vs.notify({
            title: "Started!",
            text: "",
            color: "success",
            position: "top-right",
          });
        })
        .catch((e) => {
          this.$vs.notify({
            title: "Error",
            text: `${e}`,
            color: "danger",
            position: "top-right",
          });
        });
    },
    acceptStartLesson: function () {
      let comment = `Lesson was started by ${this.user.name} instead of ${this.lesson.main_teacher.nickname}<br/><b>Reason</b>: ${this.reasonToGetInWork}`;
      const data = {
        comment,
        object_type: "Lessons",
        object_id: this.lesson.id,
        teacher_id: this.user.id,
      };
      setComments
        .call(this, { data: [data] })
        .then((response) => {
          if (response.status == "OK") {
            this.startLesson();
            eventBus.$emit("toggleComments");
          }
        })
        .catch((e) => {
          this.$vs.notify({
            title: "Error",
            text: `${e}`,
            color: "danger",
            position: "top-right",
          });
        });
    },
    cancelLessonHandle: function () {
      this.cancelPromptActive = true;
    },
    acceptCancel: function () {
      this.$store
        .dispatch("lessons/cancelLessonAction", {
          id: this.lesson.id,
          cancel_description: this.cancelDescription,
        })
        .then(() => {
          eventBus.$emit("updateLessonStatus");
          this.$vs.notify({
            title: "Cancelled!",
            text: "",
            color: "success",
            position: "top-right",
          });
        })
        .catch((e) => {
          this.$vs.notify({
            title: "Error",
            text: `${e}`,
            color: "danger",
            position: "top-right",
          });
        });
    },
    assistLessonHandle: function () {
      this.$store
        .dispatch("lessons/assistLessonAction", this.lesson.id)
        .then(() => {
          eventBus.$emit("updateLessonStatus");
          this.$vs.notify({
            title: "Started!",
            text: "",
            color: "success",
            position: "top-right",
          });
        })
        .catch((e) => {
          this.$vs.notify({
            title: "Error",
            text: `${e}`,
            color: "danger",
            position: "top-right",
          });
        });
    },
    reopenLessonHandle: function () {
      const { id } = this.lesson;
      this.$store
        .dispatch("lessons/reopenLessonAction", { id })
        .then(() => {
          eventBus.$emit("updateLessonStatus");
          this.$vs.notify({
            title: "Reopened!",
            text: "",
            color: "success",
            position: "top-right",
          });
        })
        .catch((e) => {
          this.$vs.notify({
            title: "Error",
            text: `${e}`,
            color: "danger",
            position: "top-right",
          });
        });
    },
    doneLessonHandle: function () {
      this.$store
        .dispatch("lessons/doneLessonAction", this.lesson.id)
        .then(() => {
          eventBus.$emit("updateLessonStatus");
          this.$vs.notify({
            title: "Done!",
            text: "",
            color: "success",
            position: "top-right",
          });
        })
        .catch((e) => {
          this.$vs.notify({
            title: "Error",
            text: `${e}`,
            color: "danger",
            position: "top-right",
          });
        });
    },
  },
};
</script>

<style lang="scss">
.lesson-manage {
  display: flex;
  flex-flow: row nowrap;
  margin: 0 0.5rem;
  &-buttons {
    margin-right: 0.5rem;
  }
}
</style>