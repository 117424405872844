import Vue from 'vue'

export default function getTutorLessons({ student_id, schoolyear_id }) {
    const proxy = process.env.VUE_APP_PROXY
    const api_uri = process.env.VUE_APP_API_URI

    let url = proxy + api_uri + "/lessons/tutor/" + student_id
    if (schoolyear_id) {
        let q = new URLSearchParams({ schoolyear_id }).toString()
        url += `?${q}`
    }
    return Vue.http
        .get(url, {}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
}