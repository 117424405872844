<template>
  <div class="surprise-form">
    <div class="field">
      <v-select
        label="name"
        :options="studentsList"
        v-model="student_id"
        :reduce="(item) => item.id"
        :closeOnSelect="true"
        clearable
        placeholder="Select student"
        :disabled="disable"
      >
        <template #option="{ name, icon, color }">
          <vs-avatar :icon="icon" :color="color"></vs-avatar>
          {{ name }}
        </template>
        <template #selected-option="{ name, icon, color }">
          <vs-avatar :icon="icon" :color="color"></vs-avatar>
          {{ name }}
        </template>
      </v-select>
    </div>
    <div class="field">
      <vs-input
        ref="value"
        label="Surprise Name"
        v-model="name"
        :disabled="disable"
      ></vs-input>
    </div>
    <div class="field">
      <vs-input
        label="Stickers"
        v-model="stickers"
        :disabled="disable"
      ></vs-input>
    </div>
    <div class="field">
      <label class="label">Дата получения</label>
      <v-date-picker
        v-model="received"
        mode="date"
        ref="calendar"
        :disabled="disable"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <div>
            <vs-input
              class="calendar-input"
              :value="`${inputValue}`"
              v-on="inputEvents"
              size="large"
            />
          </div>
        </template>
      </v-date-picker>
    </div>
    <div class="field">
      <label class="label">Category</label>
      <v-select
        label="name"
        :options="categories"
        v-model="category_id"
        :reduce="(item) => item.id"
        :closeOnSelect="true"
        clearable
        placeholder="Select category"
        :disabled="disable"
      >
        <template #option="{ name, icon, color }">
          <vs-avatar :icon="icon" :color="color"></vs-avatar>
          {{ name }}
        </template>
        <template #selected-option="{ name, icon, color }">
          <vs-avatar :icon="icon" :color="color"></vs-avatar>
          {{ name }}
        </template>
      </v-select>
    </div>

    <div class="field" v-if="$can('manage', 'surprises')">
      <v-select
        label="nickname"
        :options="teachers"
        v-model="giver_id"
        :reduce="(item) => item.id"
        :closeOnSelect="true"
        clearable
        placeholder="Select teacher"
        :disabled="disable"
      >
        <template #option="{ nickname, options }">
          <vs-avatar
            :src="
              options && options.avatar
                ? `${proxy}/static/files/${options.avatar}`
                : undefined
            "
          ></vs-avatar
          >{{ nickname }}
        </template>
        <template #selected-option="{ nickname, options }">
          <vs-avatar
            :src="
              options && options.avatar
                ? `${proxy}/static/files/${options.avatar}`
                : undefined
            "
          ></vs-avatar>
          {{ nickname }}
        </template>
      </v-select>
    </div>
    <div class="field">
      <label>Description</label>
      <vs-textarea v-model="desc" :disabled="disable"></vs-textarea>
    </div>
    <div class="save-popup-button">
      <vs-button icon="save" @click="save" :disabled="disable">Save</vs-button>
      <vs-checkbox
        class="add-more-checkbox"
        v-model="addAnother"
        v-if="!surprise"
        >Add more</vs-checkbox
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import vSelect from "vue-select";
export default {
  name: "surpriseForm",
  props: ["surprise", "students", "selectedStudentId", "lesson", "teacher_id"],
  data() {
    let user = JSON.parse(localStorage.getItem("user"));

    let data = {
      addAnother: false,
      id: null,
      name: null,
      category_id: null,
      stickers: 0,
      giver_id: user.id,
      student_id: this.selectedStudentId ? this.selectedStudentId : null,
      proxy: process.env.VUE_APP_PROXY,
      studentsList: this.students,
      received: new Date(),
      desc: null,
      disable: false,
    };
    if (this.lesson && this.lesson.id) {
      data.lesson_id = this.lesson.id;
      data.received = new Date(this.lesson.lesson_time * 1000);
      data.giver_id = this.lesson.teacher?.id || this.lesson.main_teacher.id;
    }
    if (this.surprise && this.surprise.id) {
      const { name, id, stickers, desc, lesson_id } = this.surprise;
      const received = new Date(this.surprise.received * 1000);
      const category_id = this.surprise.category
        ? this.surprise.category.id
        : null;
      const students = [this.surprise.student];
      const student_id = this.surprise.student.id;
      const giver_id = this.surprise.giver ? this.surprise.giver.id : null;
      data = {
        ...data,
        name,
        id,
        stickers,
        received,
        category_id,
        student_id,
        lesson_id,
        giver_id,
        desc,
        studentsList: students,
      };
    }
    return data;
  },
  computed: {
    ...mapState({
      categories: (state) => state.surprises.categories,
      teachers: (state) => state.teachers.teachers,
    }),
  },
  components: {
    vSelect,
  },
  methods: {
    save: function () {
      this.disable = true;
      const {
        giver_id,
        student_id,
        name,
        id,
        received,
        category_id,
        lesson_id,
        desc,
        stickers,
      } = this;
      let surprise = {
        giver_id,
        student_id,
        name,
        received,
        category_id,
        lesson_id,
        desc,
        stickers,
      };
      if (id) surprise.id = id;
      surprise.received = received.getTime() / 1000;
      this.$store
        .dispatch("surprises/saveSurpriseAction", { surprise })
        .then(() => {
          this.$vs.notify({
            title: "Surprise saved!",
            text: ``,
            color: "success",
            position: "top-right",
          });
          this.$store.dispatch("surprises/getSurprisesByStudentAction", {
            student_id,
          });
          if (!this.addAnother) {
            this.$emit("closePopup");
          } else {
            this.id = null;
            this.name = null;
            this.category_id = null;
            this.stickers = 0;
            // this.giver_id = null;
            this.desc = null;
            this.$refs.value.focusInput();
          }
          this.disable = false;
        });
    },
  },
  created() {
    if (!this.categories.length) {
      this.$store.dispatch("surprises/getCategoriesAction");
    }
    if (!this.teachers.length) {
      this.$store.dispatch("teachers/getTeachersAction");
    }
    setTimeout(() => {
      this.$refs.value.focusInput();
    }, 100);
  },
};
</script>

<style lang="scss">
.form-popup.surprise__popup {
  position: absolute;
  z-index: 20001;
  .vs-popup {
    height: 100%;
  }
  .surprise__popup-content {
    height: 100%;
  }
  .vs-input {
    width: 100%;
    input {
      padding: 12px;
    }
  }
  .add-more-checkbox {
    padding: 10px;
  }
}
</style>