import Vue from 'vue'

export default function visitLesson({ data }) {
    const proxy = process.env.VUE_APP_PROXY
    const api_uri = process.env.VUE_APP_API_URI

    let url = proxy + api_uri + "/lessons/" + data.id + "/visit"
    return Vue.http
        .put(url, { ...data }, {
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: 10 * 1000,
        })
        .then(response => response.json()).catch((response) => { throw response.body.message })
}