export const TASK_IMPORTANCE = [
    { id: 0, name: "Routine", icon: "expand_more", color: "primary" },
    { id: 1, name: "Middle", icon: "remove", color: "warning" },
    { id: 2, name: "Critical", icon: "expand_less", color: "danger" },
]

export const TASK_STATUS = [
    { id: 0, name: "open", color: "#d7d7d7", class: "task-open" },
    { id: 1, name: "review", color: "primary", class: "task-review" },
    { id: 2, name: "closed", color: "success", class: "task-close" },
]

export const TASK_SORT = [
    { id: 'created', name: "Created", color: "dark", icon: "north", class: "created-asc" },
    { id: '-created', name: "Created", color: "dark", icon: "south", class : "created-desc" },
    { id: 'date_finish', name: "Finish date", color: "dark", icon: "north", class : "finish-asc" },
    { id: '-date_finish', name: "Finish date", color: "dark", icon: "south", class : "finish-desc" },
]