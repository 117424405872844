import getOrdersGroups from "@/api/orders/getOrdersGroups";
import getAllOrders from "@/api/orders/getAllOrders";
import getAllBooksOrders from "@/api/orders/getAllBooksOrders";
import getOrdersGroupsByStudent from "@/api/orders/getOrdersGroupsByStudent";
import saveOrdersGroupsStudent from "@/api/orders/saveOrdersGroupsStudent";
import resetSaveOrdersGroupsStudent from "@/api/orders/resetSaveOrdersGroupsStudent";
import getOrdersTutorByStudent from "@/api/orders/getOrdersTutorByStudent";
import setOrdersGroups from "@/api/orders/setOrdersGroups";
import getOriginalOrdersGroups from "@/api/orders/getOriginalOrdersGroups";
import exportOrdersGroups from "@/api/orders/exportOrdersGroups";

const state = {
    ordersGroups: [],
    ordersByStudent: {},
    ordersStat: {},
    orderTutor: [],
    ordersAll: {},
    ordersBooksAll: []
};

const getters = {
    ordersGroupsByStudent: state => id => {
        return state.ordersByStudent[id];
    },
    ordersGroupsByGroup: state => (id, group_id) => {
        return Object.keys(state.ordersByStudent).length ? state.ordersByStudent[id][group_id] : [];
    }
}

const actions = {
    getOrdersGroupsAction({ commit }, { month, year, schoolyear }) {
        return new Promise((resolve, reject) => {
            getOrdersGroups.call(this, { month, year, schoolyear }).then(response => {
                if (response.status == 'OK') {
                    commit('setOrdersGroupsMutation', { orders: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    getOrdersGroupsByStudentAction({ commit }, { student, groups, schoolyear_id }) {
        return new Promise((resolve, reject) => {
            getOrdersGroupsByStudent.call(this, { student, groups, schoolyear_id }).then(response => {
                if (response.status == 'OK') {
                    const { data, meta } = response
                    commit('setOrdersGroupsByStudentMutation', { data, meta })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    getOrdersTutorByStudentAction({ commit }, { student, schoolyear_id }) {
        return new Promise((resolve, reject) => {
            getOrdersTutorByStudent.call(this, { student, schoolyear_id }).then(response => {
                if (response.status == 'OK') {
                    commit('setOrdersTutorByStudentMutation', { orders: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    setOrdersGroupsAction({ commit }, { data }) {
        return new Promise((resolve, reject) => {
            setOrdersGroups.call(this, { data }).then(response => {
                if (response.status == 'OK') {
                    // commit('setOrdersGroupsMutation', { orders: response.data })
                    commit('updateOrdersGroupsMutation', { orders: [response.data] })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    getOriginalOrdersAction({ commit }, data) {
        return new Promise((resolve, reject) => {
            getOriginalOrdersGroups.call(this, data).then(response => {
                if (response.status == 'OK') {
                    commit('updateOriginaOrdersGroupsMutation', { orders: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    getAllOrdersAction({ commit }, { schoolyear_id, month, year, type }) {
        return new Promise((resolve, reject) => {
            getAllOrders.call(this, { schoolyear_id, month, year, type }).then(response => {
                if (response.status == 'OK') {
                    commit('setAllOrders', { response: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    getAllBookOrdersAction({ commit }, { schoolyear_id }) {
        return new Promise((resolve, reject) => {
            getAllBooksOrders.call(this, { schoolyear_id }).then(response => {
                if (response.status == 'OK') {
                    commit('setAllBooksOrdersMutation', { response: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    saveStudentGroupOrderAction({commit},{order_id, data }) {
        return new Promise((resolve, reject) => {
            saveOrdersGroupsStudent.call(this, { order_id, data }).then(response => {
                commit('saveOrderMutation')
                if (response.status == 204) resolve()
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    resetSaveStudentGroupOrderAction({commit},{order_id }) {
        return new Promise((resolve, reject) => {
            resetSaveOrdersGroupsStudent.call(this, { order_id }).then(response => {
                commit('saveOrderMutation')
                if (response.status == 204) resolve()
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    exportOrdersGroupAction({commit},{month, year }) {
        return new Promise((resolve, reject) => {
            exportOrdersGroups.call(this, { month, year }).then(response => {
                commit('exportOrdersGroupsMutation')
                resolve(response)
            }).catch(e => { reject(new Error(e)) })
        })
    },
    clearAllOrdersAction({ commit }) {
        commit('clearAllOrders')
    }
}

const mutations = {
    setOrdersGroupsMutation(state, { orders }) {
        const items = orders.map((o, idx) => {
            return { ...o, sub_id: idx, lesson_cur_pay_original: o.lesson_cur_pay }
        })
        state.ordersGroups = items
    },
    setOrdersTutorByStudentMutation(state, { orders }) {
        state.orderTutor = orders
    },
    updateOrdersGroupsMutation(state, { orders }) {
        updateOrders(state, { orders })
    },
    updateOriginaOrdersGroupsMutation(state, { orders }) {
        orders = orders.map(o => {
            return { ...o, edited: true, lesson_cur_pay_original: o.lesson_cur_pay }
        })
        updateOrders(state, { orders })
    },
    setOrdersGroupsByStudentMutation(state, { data, meta }) {
        const { student_id, groups } = meta
        // orders
        const currentOrders = state.ordersByStudent[student_id]
        let ordersObj = {}
        ordersObj[student_id] = { ...currentOrders, ...data }
        state.ordersByStudent = { ...state.ordersByStudent, ...ordersObj }

        // stat
        const curStat = state.ordersStat[student_id]
        let metaObj = {}
        metaObj[student_id] = { ...curStat, ...groups }
        state.ordersStat = { ...state.ordersStat, ...metaObj }
    },
    saveOrderMutation(){},
    clearOrdersByStudentMutation(state) {
        state.ordersByStudent = {}
        state.orderTutor = []
    },
    setAllOrders(state, { response }) {
        state.ordersAll = response
    },
    setAllBooksOrdersMutation(state, { response }) {
        state.ordersBooksAll = response
    },
    exportOrdersGroupsMutation(){},
    clearAllOrders(state) {
        state.ordersAll = {}
        state.ordersBooksAll = []
    }
}

function updateOrders(state, { orders }) {
    orders.forEach((order) => {
        // TODO: Переделать:ищем цвет, если объект один
        // if (orders.length == 1) {
        //     const item = state.ordersGroups.find(d => d.value == order.value);
        //     order.order_group = item ? item.order_group : null;
        //     order.color = item ? item.color : null;
        // }
        // заменяем
        const index = state.ordersGroups.findIndex(r => r.group.id == order.group.id)
        if (index !== -1 && typeof index !== 'undefined') {
            state.ordersGroups.splice(index, 1, order)
        } else {
            state.ordersGroups = [...state.ordersGroups, order]
        }
    })
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}