<template>
  <div class="task">
    <div v-if="task">
      <div class="task-header">
        <task-status :status="status" />
        <div class="task-header__importance">
          <vs-icon
            :icon="importance.icon"
            :color="importance.color"
            :title="importance.name"
          ></vs-icon>
          <span>{{ importance.name }}</span>
        </div>
        <div class="task-manager">
          <div class="task-manager__edit" v-if="isEditable">
            <vs-button
              type="flat"
              color="dark"
              icon="edit"
              size="small"
              @click="$emit('editTask')"
              >Edit
            </vs-button>
          </div>
          <div class="task-manager__close" v-if="isWork">
            <vs-button
              color="success"
              icon="person"
              type="border"
              size="small"
              @click="taskGetinWorkHandler"
              >Get in work
            </vs-button>
          </div>
          <div class="task-manager__close" v-if="isReview">
            <vs-button
              color="primary"
              icon="grading"
              size="small"
              @click="taskReviewHandler"
              >Review
            </vs-button>
          </div>
          <div class="task-manager__close" v-if="isClosable">
            <vs-button
              color="success"
              icon="done"
              size="small"
              @click="taskCloseHandler"
              >Close
            </vs-button>
          </div>
          <div class="task-manager__open" v-if="task.status > 0">
            <vs-button
              color="dark"
              type="flat"
              icon="add_task"
              size="small"
              @click="taskReopenHandler"
              >Reopen
            </vs-button>
          </div>
          <div class="task-manager__delete" v-if="isDeleted">
            <vs-button
              color="dark"
              type="flat"
              icon="delete"
              size="small"
              @click="taskDeleteHandler"
            ></vs-button>
          </div>
        </div>
      </div>
      <task-dates :task="task"></task-dates>

      <div class="task-title">
        <div
          class="task-color"
          :style="{
            backgroundColor: task.event.color ? task.event.color : null,
          }"
          v-if="task.event"
        ></div>
        <h2>
          {{ task.name }}
        </h2>
      </div>
      <div class="task-content">
        <vs-row>
          <vs-col vs-w="4" v-if="task.group.id">
            <div class="task-item-place">
              <vs-avatar
                icon="people"
                :color="task.group.color"
                size="small"
              ></vs-avatar>
              <router-link
                :to="{ name: 'Group', params: { group_id: task.group.id } }"
                class="group-link"
              >
                <span>Group: {{ task.group.name }}</span>
              </router-link>
            </div>
          </vs-col>
          <vs-col vs-w="4">
            <div
              v-for="student in task.students"
              :key="student.id"
              class="task-item-place"
            >
              <vs-avatar icon="person" size="small"></vs-avatar>
              <router-link
                :to="{ name: 'Student', params: { student_id: student.id } }"
                class="student-link"
              >
                <span>{{ student.last_name }} {{ student.first_name }}</span>
              </router-link>
            </div>
          </vs-col>
          <vs-col vs-w="4" v-if="task.lesson.id">
            <vs-avatar
              icon="query_builder"
              size="small"
              color="#aaa"
            ></vs-avatar>
            <router-link
              :to="{ name: 'Lessons', params: { lesson_id: task.lesson.id } }"
              class="lesson-link"
            >
              <span>{{ task.lesson.name }} - </span>
              <span>{{
                $moment(task.lesson.lesson_time * 1000).format(
                  'DD.MM.YYYY HH.mm'
                )
              }}</span>
            </router-link>
          </vs-col>
          <vs-col
            v-if="!task.group.id && !task.students.length && !task.lesson.id"
            class="task-content__empty"
          >
            <vs-icon icon="travel_explore" color="#ccc"></vs-icon>
            <span> No links </span>
          </vs-col>
        </vs-row>
      </div>
      <div class="task-teachers-list">
        <div class="task-teachers__title task-block__title">Assigned:</div>
        <div class="task-teachers">
          <div
            v-for="t in task.teachers"
            :key="t.id"
            class="task-teachers__teacher"
          >
            <teacher-chip-view :teacher="t" :isClosedTask="task.status == 2" />
          </div>
        </div>
      </div>
      <div class="task-description" v-if="task.desc">
        <div class="task-description__title task-block__title">
          Description:
        </div>
        <div v-html="task.desc"></div>
      </div>

      <div class="task-comments">
        <div class="task-comments__title task-block__title">Comments:</div>
        <comments :itemId="task.id" itemType="Tasks" />
      </div>
      <div class="task-sub-data">
        <vs-row>
          <vs-col class="task__sub">
            <div>
              <h4 for="">
                <vs-icon icon="add" size="16px"></vs-icon>
                Given by:
              </h4>
              <span class="">
                {{ $moment(task.created * 1000).format('YYYY-MM-DD HH:mm') }}
              </span>
            </div>

            <div>
              <vs-avatar
                size="22px"
                :src="
                  task.owner.options && task.owner.options.avatar
                    ? `${proxy}/static/files/${task.owner.options.avatar}`
                    : undefined
                "
              ></vs-avatar
              >{{ task.owner.name }}
            </div>
          </vs-col>
          <vs-col
            class="task__sub"
            v-for="t in task.teachers.filter(t => t.worked_at)"
            :key="t.id"
          >
            <h4 for="">
              <vs-icon icon="person" size="16px"></vs-icon>
              In work by:
            </h4>
            <div class="">
              {{ $moment(t.worked_at * 1000).format('YYYY-MM-DD HH:mm') }}
            </div>
            <div class="task-closer">
              <vs-avatar
                size="22px"
                :src="
                  t.options && t.options.avatar
                    ? `${proxy}/static/files/${t.options.avatar}`
                    : undefined
                "
              ></vs-avatar
              >{{ t.name }}
            </div>
          </vs-col>
          <vs-col class="task__sub" v-if="task.review">
            <h4 for="">
              <vs-icon icon="grading" size="16px"></vs-icon>
              Review by:
            </h4>
            <div class="">
              {{
                $moment(task.review.review * 1000).format('YYYY-MM-DD HH:mm')
              }}
            </div>
            <div class="task-closer">
              <vs-avatar
                size="22px"
                :src="
                  task.review.teacher.options &&
                  task.review.teacher.options.avatar
                    ? `${proxy}/static/files/${task.review.teacher.options.avatar}`
                    : undefined
                "
              ></vs-avatar
              >{{ task.review.teacher.name }}
            </div>
          </vs-col>
          <vs-col class="task__sub" v-if="task.closed">
            <h4 for="">
              <vs-icon icon="done" size="16px"></vs-icon>
              Closed by:
            </h4>
            <div class="">
              {{
                $moment(task.closed.closed * 1000).format('YYYY-MM-DD HH:mm')
              }}
            </div>
            <div class="task-closer">
              <vs-avatar
                size="22px"
                :src="
                  task.closed.teacher.options &&
                  task.closed.teacher.options.avatar
                    ? `${proxy}/static/files/${task.closed.teacher.options.avatar}`
                    : undefined
                "
              ></vs-avatar
              >{{ task.closed.teacher.name }}
            </div>
          </vs-col>
        </vs-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import comments from '../comments/comments.vue'
import { TASK_IMPORTANCE, TASK_STATUS } from './dicts'
import taskStatus from './taskStatus.vue'
import taskDates from './taskDates.vue'
import closeTask from '@/api/tasks/closeTask'
import reviewTask from '@/api/tasks/reviewTask'
import openTask from '@/api/tasks/openTask'
import deleteTask from '@/api/tasks/deleteTask'
import getTaskInWork from '@/api/tasks/inWork'
import teacherChipView from '@/components/teachers/teacherChipView'

export default {
  name: 'taskView',
  components: {
    taskStatus,
    taskDates,
    teacherChipView,
    comments,
  },
  props: {
    id: { type: Number },
    isEdit: {
      type: Boolean,
      default: true,
    },
    taskUnsavedObject: {
      type: Object,
    },
  },
  data() {
    return {
      proxy: process.env.VUE_APP_PROXY,
    }
  },
  computed: {
    ...mapState({
      singleTask: state => state.tasks.singleTask,
    }),
    task: function() {
      return this.singleTask || this.taskUnsavedObject
    },
    importance: function() {
      return (
        TASK_IMPORTANCE.find(i => i.id == this.task.importance) ||
        TASK_IMPORTANCE[1]
      )
    },
    status: function() {
      return TASK_STATUS.find(s => s.id == this.task.status) || TASK_STATUS[0]
    },
    isClosable: function() {
      // для задач со средней и высокой важностью не показываем
      if (this.task.status == 0 && this.task.importance > 0) return false

      if (this.task.status == 2) return false
      if (this.$can('manage', 'task_edit')) return true
      return false
    },
    isReview: function() {
      // для задач со средней и высокой важностью не показываем
      if (this.task.status == 0 && this.task.importance == 0) return false

      if (this.task.status >= 1) return false
      if (this.$can('manage', 'task_edit')) return true
      if (this.task.teachers.find(t => t.id == this.$getUser().id)) return true
      return false
    },
    isEditable: function() {
      if (this.$can('manage', 'task_edit') && this.isEdit) return true
      // if (this.task.teachers.find((t) => t.id == this.$getUser().id))
      //   return true;
      return false
    },
    isDeleted: function() {
      if (this.$can('manage', 'task_edit')) return true
      // if (this.task.teachers.find((t) => t.id == this.$getUser().id))
      //   return true;
      return false
    },
    isWork: function() {
      const teacher = this.task.teachers?.find(t => t.id == this.$getUser().id)
      if (!teacher) return false
      if (this.task.status != 2 && !teacher.worked_at) return true
      return false
    },
  },
  methods: {
    taskCloseHandler: function() {
      closeTask
        .call(this, this.task.id)
        .then(() => {
          this.$emit('taskSaved')
          this.$vs.notify({
            title: 'Task closed!',
            text: ``,
            color: 'success',
            position: 'top-right',
          })
        })
        .catch(e => {
          this.$vs.notify({
            title: 'Task not closed!',
            text: `${e}`,
            color: 'danger',
            position: 'top-right',
          })
        })
    },
    taskReviewHandler: function() {
      reviewTask
        .call(this, this.task.id)
        .then(() => {
          this.$emit('taskSaved')
          this.$vs.notify({
            title: 'Task send to review!',
            text: ``,
            color: 'success',
            position: 'top-right',
          })
        })
        .catch(e => {
          this.$vs.notify({
            title: 'Task not in review!',
            text: `${e}`,
            color: 'danger',
            position: 'top-right',
          })
        })
    },
    taskReopenHandler: function() {
      openTask
        .call(this, this.task.id)
        .then(() => {
          this.$emit('taskSaved')
          this.$vs.notify({
            title: 'Task reopen!',
            text: ``,
            color: 'success',
            position: 'top-right',
          })
        })
        .catch(e => {
          this.$vs.notify({
            title: 'Task not open!',
            text: `${e}`,
            color: 'danger',
            position: 'top-right',
          })
        })
    },
    taskGetinWorkHandler: function() {
      getTaskInWork
        .call(this, this.task.id)
        .then(() => {
          this.$emit('taskSaved')
          this.$vs.notify({
            title: 'Task in work!',
            text: ``,
            color: 'success',
            position: 'top-right',
          })
        })
        .catch(e => {
          this.$vs.notify({
            title: 'Task not in work!',
            text: `${e}`,
            color: 'danger',
            position: 'top-right',
          })
        })
    },
    taskDeleteHandler: function() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirm`,
        text: 'Delete Task?',
        accept: () => {
          deleteTask.call(this, this.task.id).then(() => {
            this.$emit('taskSaved')
            this.$vs.notify({
              title: 'Task deleted!',
              text: ``,
              color: 'success',
              position: 'top-right',
            })
          })
        },
      })
    },
    getSingleTask: function() {
      this.$store.dispatch('tasks/getSingleTask', this.id)
    },
    createEvent: async function() {
      // сохраняем событие
      const data = {
        ...this.taskUnsavedObject,
        ...this.taskUnsavedObject.event,
        date_start: this.$moment(
          this.taskUnsavedObject.date_start * 1000
        ).format('YYYY-MM-DD HH:mm'), // КОСТЫЛЬ!!!!
        teacher_ids: this.taskUnsavedObject.teachers?.map(t => t.id),
        student_ids: this.taskUnsavedObject.students?.map(s => s.id),
        group_id: this.taskUnsavedObject.group
          ? this.taskUnsavedObject.group.id
          : {},
        lesson_id: this.taskUnsavedObject.lesson
          ? this.taskUnsavedObject.lesson.id
          : {},
      }
      await this.$store
        .dispatch('events/saveEventAction', { data })
        .then(data => this.$emit('taskSaved', data[0]))
    },
  },
  created: function() {
    // очищаем предыдущую задачу при создании нового экземпляра компонента
    // это чтобы при смене статуса блоки не прыгали
    this.$store.dispatch('tasks/clearSingleTaskAction')
    // получаем задачу
    if (this.id) {
      this.getSingleTask()
    } else {
      if (this.taskUnsavedObject.event?.is_event) {
        this.createEvent()
      }
    }
  },
}
</script>

<style lang="scss"></style>
