import getComments from "@/api/comments/getComments";
import setComments from "@/api/comments/setComments";
import deleteComment from "@/api/comments/deleteComment";


const state = { comments: [], singleLesson: [], commentMeta: {} };

const actions = {
    getCommentsAction({ commit }, { itemId, itemType, query }) {
        return new Promise((resolve, reject) => {
            getComments.call(this, itemType, itemId, query).then(response => {
                if (response.status == 'OK') {
                    commit('setCommentsMutation', { comments: response.data, commentMeta: response.meta })
                    resolve()
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    setCommentAction({ commit }, { data }) {
        return new Promise((resolve, reject) => {
            setComments.call(this, { data }).then(response => {
                if (response.status == 'OK') {
                    commit('updateCommentsMutation', { comments: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    deleteCommentAction({ commit }, id) {
        return new Promise((resolve, reject) => {
            deleteComment.call(this, id).then(response => {
                if (response.status == 'OK') {
                    commit('updateCommentsMutation', { comments: [response.data] })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    clearCommentsAction({ commit }) {
        commit('clearCommentMutation')
    }
}

const mutations = {
    setCommentsMutation(state, { comments, commentMeta }) {
        state.commentMeta = commentMeta;
        console.log(state.commentMeta)
        if (commentMeta.page > 1) {
            state.comments = [...state.comments, ...comments]
        }
        else state.comments = comments
    },
    updateCommentsMutation(state, { comments }) {
        comments.forEach(comment => {
            const index = state.comments.findIndex(r => r.id == comment.id)
            if (index !== -1 && typeof index !== 'undefined') {
                state.comments.splice(index, 1, comment)
            } else {
                state.comments = [comment, ...state.comments,]
            }
        })
    },
    clearCommentMutation(state) {
        state.comments = []
        state.commentMeta = {}
    }
}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}