<template>
  <div
    class="files-items"
    :class="{ row: direction == 'row', column: direction == 'column' }"
  >
    <div class="file" v-for="f in files" :key="f.id">
      <file-item
        :file="f"
        :isEditable="Boolean(isEditable)"
        @deleted="deleteHandler"
        @showImage="showHandler"
        :size="size || ''"
      />
    </div>
    <viewer :images="images" @inited="inited" class="viewer" ref="viewer">
      <img
        v-for="(src, index) in images"
        :src="proxy + src.file_path"
        :key="index"
      />
    </viewer>
  </div>
</template>

<script>
import { component as Viewer } from 'v-viewer'
import imgExtensionsList from '@/components/common/dicts/imageExtensions.js'

import fileItem from './item.vue'
export default {
  components: { fileItem, Viewer },
  name: 'filesList',
  props: ['files', 'direction', 'delete', 'size', 'isEditable'],
  data() {
    return {
      proxy: process.env.VUE_APP_PROXY,
    }
  },
  computed: {
    images: function() {
      const files = this.files?.filter(f =>
        imgExtensionsList.includes(
          f.name.slice(((f.name.lastIndexOf('.') - 1) >>> 0) + 2).toLowerCase()
        )
      )
      return files
    },
  },
  methods: {
    deleteHandler: function(file_id) {
      console.log(file_id)
    },
    inited(viewer) {
      this.$viewer = viewer
    },
    showHandler: function(fileId) {
      const fileIndex = this.images.findIndex(f => f.id == fileId)
      if (fileIndex != -1) this.$viewer.view(fileIndex)
    },
  },
}
</script>

<style lang="scss">
.viewer {
  display: none;
}
.files-items {
  display: flex;
  flex-flow: row wrap;
}
</style>
