import { render, staticRenderFns } from "./filesList.vue?vue&type=template&id=4051a736&"
import script from "./filesList.vue?vue&type=script&lang=js&"
export * from "./filesList.vue?vue&type=script&lang=js&"
import style0 from "./filesList.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports