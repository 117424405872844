import uploadFile from "@/api/files/uploadFile";
import getFiles from "@/api/files/getFiles";
import deleteFile from "@/api/files/deleteFile";

const state = { files: [] };

const actions = {
    uploadFile({ commit }, { data }) {
        return new Promise((resolve, reject) => {
            uploadFile.call(this, { data }).then(response => {
                if (response.status == 'OK') {
                    commit('setFilesMutation', { files: [response.data] })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    getFilesAction({ commit }, filters) {
        return new Promise((resolve, reject) => {
            getFiles.call(this, filters).then(response => {
                if (response.status == 'OK') {
                    commit('setFilesMutation', { files: response.data })
                    resolve(response.data)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    },
    deleteFileAction({commit}, id){
        return new Promise((resolve, reject) => {
            deleteFile.call(this, id).then(response => {
                if (response.status == 204) {
                    commit('deleteFileMutation', { id })
                    resolve(response)
                }
                else { reject(new Error(response.message)) }
            }).catch(e => { reject(new Error(e)) })
        })
    }
}

const mutations = {
    setFilesMutation(state, { files }) {
        state.files = files
    },

    deleteFileMutation(state, { id }) {
        const index = state.files.findIndex(f => f.id == id)
        if (index != -1)
            state.files.splice(index, 1)
    },

}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}