import Vue from 'vue'

export default function saveEvent({ data }) {
  const proxy = process.env.VUE_APP_PROXY
  const api_uri = process.env.VUE_APP_API_URI

  let url = proxy + api_uri + '/events'

  return Vue.http
    .post(url, [data], {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.json())
    .catch(() => {
      throw 'Ошибка сохранения'
    })
}
