<template>
  <div class="upload">
    <div
      class="upload__dropzone"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
      :class="{ dragging: isDragging }"
    >
      <input
        type="file"
        name="file"
        id="file-input"
        ref="file"
        multiple
        class="hidden-input"
        :accept="accept.join(',')"
        @change="changeHandler"
      />
      <label for="file-input" class="file-label">
        <div>
          <vs-icon icon="attach_file"></vs-icon>Drop files here or
          <u>click here</u> to upload.
        </div>
      </label>
    </div>
    <div class="upload__preview">
      <div class="file" v-for="(file, index) in files" :key="index">
        <file-item
          :file="file"
          :isEditable="true"
          @cropped="croppedHandler"
          @deleted="deleteHandler"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FileItem from './item.vue'

export default {
  props: ['value', 'clipboardFiles'],
  data() {
    return {
      isDragging: false,
      files: [],
      accept: ['.pdf', '.jpg', '.jpeg', '.png'],
      editFile: '',
      editFileUrl: '',
      loading: false,
    }
  },
  components: { FileItem },
  methods: {
    uploadFileHandler: async function(file) {
      var formData = new FormData()
      formData.append('type', 'comment')
      if (file.id) {
        formData.append('id', file.id)
      }

      formData.append('file', file, file.name)
      let uploadedFile = await this.$store.dispatch('files/uploadFile', {
        data: formData,
      })
      if (uploadedFile.id) {
        return uploadedFile
      }
      return []
    },
    addFilesHandler: async function(files) {
      files.forEach(async f => {
        const file = await this.uploadFileHandler(f)
        this.files = [
          ...this.files,
          {
            ...file,
            source: f,
          },
        ]
        this.$emit('input', this.files)
      })
    },
    dragover: function(e) {
      e.preventDefault()
      this.isDragging = true
    },
    dragleave: function(e) {
      e.preventDefault()
      this.isDragging = false
    },
    drop: function(e) {
      e.preventDefault()
      this.addFilesHandler(e.dataTransfer.files)
      this.isDragging = false
    },
    changeHandler: function() {
      this.addFilesHandler(this.$refs.file.files)
    },
    deleteHandler: function(file_id) {
      const index = this.files.findIndex(f => f.id == file_id)
      if (index != -1) this.files.splice(index, 1)
    },
    croppedHandler: async function(id, sourceFile) {
      let fileIndex = this.files.findIndex(f => f.id == id)
      if (fileIndex == -1) {
        console.error('error in cropped file')
      }
      sourceFile.id = id
      let uploadedFile = await this.uploadFileHandler(sourceFile)
      this.files.splice(fileIndex, 1, { ...uploadedFile, source: sourceFile })
    },
  },
  created: function() {
    this.value.forEach(async f => {
      const { name, file_path } = f
      const proxy = process.env.VUE_APP_PROXY
      let res = await fetch(proxy + file_path, {
        methods: 'GET',
      })
      const blob = await res.blob()
      const newFile = new File([blob], name, {
        type: `image/${name.slice(((name.lastIndexOf('.') - 1) >>> 0) + 2)}`,
      })
      this.files.push({
        ...f,
        source: newFile,
      })
    })
  },
  watch: {
    clipboardFiles: function(newV) {
      if (newV.length) this.addFilesHandler(newV)
    },
  },
}
</script>

<style lang="scss">
.upload__dropzone {
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  cursor: pointer;
  border-radius: 7px;
  &:hover {
    border: 1px solid #ccc;
  }
  .hidden-input {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
  }
  &.dragging {
    border: 1px dashed #000;
  }
  u {
    text-decoration: underline;
  }
  label {
    cursor: pointer;
  }
}
.upload__preview {
  display: flex;
  flex-flow: row wrap;
}
</style>
