var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"surprise-form"},[_c('div',{staticClass:"field"},[_c('v-select',{attrs:{"label":"name","options":_vm.studentsList,"reduce":function (item) { return item.id; },"closeOnSelect":true,"clearable":"","placeholder":"Select student","disabled":_vm.disable},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var icon = ref.icon;
var color = ref.color;
return [_c('vs-avatar',{attrs:{"icon":icon,"color":color}}),_vm._v(" "+_vm._s(name)+" ")]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
var icon = ref.icon;
var color = ref.color;
return [_c('vs-avatar',{attrs:{"icon":icon,"color":color}}),_vm._v(" "+_vm._s(name)+" ")]}}]),model:{value:(_vm.student_id),callback:function ($$v) {_vm.student_id=$$v},expression:"student_id"}})],1),_c('div',{staticClass:"field"},[_c('vs-input',{ref:"value",attrs:{"label":"Surprise Name","disabled":_vm.disable},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('div',{staticClass:"field"},[_c('vs-input',{attrs:{"label":"Stickers","disabled":_vm.disable},model:{value:(_vm.stickers),callback:function ($$v) {_vm.stickers=$$v},expression:"stickers"}})],1),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Дата получения")]),_c('v-date-picker',{ref:"calendar",attrs:{"mode":"date","disabled":_vm.disable},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('div',[_c('vs-input',_vm._g({staticClass:"calendar-input",attrs:{"value":("" + inputValue),"size":"large"}},inputEvents))],1)]}}]),model:{value:(_vm.received),callback:function ($$v) {_vm.received=$$v},expression:"received"}})],1),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Category")]),_c('v-select',{attrs:{"label":"name","options":_vm.categories,"reduce":function (item) { return item.id; },"closeOnSelect":true,"clearable":"","placeholder":"Select category","disabled":_vm.disable},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var icon = ref.icon;
var color = ref.color;
return [_c('vs-avatar',{attrs:{"icon":icon,"color":color}}),_vm._v(" "+_vm._s(name)+" ")]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
var icon = ref.icon;
var color = ref.color;
return [_c('vs-avatar',{attrs:{"icon":icon,"color":color}}),_vm._v(" "+_vm._s(name)+" ")]}}]),model:{value:(_vm.category_id),callback:function ($$v) {_vm.category_id=$$v},expression:"category_id"}})],1),(_vm.$can('manage', 'surprises'))?_c('div',{staticClass:"field"},[_c('v-select',{attrs:{"label":"nickname","options":_vm.teachers,"reduce":function (item) { return item.id; },"closeOnSelect":true,"clearable":"","placeholder":"Select teacher","disabled":_vm.disable},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var nickname = ref.nickname;
var options = ref.options;
return [_c('vs-avatar',{attrs:{"src":options && options.avatar
              ? (_vm.proxy + "/static/files/" + (options.avatar))
              : undefined}}),_vm._v(_vm._s(nickname)+" ")]}},{key:"selected-option",fn:function(ref){
              var nickname = ref.nickname;
              var options = ref.options;
return [_c('vs-avatar',{attrs:{"src":options && options.avatar
              ? (_vm.proxy + "/static/files/" + (options.avatar))
              : undefined}}),_vm._v(" "+_vm._s(nickname)+" ")]}}],null,false,1876363302),model:{value:(_vm.giver_id),callback:function ($$v) {_vm.giver_id=$$v},expression:"giver_id"}})],1):_vm._e(),_c('div',{staticClass:"field"},[_c('label',[_vm._v("Description")]),_c('vs-textarea',{attrs:{"disabled":_vm.disable},model:{value:(_vm.desc),callback:function ($$v) {_vm.desc=$$v},expression:"desc"}})],1),_c('div',{staticClass:"save-popup-button"},[_c('vs-button',{attrs:{"icon":"save","disabled":_vm.disable},on:{"click":_vm.save}},[_vm._v("Save")]),(!_vm.surprise)?_c('vs-checkbox',{staticClass:"add-more-checkbox",model:{value:(_vm.addAnother),callback:function ($$v) {_vm.addAnother=$$v},expression:"addAnother"}},[_vm._v("Add more")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }