import Vue from 'vue'

export default function getSingleBook(id) {
    const proxy = process.env.VUE_APP_PROXY
    const api_uri = process.env.VUE_APP_API_URI

    let url = proxy + api_uri + "/books/" + id

    return Vue.http
        .get(url, {}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
}