<template>
  <div class="comments__items">
    <div v-for="comment in comments" :key="comment.id">
      <closed-task-item
        :comment="comment"
        name="parent-comment"
        v-if="comment.type == 'TaskInComments'"
      />
      <CommentItem :comment="comment" name="parent-comment" v-else />
    </div>
  </div>
</template>

<script>
import CommentItem from '@/components/comments/commentItem'
import ClosedTaskItem from './closedTaskItem.vue'

export default {
  name: 'CommentsAllList',
  props: ['comments'],
  components: { CommentItem, ClosedTaskItem },
}
</script>

<style lang="scss">
.comments__items {
  clear: both;
  .comment__item {
    // border-left: 3px solid #777;
    margin: 0 0 10px 0;
    // padding: 10px;
    padding: 10px 10px 10px 0;
    // background-color: #f7f7f7;
    .comment__childs {
      margin-left: 50px;

      .student-comment {
        // margin-top: 10px;
        // border-top: 1px solid #ddd;
        position: relative;
        margin: 10px 0;
        padding: 0;
        &::before {
          content: '';
          width: 3px;
          height: 120px;
          background-color: #ccc;
          position: absolute;
          left: 55px;
          top: -60px;
          z-index: -1;
        }
        .comment__options {
          margin-left: 30px;
        }
        .comment__text {
          margin-left: 40px;
        }
      }
    }
  }
  .comment__title,
  .comment__options {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .comment__title {
    margin-left: 47px;
    // padding-left: 30px;
    // &::before {
    //   content: "";
    //   width: 10px;
    //   height: 10px;
    //   margin-right: 7px;
    //   border-radius: 50%;
    //   background-color: #777;
    // }
  }
  .comment__name {
    text-transform: capitalize;
    // font-weight: bold;
    text-decoration: underline;
    margin-bottom: 7px;
    display: flex;
    flex-flow: row nowrap;
    gap: 1rem;
    align-items: center;
  }
  .comment__text {
    // margin-bottom: 15px;
    // padding-left: 30px;
    margin: 0 0 0 50px;
    padding: 15px 25px 15px 25px;
    border-radius: 15px;
    background-color: #f2f2f2;
    box-shadow: 0 0 10px 4px #f1f1f1;
    border: 1px solid #ddd;
    .comment__text__block {
      font-size: 0.9em;
      padding: 5px 15px 0 15px;
    }
  }
  .comment__options {
    display: flex;
    flex-direction: row;
    align-items: center;
    & > * {
      margin-right: 10px;
    }
    .comment__date {
      font-size: 0.8em;
      color: var(--thead-text-color);
      &.updated {
        color: red;
      }
    }
    .comment__autor {
      font-weight: bold;
      margin-right: 25px;
      margin-left: 5px;
    }
    .comment__dates {
      display: flex;
      flex-flow: row wrap;
      flex: 1;
      align-items: center;
      & > * {
        margin-right: 10px;
      }
    }
  }
}
.comments__load-more {
  margin-left: 50px;
}
</style>
