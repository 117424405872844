<template>
  <div class="draggable-panel" ref="draggableContainer">
    <div
      class="draggable-panel__header"
      @mousedown="dragMouseDown"
      title="Move"
    >
      <vs-icon icon="drag_handle"></vs-icon>
      <slot name="header"></slot>
    </div>
    <div class="draggable-panel__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ['X', 'Y'],
  data() {
    return {
      clientX: this.X,
      clientY: this.Y,
      movementX: 0,
      movementY: 0,
    }
  },
  methods: {
    dragMouseDown: function(event) {
      event.preventDefault()
      this.clientX = event.clientX
      this.clientY = event.clientY

      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },
    elementDrag: function(event) {
      event.preventDefault()
      this.movementX = this.clientX - event.clientX
      this.movementY = this.clientY - event.clientY
      this.clientX = event.clientX
      this.clientY = event.clientY
      // set the element's new position:
      this.$refs.draggableContainer.style.top =
        this.$refs.draggableContainer.offsetTop - this.movementY + 'px'
      this.$refs.draggableContainer.style.left =
        this.$refs.draggableContainer.offsetLeft - this.movementX + 'px'
    },
    closeDragElement() {
      document.onmouseup = null
      document.onmousemove = null
    },
  },
  created() {
    setTimeout(() => {
      // Вычисляем размещение панели в зависимости от ширины и высоты экрана
      const fixOffset = 30
      this.$refs.draggableContainer.style.display = 'block'
      const windowWidth = window.innerWidth
      const width = this.$refs.draggableContainer.clientWidth

      if (this.clientX + width > windowWidth) {
        this.$refs.draggableContainer.style.left =
          windowWidth - width - fixOffset + 'px'
      } else {
        this.$refs.draggableContainer.style.left = this.clientX + 'px'
      }

      // const pageElement = document.querySelector('.content .page')
      const windowHeight = window.innerHeight
      const height = this.$refs.draggableContainer.clientHeight
      if (this.clientY + height > windowHeight) {
        this.$refs.draggableContainer.style.top =
          windowHeight - height - fixOffset + 'px'
      } else {
        this.$refs.draggableContainer.style.top = this.clientY + 'px'
      }
    }, 100)
  },
}
</script>

<style lang="scss">
.draggable-panel {
  position: fixed;
  // position: absolute;
  width: 30rem;
  height: auto;
  z-index: 1000;
  border-radius: 15px;
  box-shadow: 0 0 55px 5px #d2d2d2;
  overflow: hidden;
  display: none;
  background-color: #fff;
  &__header {
    height: 35px;
    width: 100%;
    cursor: move;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transition: 0.3s all;
    &:hover {
      background-color: #ddd;
      box-shadow: 0 -3px 10px 2px #eee;
    }
    i {
      padding: 5px 7px;
      opacity: 0.6;
    }
  }
  &__content {
    position: relative;
    width: 100%;
    height: 100%;
    & > div {
      margin: 0 !important;
    }
  }
}
</style>
