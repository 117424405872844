import Vue from 'vue'

export default function resetSaveOrdersGroupsStudent({ order_id }) {
    const proxy = process.env.VUE_APP_PROXY
    const api_uri = process.env.VUE_APP_API_URI
    let url = proxy + api_uri + "/orders/orders_groups/" + order_id + "/reset"
    return Vue.http
        .put(url, {}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response).catch(response => response.json())
}