import Vue from 'vue'

export default function setGroups({ id, cancel_description }) {
    const proxy = process.env.VUE_APP_PROXY
    const api_uri = process.env.VUE_APP_API_URI

    let url = proxy + api_uri + "/lessons/" + id + "/cancel"
    return Vue.http
        .put(url, { cancel_description }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json()).catch((response) => response.json())
}