import Vue from 'vue'

export default function getNotifications() {
  const proxy = process.env.VUE_APP_PROXY
  const api_uri = process.env.VUE_APP_API_URI

  let url = proxy + api_uri + '/notifications'
  return Vue.http
    .get(
      url,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(response => response.json())
}
