import Vue from 'vue'
import Vuex from 'vuex'
import rooms from './modules/rooms'
import years from './modules/years'
import students from './modules/students'
import groups from './modules/groups'
import teachers from './modules/teachers'
import lessons from './modules/lessons'
import comments from './modules/comments'
import auth from './modules/auth'
import orders from './modules/orders'
import payments from './modules/payments'
import files from './modules/files'
import books from './modules/books'
import results from './modules/results'
import surprises from './modules/surprises'
import tasks from './modules/tasks'
import home from './modules/home'
import events from './modules/events'

Vue.use(Vuex)

export default new Vuex.Store({
  // state: {},
  // mutations: {},
  // actions: {},
  modules: {
    rooms,
    years,
    students,
    groups,
    teachers,
    lessons,
    comments,
    auth,
    orders,
    payments,
    files,
    books,
    results,
    surprises,
    tasks,
    home,
    events,
  },
})
